import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import styles from "./StoryImageLoader.module.sass";
import Icon from "../Icon";
import axios from "../../utils/axios";

const StoryImageLoader = ({ btnTitle, imageUrl, delay, validationError }) => {
  const [option, setOption] = useState("default");
  const [fileName, setFileName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (imageUrl) {
      setFileName(imageUrl);
      setOption("loaded");
    }
  }, [imageUrl]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileInput = (event) => {
    const uploadedFile = event.target.files[0];

    if (
      !uploadedFile?.type.includes("image") ||
      uploadedFile?.type.includes("image/gif")
    ) {
      setOption("error");
      return;
    }

    if (delay) {
      delay(uploadedFile);
      setFileName(uploadedFile.name);
      setOption("loaded");
      return;
    }

    uploadImageToS3(uploadedFile);
  };

  const uploadImageToS3 = async (uploadedFile) => {
    const bodyFormData = new FormData();
    bodyFormData.append("image", uploadedFile);

    setLoading(true);

    try {
      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path: `stories`,
        },
      });

      setFileName(resp.data);
      setOption("loaded");
    } catch (err) {
      setOption("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.loader}>
      <button
        className={cn("button-stroke", styles.upload_button, {
          [styles.upload_button_error]: validationError,
        })}
        onClick={handleClick}
      >
        <img
          src={`/images/icons/${isLoading ? "loader" : "add"}/${
            validationError ? "err" : "light"
          }.svg`}
          alt="icon"
        />
        <span>{btnTitle}</span>
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileInput}
        style={{ display: "none" }}
      />
      {option === "default" && (
        <div className={styles.option_default}>
          <span>No uploaded</span>
        </div>
      )}
      {option === "loaded" && (
        <div className={styles.option_loaded}>
          <span>
            {fileName.length > 10 ? `${fileName.slice(0, 10)}...` : fileName}
          </span>
          <Icon name="check" size="20" />
        </div>
      )}
      {option === "error" && (
        <div className={styles.option_error}>
          <span>Error. Try again</span>
          <Icon name="close" size="18" />
        </div>
      )}
    </div>
  );
};

export default StoryImageLoader;
