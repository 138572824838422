import React from "react";
import cn from "classnames";

import styles from "./Table.module.sass";
import Row from "./Row";
import Loader from "../../../components-dev/Loader";
import { superAdminRole } from "../../../assets/roles";

const Table = ({ transferRequests, loader, isLoading }) => {
  return (
    <div className={cn(styles.wrapper)}>
      {transferRequests.length ? (
        <div className={cn(styles.table)}>
          <div className={cn(styles.row)}>
            <div className={styles.col}>Item</div>
            <div className={styles.col}>Digital model</div>
            <div className={styles.col}>Game name</div>
            <div className={styles.col}>Username</div>
            <div className={styles.col}>Created time</div>
            <div className={styles.col}>Updated time</div>
            <div className={styles.col}>Transfer status</div>
            {superAdminRole && <div className={styles.col}>Delete</div>}
          </div>
          {transferRequests.map((transferRequest, index) => (
            <Row item={transferRequest} key={index} />
          ))}
        </div>
      ) : (
        !isLoading && <div className={styles.info}>No transfer requests</div>
      )}
      <div ref={loader}></div>
      {isLoading && (
        <div className={styles.foot}>
          <div className={styles.blc_loader}>
            <Loader className={styles.loader} />
            <span>Loading</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
