import React from "react";
import cn from "classnames";

import styles from "./Game.module.sass";
import ImageStub from "../../../components-dev/ImageStub";

const Game = ({ className, data, onClick, onAuxClick }) => {
  return (
    <div className={cn(styles.game, className)}>
      <div className={styles.preview} onClick={onClick} onAuxClick={onAuxClick}>
        {data.logo ? (
          <img srcSet={data.logo} src={data.logo} alt="Game" />
        ) : (
          <ImageStub alt="Game" />
        )}
      </div>
      <div className={styles.line}>
        <div className={styles.name}>{data.name}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.type}>
          <span>{data.type}</span>
        </div>
      </div>
      <div className={styles.line}>
        <div className={cn(data.isActive ? "status-green" : "status-grey")}>
          {data.isActive ? "Active" : "Inactive"}
        </div>
      </div>
    </div>
  );
};

export default Game;
