import React from "react";

const Image = ({ className, src, srcSet, alt }) => {
  return (
    <img
      className={className}
      srcSet={srcSet}
      src={src}
      alt={alt}
      onError={(e) => (e.target.src = "/images/logo.svg")}
    />
  );
};

export default Image;
