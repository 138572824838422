import React, { useState, useEffect } from "react";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./Thumbnail.module.sass";
import Icon from "../../../components-dev/Icon";
import TextInput from "../../../components-dev/TextInput";
import Tooltip from "../../../components-dev/Tooltip";
import StoryImageLoader from "../../../components-dev/StoryImageLoader";
import StoryColorPicker from "../../../components-dev/StoryColorPicker";
import { basicRole } from "../../../assets/roles";
import { generateImgUrl } from "../../../utils/generateImgUrl";

const Thumbnail = ({
  data,
  setEditable,
  onSave,
  errorVisible,
  setValid,
  disabled,
}) => {
  const [thumbnail, setThumbnail] = useState(data);
  const [clipboard, setClipboard] = useState(data);
  const [isEditMode, setEditMode] = useState(false);
  const [isColorMode, setColorMode] = useState(false);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    setEditable(isEditMode ? "thumbnail" : null);
  }, [isEditMode]);

  useEffect(() => {
    validate();
  }, [thumbnail]);

  const validate = () => {
    const { error } = Joi.object({
      title: Joi.string().max(40).required(),
      previewImage: Joi.string().required(),
      previewBackgroundColor: Joi.string().required(),
      previewTextColor: Joi.string().required(),
      uploadedFile: Joi.optional(),
    }).validate(thumbnail, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const save = () => {
    onSave(thumbnail);
    setClipboard(thumbnail);
    setEditMode(false);
  };

  const cancel = () => {
    setThumbnail(clipboard);
    setEditMode(false);
    setColorPickerVisible(false);
  };

  const onChangeStoryImageLoader = async (uploadedFile) => {
    const previewUrl = await generateImgUrl(uploadedFile);
    setThumbnail((prev) => ({
      ...prev,
      previewImage: previewUrl,
      uploadedFile,
    }));
  };

  return (
    <div className={cn(styles.content, disabled && styles.blurred)}>
      <div className={cn(styles.card, styles.thumbnail)}>
        <div className={styles.card_head}>
          <div className={cn("title-green", styles.title)}>Thumbnail</div>
          {!basicRole && (
            <button
              className={cn(styles.btn_edit)}
              onClick={() =>
                setEditMode((prev) => (prev === true ? cancel() : true))
              }
              disabled={disabled}
            >
              <Icon name="edit" size="20" />
            </button>
          )}
        </div>
        <div
          className={styles.prototype}
          style={{
            backgroundColor: thumbnail.previewBackgroundColor,
            backgroundImage: `url(${thumbnail.previewImage})`,
          }}
        >
          <span style={{ color: thumbnail.previewTextColor }}>
            {thumbnail.title}
          </span>
        </div>
        <div className={styles.card_footer}>
          <span>How does it look like in app?</span>
          <button
            className={cn(styles.btn_prototype)}
            onClick={() => {}}
            disabled={true}
          >
            <Icon name="info" size="20" />
          </button>
        </div>
      </div>

      {isEditMode && (
        <div className={cn(styles.card, styles.thumbnail_edit)}>
          <div className={styles.card_head}>
            <div className={cn("title-green", styles.title)}>
              Thumbnail edit
            </div>
          </div>

          <div className={styles.thumbnail_edit_section}>
            <div className={styles.image_upload_title}>
              <span>Background image upload</span>
              <Tooltip
                className={styles.tooltip}
                title="Square image 1:1 with transparent background"
                icon="info"
                place="bottom"
              />
            </div>
            <StoryImageLoader
              btnTitle="image"
              imageUrl={thumbnail.uploadedFile?.name}
              delay={onChangeStoryImageLoader}
              validationError={
                errorVisible && validationError.includes("previewImage")
              }
            />
          </div>

          <div className={styles.thumbnail_edit_section}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("title"),
              })}
              classLabel={styles.text_input_label}
              value={thumbnail.title}
              placeholder="Title of the story"
              label={"Title *"}
              maxLength="40"
              onChange={({ target }) =>
                setThumbnail((prev) => ({ ...prev, title: target.value }))
              }
              error={errorVisible && validationError.includes("title")}
            />
          </div>

          <div className={styles.thumbnail_edit_section}>
            <div className={styles.color_picker_control}>
              <span>Change colors</span>
              <button
                className={cn(styles.btn_prototype)}
                onClick={() => setColorMode((prev) => !prev)}
                style={{
                  transform: isColorMode && "rotate(180deg)",
                }}
              >
                <Icon name="arrow-down" size="24" />
              </button>
            </div>

            {isColorMode && (
              <>
                <div className={styles.color_picker_control}>
                  <StoryColorPicker
                    title="Background color"
                    currentColor={thumbnail.previewBackgroundColor}
                    onSaveColor={(selectedColor) => {
                      setThumbnail((prev) => ({
                        ...prev,
                        previewBackgroundColor: selectedColor,
                      }));
                    }}
                    setColorPickerVisible={setColorPickerVisible}
                  />
                </div>
                <div className={styles.color_picker_control}>
                  <StoryColorPicker
                    title="Title color"
                    currentColor={thumbnail.previewTextColor}
                    onSaveColor={(selectedColor) => {
                      setThumbnail((prev) => ({
                        ...prev,
                        previewTextColor: selectedColor,
                      }));
                    }}
                    setColorPickerVisible={setColorPickerVisible}
                  />
                </div>
              </>
            )}
          </div>

          <div className={styles.thumbnail_edit_section}>
            <button
              className={cn("button", styles.btn_save_thumbnail)}
              disabled={colorPickerVisible}
              onClick={save}
            >
              Done
            </button>
            <button
              className={cn("button-stroke", styles.btn_cancel_thumbnail)}
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
