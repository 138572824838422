import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./RedirectTableCell.module.sass";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";

const RedirectTableCell = ({ text, to, maxWidth }) => {
  const history = useHistory();

  return (
    <div className={styles.table_cell}>
      <span className={maxWidth}>{text}</span>
      <button
        className={styles.btn_redirect}
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey) window.open(to);
          else history.push(to);
        }}
        onAuxClick={wheelClickEventHandler(to)}
      >
        <img src="/images/icons/open/light.svg" alt="link" />
      </button>
    </div>
  );
};

export default RedirectTableCell;
