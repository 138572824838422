import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import styles from "./ProductItems.module.sass";
import Card from "../../../components-dev/Card";
import CreationForm from "./CreationForm";
import Loader from "../../../components-dev/Loader";
import RedirectTableCell from "../../../components-dev/RedirectTableCell";
import axios from "../../../utils/axios";
import eventBus from "../../../utils/eventBus";

const ProductItems = ({ className, product }) => {
  const [productItems, setProductItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    fetchProductItems();
  }, [page]);

  const fetchProductItems = async () => {
    if (page > 1 && productItems.length === total) {
      observer.current.disconnect();
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get("marketplace/product-items", {
        params: { productId: product.id, limit: 10, page },
      });

      setTotal(data.pager.count);
      setProductItems((prev) => [...prev, ...data.productItems]);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        className={styles.card}
        title={"Related product items"}
        classTitle="title-green"
        info={!!total && ["Items", total]}
        btnCreate={{
          title: "Add product items",
          onClick: () => setFormVisible(true),
        }}
      >
        <div className={cn(styles.wrapper, className)}>
          {productItems.length ? (
            <div className={cn(styles.table)}>
              <div className={cn(styles.row)}>
                <div className={styles.col}>Item ID</div>
                <div className={styles.col}>Status</div>
              </div>
              {productItems.map((item, index) => (
                <div className={styles.row_content} key={index}>
                  <div className={styles.col_content}>
                    <RedirectTableCell
                      text={item.id}
                      to={`/product-items/${item.id}`}
                    />
                  </div>
                  <div className={styles.col_content}>
                    <div
                      className={cn(styles[item.stockStatus.replace("_", "")])}
                    >
                      {item.stockStatus.replace("_", " ")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            !isLoading && <div className={styles.info}>No product items</div>
          )}
          <div ref={loader}></div>
          {isLoading && (
            <div className={styles.foot}>
              <div className={styles.blc_loader}>
                <Loader className={styles.loader} />
                <span>Loading</span>
              </div>
            </div>
          )}
        </div>
      </Card>
      <CreationForm
        visible={formVisible}
        setVisible={setFormVisible}
        product={product}
        onCreate={(newProductItems) => {
          setProductItems((prev) => [...newProductItems, ...prev]);
          setTotal((prev) => prev + newProductItems.length);
          eventBus.emit("add_product_items_event", newProductItems.length);
        }}
      />
    </>
  );
};

export default ProductItems;
