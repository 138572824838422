import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./DigitalModel.module.sass";
import Items from "./Items";
import MarketplaceProducts from "./MarketplaceProducts";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import Dropdown from "../../components-dev/Dropdown";
import TextInput from "../../components-dev/TextInput";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import Icon from "../../components-dev/Icon";
import FileUploader from "../../components-dev/FileUploader";
import ImageStub from "../../components-dev/ImageStub";
import DigitalModelVariants from "../../components-dev/DigitalModelVariants";
import ModelLoader from "../../components-dev/ModelLoader";
import DigitalModelProductImages from "../../components-dev/DigitalModelProductImages";
import BrandCreationForm from "../../components-dev/BrandCreationForm";
import TransferCard from "../../components-dev/TransferCard";
import PromoCard from "../../components-dev/PromoCard";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { isJsonString, toPrettyJson } from "../../utils/gJson";
import { addTabCharacter, resizeHeightTextarea } from "../../utils/textField";
import apparelTypes from "../../assets/apparelTypes";
import digitalModelTypes from "../../assets/digitalModelTypes";
import { basicRole } from "../../assets/roles";

const DigitalModel = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [brandFormVisible, setBrandFormVisible] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(false);

  const [digitalModel, setDigitalModel] = useState({});
  const [previewFile, setPreviewFile] = useState(null);
  const [clipboard, setClipboard] = useState({});
  const [brands, setBrands] = useState([]);
  const [physiсalModels, setPhysiсalModels] = useState([]);

  const transferCardAnchorRef = useRef(null);

  const { id: digitalModelId } = useParams();
  const history = useHistory();

  useEffect(() => {
    scrollToTop();
    fetchData();
  }, []);

  useEffect(() => {
    validate();
  }, [digitalModel]);

  useEffect(() => {
    if (!digitalModel.freeCollection && digitalModel.freeCollectionEmail) {
      setDigitalModel((prev) => ({
        ...prev,
        freeCollectionEmail: false,
      }));
    }
  }, [digitalModel.freeCollection]);

  const fetchData = async () => {
    displayLoader();
    await fetchDigitalModel();
    await fetchBrands();
    await fetchPhysiсalModels();
    hideLoader();

    if (history.location.state?.anchorLink) {
      const transferCardElem = transferCardAnchorRef.current;
      transferCardElem && transferCardElem.scrollIntoView();
    }
  };

  const fetchDigitalModel = async () => {
    try {
      const { data } = await axios.get("digitalmdl", {
        params: {
          digitalModelId,
        },
      });
      const { customizationConfigurator, ...record } = data.records[0];
      const dModel = {
        ...record,
        customizationConfigurator: toPrettyJson(customizationConfigurator),
      };
      setDigitalModel(dModel);
      setClipboard(dModel);
      await fetchFreeCollectionDigitalModels(dModel.freeCollectionEmail);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  const fetchFreeCollectionDigitalModels = async (fce) => {
    try {
      const { data } = await axios.get("digitalmdl/free-collection");
      setSwitchDisabled(data.fcEmail && !fce);
    } catch {}
  };

  const fetchBrands = async (alreadyLoadedBrands = [], page = 1) => {
    try {
      const { data } = await axios.get("brand", {
        params: {
          page,
          limit: 20,
        },
      });

      if (data.records.length) {
        if (data.count > alreadyLoadedBrands.length + data.records.length) {
          await fetchBrands([...alreadyLoadedBrands, ...data.records], ++page);
        } else {
          setBrands([...alreadyLoadedBrands, ...data.records]);
        }
      }
    } catch {}
  };

  const fetchPhysiсalModels = async () => {
    try {
      const { data } = await axios.get("physicalmdl");
      setPhysiсalModels(data.records);
    } catch {}
  };

  const changeMode = () => {
    if (isEditMode) {
      setDigitalModel((prev) => ({
        ...clipboard,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setDigitalModel((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const customizationConfigurator = digitalModel.customizationConfigurator;

    const { error } = Joi.object({
      brandId: Joi.string().uuid().required(),
      titleFull: Joi.string().required(),
      name: Joi.string().required(),
      description: Joi.string().required(),
      apparelType: Joi.string().required(),
      digitalModelType: Joi.string().required(),
      isActive: Joi.boolean().required(),
      transferable: Joi.string().required(),
      freeCollection: Joi.boolean().required(),
      freeCollectionEmail: Joi.boolean().required(),
      giftingAvailable: Joi.boolean().required(),
      customizationConfigurator: Joi.object({
        parts: Joi.array()
          .items(
            Joi.object({
              name: Joi.string().required(),
              title: Joi.string().required(),
              colors: Joi.array().items(Joi.string()).min(1),
              patterns: Joi.array().items(Joi.string()).min(1),
            }).required()
          )
          .required(),
        backgroundColors: Joi.array().items(Joi.string()).min(1),
      }).allow(null),
      arGroupLensId: Joi.string().uuid().allow(null, ""),
      arLensId: Joi.string().uuid().allow(null, ""),
      shopifyProductId: Joi.string().uuid().allow(null, ""),
      variants: Joi.array()
        .allow(null)
        .items(
          Joi.object({
            name: Joi.string().required(),
            id: Joi.string().required(),
          })
        ),
    }).validate(
      {
        brandId: digitalModel.brandId,
        titleFull: digitalModel.titleFull,
        name: digitalModel.name,
        description: digitalModel.description,
        apparelType: digitalModel.apparelType,
        digitalModelType: digitalModel.digitalModelType,
        isActive: digitalModel.isActive,
        transferable: digitalModel.transferable,
        freeCollection: digitalModel.freeCollection,
        freeCollectionEmail: digitalModel.freeCollectionEmail,
        giftingAvailable: digitalModel.giftingAvailable,
        customizationConfigurator:
          isJsonString(customizationConfigurator) &&
          JSON.parse(customizationConfigurator),
        arGroupLensId: digitalModel.arGroupLensId,
        arLensId: digitalModel.arLensId,
        shopifyProductId: digitalModel.arLensId,
        variants: digitalModel.variants,
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (uploadedFile) => {
    if (
      uploadedFile?.type.includes("image") ||
      !uploadedFile?.type.includes("image/gif")
    ) {
      const previewUrl = await generateImgUrl(uploadedFile);
      handleChange("previewUrl", previewUrl);
      setPreviewFile(uploadedFile);
    }
  };

  const onDeletePreview = () => {
    handleChange("previewUrl", "");
    setPreviewFile(null);
  };

  const uploadPreviewToS3 = async (file) => {
    const bodyFormData = new FormData();
    bodyFormData.append("previewPic", file);

    try {
      await axios.post(`digitalmdl/${digitalModelId}/preview`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      displayNotificationPopup("error: upload preview");
    }
  };

  const updateDigitalModel = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        brandId: digitalModel.brandId,
        titleFull: digitalModel.titleFull,
        name: digitalModel.name,
        description: digitalModel.description,
        apparelType: digitalModel.apparelType,
        digitalModelType: digitalModel.digitalModelType,
        physicalModelId: digitalModel.physicalModelId,
        isActive: digitalModel.isActive,
        transferable: digitalModel.transferable,
        freeCollection: digitalModel.freeCollection,
        freeCollectionEmail: digitalModel.freeCollectionEmail,
        giftingAvailable: digitalModel.giftingAvailable,
        customizationConfigurator: digitalModel.customizationConfigurator
          ? JSON.parse(digitalModel.customizationConfigurator)
          : "",
        arGroupLensId: digitalModel.arGroupLensId,
        arLensId: digitalModel.arLensId,
        shopifyProductId: digitalModel.shopifyProductId,
        variants: digitalModel.variants?.length ? digitalModel.variants : null,
      };

      Object.keys(body).forEach((key) => {
        if (body[key] === null || body[key] === undefined) delete body[key];
        if (body[key] === "") body[key] = null;
      });

      if (!digitalModel.previewUrl) body.previewUrl = null;
      if (!digitalModel.variants?.length) body.variants = null;

      await axios.put(`digitalmdl/${digitalModelId}`, body);
      if (previewFile) {
        await uploadPreviewToS3(previewFile);
      }

      setClipboard((prev) => ({
        id: prev.id,
        previewUrl: prev.previewUrl,
        modelUrl: prev.modelUrl,
        ...body,
      }));
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    !isEmptyObject(digitalModel) && (
      <>
        <InfoNavigation
          link={"/digital-models"}
          parts={["Digital models", digitalModel.name || "model"]}
        />
        <div className={styles.head}>
          <div className={styles.blc_title}>
            <div className={cn("h3", styles.title)}>
              {brands.find((b) => b.id === digitalModel.brandId)?.name ||
                "Brand"}
            </div>
            <div className={cn("h3", styles.subtitle)}>
              {digitalModel.titleFull || "Model"}
            </div>
          </div>
          {!basicRole && (
            <button
              className={cn("button-stroke", styles.switch_button, [
                !isEditMode ? styles.info : styles.edit,
              ])}
              onClick={changeMode}
            >
              <Icon name={!isEditMode ? "edit" : "close"} size="24" />
              <span>{!isEditMode ? "Edit" : "Cancel"}</span>
            </button>
          )}
        </div>

        <Card>
          <div className={cn(styles.digital_model)}>
            <div className={styles.blc_left}>
              <div className={styles.preview}>
                {digitalModel.previewUrl ? (
                  <div className={styles.preview_content}>
                    <img
                      srcSet={digitalModel.previewUrl}
                      src={digitalModel.previewUrl}
                      alt="Digital model"
                    />
                    {isEditMode && (
                      <>
                        <button
                          className={styles.btn_preview_add}
                          style={{
                            backgroundImage: `url("/images/icons/add/light.svg")`,
                          }}
                        >
                          <FileUploader onUpload={onUploadPreview} fill />
                        </button>
                        <button
                          className={styles.btn_preview_delete}
                          style={{
                            backgroundImage: `url("/images/icons/trash/light-red.svg")`,
                          }}
                          onClick={onDeletePreview}
                        ></button>
                      </>
                    )}
                  </div>
                ) : isEditMode ? (
                  <div className={styles.preview_content}>
                    <FileUploader onUpload={onUploadPreview}>
                      <Icon name="add" size="52" fill="#b1b5b9" />
                    </FileUploader>
                  </div>
                ) : (
                  <ImageStub className={styles.stub} alt="Digital model" />
                )}
              </div>
              {!basicRole && (
                <>
                  <div className={styles.models_loader}>
                    <ModelLoader
                      btnTitle={"Add Android 3D model"}
                      modelUrl={digitalModel.modelUrl}
                      modelFile="glbFileUrl"
                    />
                    <ModelLoader
                      btnTitle={"Add iOS 3D model"}
                      modelUrl={digitalModel.modelUrl}
                      modelFile="usdzFileUrl"
                    />
                  </div>
                  <DigitalModelProductImages
                    label="Product photos"
                    tooltip="Product photos"
                    images={digitalModel.images || []}
                    param="images"
                    onChange={(changedImages) =>
                      handleChange("images", changedImages)
                    }
                  />
                  <DigitalModelProductImages
                    label="Size guide"
                    tooltip="Size guide"
                    images={
                      digitalModel.sizeGuide?.tables?.length
                        ? digitalModel.sizeGuide.tables.map((t) => t.image)
                        : []
                    }
                    param="sizeGuide"
                    onChange={(changedSizeGuide) =>
                      handleChange("sizeGuide", changedSizeGuide)
                    }
                  />
                </>
              )}
            </div>

            <div className={styles.blc_right}>
              <div className={cn(styles.group, styles.nsame)}>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={
                    brands.find((b) => b.id === digitalModel.brandId)?.name
                  }
                  placeholder="Brand name ..."
                  setValue={(selectedBrand) => {
                    handleChange("brandId", selectedBrand.id);
                  }}
                  options={brands}
                  label={"Brand name *"}
                  link={!isEditMode && `/brands/${digitalModel.brandId}`}
                  loading
                  disabled={!isEditMode}
                />
                {/* {isEditMode && (
                  <button
                    className={cn("button-stroke", styles.create_brand_button)}
                    onClick={() => setBrandFormVisible(true)}
                  >
                    <Icon name="add" size="24" />
                    <span>Create brand</span>
                  </button>
                )} */}
              </div>
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    validationError.includes("titleFull"),
                })}
                value={digitalModel.titleFull}
                placeholder="Title full ..."
                label={"Title full *"}
                onChange={({ target }) =>
                  handleChange("titleFull", target.value)
                }
                error={isEditMode && validationError.includes("titleFull")}
                disabled={!isEditMode}
              />
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]: validationError.includes('"name"'),
                })}
                value={digitalModel.name}
                placeholder="Name ..."
                label={"Name *"}
                onChange={({ target }) => handleChange("name", target.value)}
                error={isEditMode && validationError.includes('"name"')}
                disabled={!isEditMode}
              />
              <AutoHeightTextarea
                classInput={cn(styles.textarea, styles.min_height_205)}
                value={digitalModel.description}
                placeholder="Description ..."
                label={"Description *"}
                onChange={({ target }) =>
                  handleChange("description", target.value)
                }
                error={isEditMode && validationError.includes("description")}
                disabled={!isEditMode}
              />
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={
                  physiсalModels.find(
                    (model) => model.id === digitalModel.physicalModelId
                  )?.name
                }
                placeholder="Physiсal model ..."
                setValue={(selectedPhysiсalModel) => {
                  handleChange("physicalModelId", selectedPhysiсalModel.id);
                }}
                options={physiсalModels}
                label={"Physiсal model"}
                clear={isEditMode && digitalModel.physicalModelId}
                loading
                disabled={!isEditMode}
              />
              <div className={cn(styles.group, styles.same)}>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={digitalModel.apparelType}
                  placeholder="Apparel type ..."
                  setValue={(selectedValue) => {
                    handleChange("apparelType", selectedValue);
                  }}
                  options={apparelTypes}
                  label={"Apparel type *"}
                  disabled={!isEditMode}
                />
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={digitalModel.digitalModelType}
                  placeholder="Model type ..."
                  setValue={(selectedValue) => {
                    handleChange("digitalModelType", selectedValue);
                  }}
                  options={digitalModelTypes}
                  label={"Model type *"}
                  disabled={!isEditMode}
                />
              </div>
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    validationError.includes("shopifyProductId"),
                })}
                value={digitalModel.shopifyProductId || ""}
                placeholder="Shopify Product ID ..."
                label={"Shopify Product ID"}
                onChange={({ target }) =>
                  handleChange("shopifyProductId", target.value)
                }
                error={
                  isEditMode && validationError.includes("shopifyProductId")
                }
                disabled={!isEditMode}
              />
              <DigitalModelVariants
                variants={digitalModel.variants || []}
                onChange={(changedVariants) =>
                  handleChange("variants", changedVariants)
                }
                validationError={
                  isEditMode &&
                  validationError.includes("variants") &&
                  validationError
                }
                disabled={!isEditMode}
              />
              <div className={styles.blc_switch}>
                <span>Active</span>
                <Switch
                  className={styles.switch}
                  value={digitalModel.isActive}
                  onChange={() =>
                    setDigitalModel((prev) => ({
                      ...prev,
                      isActive: !prev.isActive,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              <div className={styles.blc_switch}>
                <span>Transferable</span>
                <Switch
                  className={styles.switch}
                  value={digitalModel.transferable !== "not_transferable"}
                  onChange={() =>
                    handleChange(
                      "transferable",
                      digitalModel.transferable === "not_transferable"
                        ? "decentraland"
                        : "not_transferable"
                    )
                  }
                  disabled={!isEditMode}
                />
              </div>
              <div className={styles.blc_switch}>
                <span>Free collection for new users</span>
                <Switch
                  className={styles.switch}
                  value={digitalModel.freeCollection}
                  onChange={() =>
                    setDigitalModel((prev) => ({
                      ...prev,
                      freeCollection: !prev.freeCollection,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              <div className={styles.blc_switch}>
                <span
                  className={cn({
                    ["unchangeable"]:
                      isEditMode &&
                      (switchDisabled || !digitalModel.freeCollection),
                  })}
                >
                  Free collection email
                </span>
                <Switch
                  className={styles.switch}
                  value={digitalModel.freeCollectionEmail}
                  onChange={() =>
                    setDigitalModel((prev) => ({
                      ...prev,
                      freeCollectionEmail: !prev.freeCollectionEmail,
                    }))
                  }
                  disabled={
                    !isEditMode ||
                    switchDisabled ||
                    !digitalModel.freeCollection
                  }
                />
              </div>
              <div className={styles.blc_switch}>
                <span>Gifting</span>
                <Switch
                  className={styles.switch}
                  value={digitalModel.giftingAvailable}
                  onChange={() =>
                    setDigitalModel((prev) => ({
                      ...prev,
                      giftingAvailable: !prev.giftingAvailable,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              <AutoHeightTextarea
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={styles.textarea}
                label={"Preview attributes (JSON) *"}
                value={toPrettyJson(digitalModel.previewAttributes)}
                placeholder="Preview attributes ..."
                disabled={true}
              />
              <AutoHeightTextarea
                classInput={cn(styles.textarea)}
                label={"Customization configurator (JSON)"}
                value={digitalModel.customizationConfigurator || ""}
                placeholder="Customization configurator ..."
                onChange={({ target }) => {
                  handleChange("customizationConfigurator", target.value);
                  resizeHeightTextarea(target);
                }}
                onKeyDown={addTabCharacter}
                onPrettyJson={
                  isEditMode &&
                  ((value) =>
                    handleChange(
                      "customizationConfigurator",
                      toPrettyJson(value)
                    ))
                }
                onClear={
                  isEditMode &&
                  (() => handleChange("customizationConfigurator", null))
                }
                error={
                  isEditMode &&
                  validationError.includes("customizationConfigurator")
                }
                disabled={!isEditMode}
              />
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    validationError.includes("arGroupLensId"),
                })}
                value={digitalModel.arGroupLensId || ""}
                placeholder="AR group lens ID ..."
                label={"AR group lens ID"}
                onChange={({ target }) =>
                  handleChange("arGroupLensId", target.value)
                }
                error={isEditMode && validationError.includes("arGroupLensId")}
                disabled={!isEditMode}
              />
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    validationError.includes("arLensId"),
                })}
                value={digitalModel.arLensId || ""}
                placeholder="AR lens ID ..."
                label={"AR lens ID"}
                onChange={({ target }) =>
                  handleChange("arLensId", target.value)
                }
                error={isEditMode && validationError.includes("arLensId")}
                disabled={!isEditMode}
              />
              {isEditMode && (
                <button
                  className={cn("button", styles.save_button)}
                  disabled={!isValid}
                  onClick={updateDigitalModel}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </Card>
        <BrandCreationForm
          visible={brandFormVisible}
          setVisible={setBrandFormVisible}
          onCreate={(newBrand) => {
            setBrands((prev) => [newBrand, ...prev]);
            handleChange("brandId", newBrand.id);
          }}
        />
        <div ref={transferCardAnchorRef}></div>
        {!isEditMode && <TransferCard digitalModel={digitalModel} />}
        {!isEditMode && <PromoCard digitalModelId={digitalModelId} />}
        {!isEditMode && <MarketplaceProducts digitalModel={digitalModel} />}
        {!isEditMode && <Items digitalModel={digitalModel} />}
      </>
    )
  );
};

export default DigitalModel;
