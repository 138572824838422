import React, { useState, useRef } from "react";
import cn from "classnames";

import styles from "./StoryColorPicker.module.sass";
import Icon from "../Icon";
import { storyColors } from "../../assets/storyColors";

const StoryColorPicker = ({
  title,
  currentColor = null,
  onSaveColor,
  setColorPickerVisible,
}) => {
  const [selectedColor, setColor] = useState(currentColor);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState(null);
  const pickerRef = useRef();

  const displayColorPicker = () => {
    const coordinates = pickerRef.current.getBoundingClientRect();
    setPosition({ left: coordinates.x - 70, top: coordinates.y - 410 });
    setVisible(true);
    setColorPickerVisible(true);
  };

  const closeColorPicker = () => {
    setColor(currentColor);
    setVisible(false);
    setColorPickerVisible(false);
  };

  const saveColor = () => {
    onSaveColor(selectedColor);
    setVisible(false);
    setColorPickerVisible(false);
  };

  return (
    <>
      <button
        className={cn("button-stroke", styles.btn_picker)}
        onClick={displayColorPicker}
        ref={pickerRef}
      >
        <span>{title}</span>
        {selectedColor && (
          <span
            style={{
              backgroundColor: selectedColor,
              border: selectedColor === "#FFFFFF" && "1px solid #6F767E",
            }}
          ></span>
        )}
      </button>
      {visible && (
        <div className={styles.color_picker}>
          <div className={styles.title}>
            <div>{title}</div>
            <button onClick={closeColorPicker}>
              <Icon name="close" size="20" />
            </button>
          </div>

          <div className={styles.color_scheme}>
            {storyColors.map((color, idx) => (
              <div
                key={idx}
                style={{
                  backgroundColor: color,
                  borderColor: color === "#FFFFFF" && "#6F767E",
                }}
                onClick={() => setColor(color)}
              >
                {color === selectedColor && (
                  <span className={styles.selectedColor}></span>
                )}
              </div>
            ))}
          </div>

          <button
            className={cn("button", styles.btn_save)}
            disabled={!selectedColor}
            onClick={saveColor}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default StoryColorPicker;
