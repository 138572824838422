import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import cn from "classnames";
import { format } from "date-fns";

import styles from "./User.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import RedirectTableCell from "../../components-dev/RedirectTableCell";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";

const User = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [perks, setPerks] = useState([]);

  const { id: userId } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      displayLoader();

      const { data } = await axios.get(`users/${userId}`);
      setData(data);
      setItems(data.items?.slice(0, 6));
      setPerks(data.perks?.slice(0, 3));
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    displayNotificationPopup("copied to clipboard");
  };

  const transformPerkStatus = (status) => {
    const obj = {
      PERK_AVAILABLE: "active",
      PERK_NOT_AVAILABLE: "not active",
      PERK_EXPIRED: "expired",
    };

    return obj[status];
  };

  return (
    !isEmptyObject(data) && (
      <>
        <InfoNavigation link={"/users"} parts={["Users", userId]} />
        <div className={styles.head}>
          <div className={styles.blc_title}>
            <div className={cn("h3", styles.title)}>{data.nickname}</div>
          </div>
        </div>

        <div className={styles.user_details}>
          <div>
            <Card
              className={styles.items_card}
              title={"Digital items"}
              classTitle="title-blue"
              info={["", data.items.length || ""]}
              classCardInfo={styles.count}
            >
              {data.items.length ? (
                <>
                  <div className={styles.items}>
                    {items.map((item, idx) => (
                      <div className={styles.item} key={idx}>
                        <div
                          className={styles.item_preview}
                          onClick={(e) => {
                            if (e.ctrlKey || e.metaKey)
                              window.open(`/digital-items/${item.id}`);
                            else history.push(`/digital-items/${item.id}`);
                          }}
                          onAuxClick={wheelClickEventHandler(
                            `/digital-items/${item.id}`
                          )}
                        >
                          <img
                            srcSet={item.digitalModel.previewUrl}
                            src={item.digitalModel.previewUrl}
                            alt="item"
                            onError={(e) =>
                              (e.target.src = "/images/game_image_stub.svg")
                            }
                          />
                        </div>
                        <div>
                          <div className={styles.item_brand}>
                            {item.brand.name}
                          </div>
                          <div className={styles.item_title}>
                            {item.digitalModel.name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {data.items.length > 6 && (
                    <button
                      className={cn("button-stroke", styles.btn_show_more)}
                      onClick={() =>
                        setItems(
                          items.length !== data.items.length
                            ? data.items
                            : data.items.slice(0, 6)
                        )
                      }
                    >
                      {`Show ${
                        items.length !== data.items.length ? "more" : "less"
                      }`}
                    </button>
                  )}
                </>
              ) : (
                <div className={styles.stub}>No digital items</div>
              )}
            </Card>
            <Card
              className={styles.perks_card}
              title={"Perks"}
              classTitle="title-blue"
              info={["", data.perks.length || ""]}
              classCardInfo={styles.count}
            >
              {data.perks.length ? (
                <>
                  <div>
                    {perks.map((perk, idx) => (
                      <div className={styles.perk} key={idx}>
                        <div>
                          <RedirectTableCell
                            text={perk.brandName}
                            to={`/brands/${perk.brandId}`}
                          />
                          <div className={styles.perk_description}>
                            {`${perk.discount}% ${perk.description}`}
                          </div>
                        </div>
                        <div
                          className={cn(
                            transformPerkStatus(perk.status) === "active"
                              ? "status-green"
                              : transformPerkStatus(perk.status) ===
                                "not active"
                              ? "status-red"
                              : "status-grey2",
                            styles.perk_status
                          )}
                        >
                          {transformPerkStatus(perk.status)}
                        </div>
                      </div>
                    ))}
                  </div>
                  {data.perks.length > 3 && (
                    <button
                      className={cn("button-stroke", styles.btn_show_more)}
                      onClick={() =>
                        setPerks(
                          perks.length !== data.perks.length
                            ? data.perks
                            : data.perks.slice(0, 3)
                        )
                      }
                    >
                      {`Show ${
                        perks.length !== data.perks.length ? "more" : "less"
                      }`}
                    </button>
                  )}
                </>
              ) : (
                <div className={styles.stub}>No perks</div>
              )}
            </Card>
          </div>
          <div>
            <Card
              className={styles.info_card}
              title={"Info"}
              classTitle="title-blue"
            >
              <div className={styles.user_info}>
                <img
                  srcSet={data.avatarUrl}
                  src={data.avatarUrl}
                  alt="avatar"
                  onError={(e) => (e.target.src = "/images/image_stub.png")}
                />
                <div className={styles.user_info_param}>
                  <p>ID</p>
                  <p>
                    <div className={styles.copy_param}>{data.id}</div>
                    <button
                      className={styles.copy_btn}
                      onClick={() => copyTextToClipboard(data.id)}
                    >
                      <Icon name="copy" size="18" />
                    </button>
                  </p>
                </div>
                <div className={styles.user_info_param}>
                  <p>Username</p>
                  <p>
                    {data.nickname}
                    <button
                      className={styles.copy_btn}
                      onClick={() => copyTextToClipboard(data.nickname)}
                    >
                      <Icon name="copy" size="18" />
                    </button>
                  </p>
                </div>
                <div className={styles.user_info_param}>
                  <p>First name</p>
                  <p>{data.firstName}</p>
                </div>
                <div className={styles.user_info_param}>
                  <p>Last name</p>
                  <p>{data.lastName}</p>
                </div>
                <div className={styles.user_info_param}>
                  <p>Email</p>
                  <p>
                    {data.email}
                    <button
                      className={styles.copy_btn}
                      onClick={() => copyTextToClipboard(data.email)}
                    >
                      <Icon name="copy" size="18" />
                    </button>
                  </p>
                </div>
                <div className={styles.user_info_param}>
                  <p>Last activity</p>
                  <p>
                    {data.lastActivity &&
                      format(new Date(data.lastActivity), "dd MMMM yyyy")}
                  </p>
                </div>
                <div className={styles.user_info_param}>
                  <p>Registration date</p>
                  <p>
                    {data.registrationDate &&
                      format(new Date(data.registrationDate), "dd MMMM yyyy")}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    )
  );
};

export default User;
