import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Loader from "../Loader";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  classUnchangeable,
  value,
  placeholder,
  setValue,
  options,
  label,
  clear,
  tooltip,
  small,
  upBody,
  disabled,
  loading,
  link,
  valueLabel,
  optionLabel,
}) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const onSelect = (value) => {
    setValue(value);
    setVisible(false);
  };

  const onClear = (e) => {
    e.stopPropagation();
    onSelect({ id: "" });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div
          className={cn(styles.label, classDropdownLabel, classUnchangeable)}
        >
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          { [styles.active]: visible && !disabled },
          classUnchangeable
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead, {
            [styles.disabled]: disabled,
          })}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>
            {valueLabel && <b>{valueLabel}</b>}
            {value}
            {clear && (
              <button className={styles.clear} onClick={onClear}>
                <img src="/images/icons/close/light.svg" alt="clear" />
              </button>
            )}
          </div>
          {placeholder && !value && (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
          {loading && !options.length && (
            <div className={styles.blc_loader}>
              <Loader className={styles.loader} />
              <span>Loading ...</span>
            </div>
          )}
          {link && (
            <button
              className={styles.link}
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey) window.open(link);
                else history.push(link);
              }}
              onAuxClick={wheelClickEventHandler(link)}
            >
              <img src="/images/icons/open/light.svg" alt="link" />
            </button>
          )}
        </div>
        {!disabled && (
          <div
            className={cn(
              styles.body,
              { [styles.bodyUp]: upBody },
              { [styles.hide]: !options.length }
            )}
          >
            {options.map((x, index) => (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]:
                    typeof x === "object" ? x.name === value : x === value,
                })}
                onClick={() => onSelect(x, index)}
                key={index}
              >
                {optionLabel && x[optionLabel] && <b>{x[optionLabel]}</b>}
                {typeof x === "object" ? x.name : x}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
