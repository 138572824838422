import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import styles from "./DigitalItems.module.sass";
import Table from "./Table";
import CreationForm from "./CreationForm";
import Card from "../../components-dev/Card";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { basicRole } from "../../assets/roles";

const DigitalItems = ({ displayNotificationPopup }) => {
  const [digitalItems, setDigitalItems] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);

  const limit = 25;

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    page && fetchDigitalItems();
  }, [page]);

  const fetchDigitalItems = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("digitalitem", {
        params: { limit, page },
      });
      if (data.records.length) {
        setDigitalItems((prev) => [...prev, ...data.records]);
      } else {
        observer.current.disconnect();
      }

      setLoading(false);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <>
      <Card>
        {!basicRole && (
          <button
            className={cn("button-white", styles.create_button)}
            onClick={() => setFormVisible(true)}
          >
            <Icon name="add" size="24" />
            <span>{"Create digital items"}</span>
          </button>
        )}
        <Table
          className={styles.table}
          digitalItems={digitalItems}
          loader={loader}
          isLoading={isLoading}
        />
      </Card>
      <CreationForm
        visible={formVisible}
        setVisible={setFormVisible}
        onCreate={(newDigitalItems) =>
          setDigitalItems((prev) => [...newDigitalItems, ...prev])
        }
      />
    </>
  );
};

export default DigitalItems;
