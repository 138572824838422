import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import Table from "./Table";
import Card from "../../components-dev/Card";
import axios from "../../utils/axios";
import eventBus from "../../utils/eventBus";

const Admins = ({ displayNotificationPopup }) => {
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);
  const history = useHistory();

  const limit = 25;

  useEffect(() => {
    if (!localStorage.getItem("roles").includes("superadmin")) {
      history.goBack();
    }

    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    const subscription = eventBus.on("delete_admin_event", (adminId) => {
      setAdmins((prev) => prev.filter((a) => a.id !== adminId));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    page && fetchAdmins();
  }, [page]);

  const fetchAdmins = async () => {
    try {
      setLoading(true);

      const response = await axios.get("admins", {
        params: { limit, offset: (page - 1) * limit },
      });
      const { data } = response.data;

      if (data.length) {
        setAdmins((prev) => [...prev, ...data]);
      } else {
        observer.current.disconnect();
      }

      setLoading(false);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <Card>
      <Table data={admins} loader={loader} isLoading={isLoading} />
    </Card>
  );
};

export default Admins;
