import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./PromoCard.module.sass";
import Card from "../Card";
import TextInput from "../TextInput";
import AutoHeightTextarea from "../AutoHeightTextarea";
import Dropdown from "../Dropdown";
import Icon from "../Icon";
import Loader from "../Loader";
import axios from "../../utils/axios";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { basicRole } from "../../assets/roles";
import { promoTypes } from "../../assets/promoTypes";
import { promoStatuses } from "../../assets/promoStatuses";
import { promoGroup } from "../../assets/promoGroup";

const PromoCard = ({ digitalModelId }) => {
  const [promo, setPromo] = useState({});
  const [clipboard, setClipboard] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isCreateMode, setCreateMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPromo();
  }, []);

  useEffect(() => {
    validate();
  }, [promo]);

  const fetchPromo = async () => {
    try {
      setLoading(true);

      const {
        data: { perks },
      } = await axios.get("marketplace/product-perks", {
        params: { digitalModelId },
      });

      if (perks[0]) {
        setPromo(perks[0]);
        setClipboard(perks[0]);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setPromo(clipboard);
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setPromo((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      digitalModelId: Joi.string().uuid().optional(),
      description: Joi.string().required(),
      promoPhrase: Joi.string().required(),
      discount: Joi.number().integer().min(1).max(99).required(),
      type: Joi.string()
        .valid(...promoTypes)
        .required(),
      status: Joi.string()
        .valid(...promoStatuses)
        .required(),
      group: Joi.string()
        .valid(...promoGroup)
        .required(),
    }).validate(
      {
        digitalModelId: promo.digitalModelId,
        description: promo.description,
        promoPhrase: promo.promoPhrase,
        discount: promo.discount,
        type: promo.type,
        status: promo.status,
        group: promo.group,
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const save = async () => {
    if (!isValid) return;

    try {
      dispatch(displayLoader());

      if (isCreateMode) {
        const {
          data: { perk },
        } = await axios.post("marketplace/product-perks", {
          ...promo,
          digitalModelId,
        });
        setPromo(perk);
        setClipboard(perk);
        setCreateMode(false);
      } else {
        const { id, ...body } = promo;
        await axios.put(`marketplace/product-perks/${promo.id}`, body);
        setClipboard(promo);
        setEditMode(false);
      }

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("saved successfully"));
    } catch (err) {
      dispatch(hideLoader());
      dispatch(displayNotificationPopup("error"));
    }
  };

  return (
    <Card className={styles.card} title={"Promo"} classTitle="title-green">
      {!basicRole && !isLoading && isEmptyObject(promo) && (
        <button
          className={cn("button-stroke", styles.create_button, styles.info)}
          onClick={() => setCreateMode(true)}
        >
          <Icon name="add" size="24" />
          <span>Create promo</span>
        </button>
      )}

      {!basicRole && !isLoading && !isEmptyObject(promo) && !isCreateMode && (
        <button
          className={cn("button-stroke", styles.switch_button, [
            !isEditMode ? styles.info : styles.edit,
          ])}
          onClick={changeMode}
        >
          <Icon name={!isEditMode ? "edit" : "close"} size="24" />
          <span>{!isEditMode ? "Edit" : "Cancel"}</span>
        </button>
      )}

      {(!isEmptyObject(promo) || isCreateMode) && (
        <div className={cn(styles.promo_form)}>
          <div className={styles.blc_left}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("discount"),
              })}
              value={promo.discount}
              placeholder="Discount ..."
              label={"Discount (%) *"}
              onChange={({ target }) => handleChange("discount", target.value)}
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              error={errorVisible && validationError.includes("discount")}
              disabled={!isEditMode && !isCreateMode}
            />
            <AutoHeightTextarea
              classInput={styles.textarea}
              value={promo.description}
              placeholder="Description ..."
              label={"Description *"}
              onChange={({ target }) =>
                handleChange("description", target.value)
              }
              error={errorVisible && validationError.includes("description")}
              disabled={!isEditMode && !isCreateMode}
            />
          </div>
          <div className={styles.blc_right}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("promoPhrase"),
              })}
              value={promo.promoPhrase}
              placeholder="Promo phrase ..."
              label={"Promo phrase *"}
              onChange={({ target }) =>
                handleChange("promoPhrase", target.value)
              }
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              error={errorVisible && validationError.includes("promoPhrase")}
              disabled={!isEditMode && !isCreateMode}
            />
            <Dropdown
              options={promoStatuses}
              value={promo.status}
              placeholder="Status ..."
              label={"Status *"}
              setValue={(selectedValue) =>
                handleChange("status", selectedValue)
              }
              disabled={!isEditMode && !isCreateMode}
            />
            <Dropdown
              options={promoTypes}
              value={promo.type}
              placeholder="Type ..."
              label={"Type *"}
              setValue={(selectedValue) => handleChange("type", selectedValue)}
              disabled={!isEditMode && !isCreateMode}
            />
            <Dropdown
              options={promoGroup}
              value={promo.group}
              placeholder="Group ..."
              label={"Group *"}
              setValue={(selectedValue) => handleChange("group", selectedValue)}
              disabled={!isEditMode && !isCreateMode}
            />
            {(isEditMode || isCreateMode) && (
              <button
                className={cn("button", styles.save_button)}
                disabled={!isValid && errorVisible}
                onClick={save}
                onFocus={() => setErrorVisible(true)}
              >
                Save
              </button>
            )}
          </div>
        </div>
      )}

      {!isLoading && isEmptyObject(promo) && !isCreateMode && (
        <div className={styles.info}>No promo</div>
      )}

      {isLoading && (
        <div className={styles.blc_loader}>
          <Loader className={styles.loader} />
          <span>Loading</span>
        </div>
      )}
    </Card>
  );
};

export default PromoCard;
