import { createSlice } from "@reduxjs/toolkit";

const initialState = { visible: false };

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    display(state) {
      state.visible = true;
    },
    hide(state) {
      state.visible = false;
    },
  },
});

export const { display, hide } = loaderSlice.actions;
export default loaderSlice.reducer;
