import React, { useState, useEffect } from "react";
import cn from "classnames";

import styles from "./PopularModelStats.module.sass";
import Table from "./Table";
import Loader from "../Loader";
import RetryLoading from "../RetryLoading";
import Plug from "../Plug";
import Card from "../../../components-dev/Card";
import Form from "../../../components-dev/Form";
import Tooltip from "../../../components-dev/Tooltip";
import axios from "../../../utils/axios";

const indicators = [
  {
    title: "Views",
    color: "#B5E4CA",
  },
  {
    title: "Customizations",
    color: "#B1E5FC",
  },
  {
    title: "AR Try-ons",
    color: "#CABDFF",
  },
  {
    title: "Transfers",
    color: "#FFBC99",
  },
];

const PopularModelStats = ({ dateInterval }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    setLimit(3);
    fetchData();
  }, [dateInterval]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("stats/popular-models", {
        params: { ...dateInterval },
      });

      const popularModelsStats = dataMapper(data);

      setData(popularModelsStats);
      setLoading(false);
      setErrorLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorLoading(true);
    }
  };

  const dataMapper = (data) => {
    const percentageOfNumber = (count, sum) =>
      `${Number((count / (sum / 100)).toFixed(2))}%`;

    const percentageOfMaxNumber = (count, max) =>
      `${Number((count / (max / 100)).toFixed(2))}%`;

    let maxOperationCount = 0;

    return data
      .map((x) => {
        const allOperationCount =
          x.usersItemViewedCount +
          x.usersTransferSuccess +
          x.usersCustomizationSaved +
          x.usersArLaunched;

        maxOperationCount =
          maxOperationCount > allOperationCount
            ? maxOperationCount
            : allOperationCount;

        return {
          ...x,
          allOperationCount,
        };
      })
      .map((x) => {
        return {
          digitalModelId: x.digitalModelId,
          digitalModelName: x.digitalModelName,
          digitalModelPreviewUrl: x.digitalModelPreviewUrl,
          brandId: x.brandId,
          brandName: x.brandName,
          progress: percentageOfMaxNumber(
            x.allOperationCount,
            maxOperationCount
          ),
          items: [
            {
              indicatorTitle: indicators[0].title,
              color: indicators[0].color,
              counter: x.usersItemViewedCount,
              width: percentageOfNumber(
                x.usersItemViewedCount,
                x.allOperationCount
              ),
            },
            {
              indicatorTitle: indicators[1].title,
              color: indicators[1].color,
              counter: x.usersCustomizationSaved,
              width: percentageOfNumber(
                x.usersCustomizationSaved,
                x.allOperationCount
              ),
            },
            {
              indicatorTitle: indicators[2].title,
              color: indicators[2].color,
              counter: x.usersArLaunched,
              width: percentageOfNumber(x.usersArLaunched, x.allOperationCount),
            },
            {
              indicatorTitle: indicators[3].title,
              color: indicators[3].color,
              counter: x.usersTransferSuccess,
              width: percentageOfNumber(
                x.usersTransferSuccess,
                x.allOperationCount
              ),
            },
          ].filter((item) => item.counter),
        };
      });
  };

  const filterBySearch = (data) => {
    if (!search || !data.length) return data;

    return data.filter((x) =>
      x.digitalModelName
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase())
    );
  };

  const paginate = () => {
    setLimit((prev) => prev + 5);
  };

  return (
    <Card
      className={styles.card}
      title="Popular models"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={cn(styles.form, {
              [styles.hide]: !data.length || isErrorLoading,
            })}
            value={search}
            setValue={setSearch}
            placeholder="Search models"
            type="text"
            name="search"
            icon="search"
          />
          <Tooltip
            className={styles.tooltip}
            title="Distribution of user activity by digital models in the selected period"
            icon="info-stroke"
            place="left"
          />
        </>
      }
    >
      {isLoading && <Loader className={styles.loader} />}

      {!isLoading && data.length && (
        <Table
          data={filterBySearch(data).slice(0, limit)}
          indicators={indicators}
          hide={isLoading}
        />
      )}

      {!isLoading && data.length > 3 && filterBySearch(data).length > limit && (
        <div className={styles.pagination_control} onClick={paginate}>
          <button
            className={cn("button-stroke", "button-small", styles.button)}
          >
            Load more
          </button>
        </div>
      )}

      {!isLoading && !data.length && <Plug className={styles.plug} />}

      {!isLoading && isErrorLoading && (
        <RetryLoading className={styles.retry_loading} retry={fetchData} />
      )}
    </Card>
  );
};

export default PopularModelStats;
