import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";

const TextInput = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  classCopyIcon,
  copyToClipboard,
  clear,
  onClear,
  currency,
  tooltip,
  place,
  error,
  errorIcon,
  ...props
}) => {
  const dispatch = useDispatch();

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(displayNotificationPopup("copied to clipboard"));
  };

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        { [styles.error]: error },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {Array.isArray(label) ? (
            <>
              <span>{label[0]}</span>
              <span className={styles.label_info}>{label[1]}</span>
            </>
          ) : (
            <span>{label}</span>
          )}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={cn(styles.wrap, { [styles.error_icon]: errorIcon })}>
        <input
          className={cn(classInput, styles.input, {
            [styles.disabled]: props.disabled,
          })}
          {...props}
        />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {copy && (
          <button
            className={cn(styles.copy, classCopyIcon)}
            onClick={() => copyTextToClipboard(props.value)}
          >
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {clear && (
          <button className={cn(styles.clear)} onClick={onClear}>
            <Icon name="clear" size="20" />{" "}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
    </div>
  );
};

export default TextInput;
