import React from "react";
import cn from "classnames";

import styles from "./ImageStub.module.sass";

const ImageStub = ({ className, src, alt }) => {
  return (
    <img
      className={cn(styles.image, className)}
      src={src || "/images/icons/image/light.svg"}
      alt={alt || "icon"}
    />
  );
};

export default ImageStub;
