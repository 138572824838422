import React, { useState, useEffect } from "react";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./Slide.module.sass";
import Icon from "../../../components-dev/Icon";
import TextInput from "../../../components-dev/TextInput";
import AutoHeightTextarea from "../../../components-dev/AutoHeightTextarea";
import Switch from "../../../components-dev/Switch";
import Tooltip from "../../../components-dev/Tooltip";
import StoryImageLoader from "../../../components-dev/StoryImageLoader";
import StoryColorPicker from "../../../components-dev/StoryColorPicker";
import { basicRole } from "../../../assets/roles";
import { generateImgUrl } from "../../../utils/generateImgUrl";

const Slide = ({
  data,
  ctaButtons,
  index,
  setEditable,
  onSave,
  onDelete,
  isGeneral,
  setAsGeneral,
  setGeneralColors,
  errorVisible,
  setValid,
  disabled,
}) => {
  const [slide, setSlide] = useState(data);
  const [clipboard, setClipboard] = useState(data);
  const [isEditMode, setEditMode] = useState(false);
  const [isColorMode, setColorMode] = useState(false);
  const [isGeneralChanges, setGeneralChanges] = useState(isGeneral);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    setSlide(data);
    setClipboard(data);
  }, [data]);

  useEffect(() => {
    setGeneralChanges(isGeneral);
  }, [isGeneral]);

  useEffect(() => {
    setEditable(isEditMode ? `slide${index}` : null);
  }, [isEditMode]);

  useEffect(() => {
    validate();
  }, [slide]);

  const validate = () => {
    const { error } = Joi.object({
      id: Joi.optional(),
      title: Joi.string().max(40).required(),
      text: Joi.string().max(100).required(),
      slideImage: Joi.string().required(),
      slideBackgroundColor: Joi.string().required(),
      slideTitleColor: Joi.string().required(),
      slideTextColor: Joi.string().required(),
      uploadedFile: Joi.optional(),
    }).validate(slide, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const save = () => {
    onSave(slide);
    setClipboard(slide);
    if (isGeneralChanges) {
      setAsGeneral(index);
      setGeneralColors({
        slideBackgroundColor: slide.slideBackgroundColor,
        slideTitleColor: slide.slideTitleColor,
        slideTextColor: slide.slideTextColor,
      });
    } else {
      if (isGeneral) {
        setAsGeneral(null);
        setGeneralColors(null);
      }
    }
    setEditMode(false);
  };

  const cancel = () => {
    setSlide(clipboard);
    setEditMode(false);
    setGeneralChanges(isGeneral);
    setColorPickerVisible(false);
  };

  const onChangeStoryImageLoader = async (uploadedFile) => {
    const slideUrl = await generateImgUrl(uploadedFile);
    setSlide((prev) => ({ ...prev, slideImage: slideUrl, uploadedFile }));
  };

  return (
    <div className={cn(styles.content, disabled && styles.blurred)}>
      <div className={cn(styles.card, styles.slide)}>
        <div className={styles.card_head}>
          <div className={cn("title-blue", styles.title)}>{`Slide ${
            index + 1
          }`}</div>
          {!basicRole && (
            <button
              className={cn(styles.btn_edit)}
              onClick={() =>
                setEditMode((prev) => (prev === true ? cancel() : true))
              }
              disabled={disabled}
            >
              <Icon name="edit" size="20" />
            </button>
          )}
        </div>
        <div
          className={styles.prototype}
          style={{
            backgroundColor: slide.slideBackgroundColor,
            backgroundImage: `url(${slide.slideImage})`,
          }}
        >
          {/* <div className={styles.slide_cancel}>
            <img src="/images/icons/close/light.svg" alt="cancel" />
          </div> */}
          <div className={styles.slide_info}>
            <span style={{ color: slide.slideTitleColor }}>{slide.title}</span>
            <span style={{ color: slide.slideTextColor }}>{slide.text}</span>
            {index > 0 && ctaButtons && (
              <div className={styles.slide_cta}>
                {ctaButtons.map((btn, idx) => (
                  <button
                    className={styles.btn_cta}
                    style={{
                      display: btn.displayInStory ? "block" : "none",
                      color: btn.textColor,
                      backgroundColor: btn.buttonColor,
                    }}
                    disabled={true}
                    key={idx}
                  >
                    {btn.text}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {isEditMode && (
        <div className={cn(styles.card, styles.slide_edit)}>
          <div className={styles.card_head}>
            <div className={cn("title-blue", styles.title)}>{`Slide ${
              index + 1
            } edit`}</div>
            {index > 0 && (
              <button
                className={cn(styles.btn_delete)}
                onClick={() => onDelete(data.id)}
              >
                <Icon name="trash" size="20" />
              </button>
            )}
          </div>

          <div className={styles.slide_edit_section}>
            <div className={styles.image_upload_title}>
              <span>Background image upload</span>
              <Tooltip
                className={styles.tooltip}
                title="Square image 1:1 with transparent background"
                icon="info"
                place="bottom"
              />
            </div>
            <StoryImageLoader
              btnTitle="image"
              imageUrl={slide.uploadedFile?.name}
              delay={onChangeStoryImageLoader}
              validationError={
                errorVisible && validationError.includes("slideImage")
              }
            />
          </div>

          <div className={styles.slide_edit_section}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("title"),
              })}
              classLabel={styles.text_input_label}
              value={slide.title}
              placeholder="Header of the slide"
              label={"Header"}
              maxLength="40"
              onChange={({ target }) =>
                setSlide((prev) => ({ ...prev, title: target.value }))
              }
              error={errorVisible && validationError.includes("title")}
            />
          </div>

          <div className={styles.slide_edit_section}>
            <AutoHeightTextarea
              classInput={styles.textarea}
              value={slide.text}
              placeholder="Text for slide"
              label={"Text"}
              maxLength="100"
              onChange={({ target }) =>
                setSlide((prev) => ({ ...prev, text: target.value }))
              }
              error={errorVisible && validationError.includes("text")}
            />
          </div>

          <div className={styles.slide_edit_section}>
            <div className={styles.color_picker_control}>
              <span>Change colors</span>
              <button
                onClick={() => setColorMode((prev) => !prev)}
                style={{
                  transform: isColorMode && "rotate(180deg)",
                }}
              >
                <Icon name="arrow-down" size="24" />
              </button>
            </div>

            {isColorMode && (
              <>
                <div className={styles.color_picker_control}>
                  <StoryColorPicker
                    title="Background color"
                    currentColor={slide.slideBackgroundColor}
                    onSaveColor={(selectedColor) => {
                      setSlide((prev) => ({
                        ...prev,
                        slideBackgroundColor: selectedColor,
                      }));
                    }}
                    setColorPickerVisible={setColorPickerVisible}
                  />
                </div>
                <div className={styles.color_picker_control}>
                  <StoryColorPicker
                    title="Pick header color"
                    currentColor={slide.slideTitleColor}
                    onSaveColor={(selectedColor) => {
                      setSlide((prev) => ({
                        ...prev,
                        slideTitleColor: selectedColor,
                      }));
                    }}
                    setColorPickerVisible={setColorPickerVisible}
                  />
                </div>
                <div className={styles.color_picker_control}>
                  <StoryColorPicker
                    title="Pick text color"
                    currentColor={slide.slideTextColor}
                    onSaveColor={(selectedColor) => {
                      setSlide((prev) => ({
                        ...prev,
                        slideTextColor: selectedColor,
                      }));
                    }}
                    setColorPickerVisible={setColorPickerVisible}
                  />
                </div>
                <div className={styles.color_picker_control}>
                  <div className={styles.general_color_switch}>
                    <span>Apply to all slides</span>
                    <Switch
                      value={isGeneralChanges}
                      onChange={() => setGeneralChanges((prev) => !prev)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={styles.slide_edit_section}>
            <button
              className={cn("button", styles.btn_save_slide)}
              disabled={colorPickerVisible}
              onClick={save}
            >
              Done
            </button>
            <button
              className={cn("button-stroke", styles.btn_cancel_slide)}
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slide;
