import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./NewGame.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import Dropdown from "../../components-dev/Dropdown";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import GameTransferDescriptionParams from "../../components-dev/GameTransferDescriptionParams";
import GameImageLoader from "../../components-dev/BrandImageLoader";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { basicRole } from "../../assets/roles";
import gameTypes from "../../assets/gameTypes";

const NewGame = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [game, setGame] = useState({
    gameNameFull: "",
    gameNameShort: "",
    gameType: "eth",
    gameDescription: "",
    isActive: true,
    transferDescriptionParams: null,
    gameLogoUrl: "",
    gameImageUrl: "",
  });
  const [gameImageFile, setGameImageFile] = useState(null);
  const [gameLogoFile, setGameLogoFile] = useState(null);

  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (basicRole) history.goBack();
  }, []);

  useEffect(() => {
    validate();
  }, [game]);

  const handleChange = (prop, value) => {
    setGame((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      gameNameFull: Joi.string().max(255).required(),
      gameNameShort: Joi.string().max(255).required(),
      gameType: Joi.string()
        .valid(...gameTypes)
        .required(),
      gameDescription: Joi.string().required(),
      isActive: Joi.boolean().required(),
      transferDescriptionParams: Joi.array()
        .items(
          Joi.object({
            step: Joi.number().integer().min(1).required(),
            text: Joi.string().required(),
            image: Joi.string()
              .pattern(/^([A-z0-9-_+]+\/)*([A-z0-9-_]+\.(png|jpg|jpeg))$/i)
              .max(2000),
          })
        )
        .min(1)
        .allow(null),
      gameLogoUrl: Joi.string().required(),
      gameImageUrl: Joi.string().required(),
    }).validate(game, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (type, uploadedFile, sizeLimit = 10000000) => {
    if (uploadedFile?.size > sizeLimit) {
      displayNotificationPopup("error: file size limit");
      return;
    }

    if (
      uploadedFile?.type.includes("image") &&
      !uploadedFile?.type.includes("image/gif")
    ) {
      const imgUrl = await generateImgUrl(uploadedFile);
      handleChange(type, imgUrl);
      type === "gameImageUrl"
        ? setGameImageFile(uploadedFile)
        : setGameLogoFile(uploadedFile);
    } else {
      displayNotificationPopup("error: unexpected file");
    }
  };

  const onDeletePreview = (type) => {
    handleChange(type, "");
    type === "gameImageUrl" ? setGameImageFile(null) : setGameLogoFile(null);
  };

  const uploadImageToS3 = async (files, path) => {
    const bodyFormData = new FormData();
    files.forEach((file) => bodyFormData.append("image", file));

    try {
      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path,
        },
      });

      return resp.data;
    } catch (err) {
      throw err;
    }
  };

  const updateGameImages = async (gameId) => {
    try {
      let gameImage = null;
      let gameLogo = null;

      if (gameImageFile) {
        [gameImage] = await uploadImageToS3(
          [gameImageFile],
          `games/${gameId}/preview`
        );
      }
      if (gameLogoFile) {
        [gameLogo] = await uploadImageToS3(
          [gameLogoFile],
          `games/${gameId}/logo`
        );
      }

      await axios.put(`games/${gameId}`, {
        gameImage,
        gameLogo,
      });
    } catch (err) {
      displayNotificationPopup("error: upload image(s)");
    }
  };

  const createGame = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        gameNameFull: game.gameNameFull,
        gameType: game.gameType,
        gameDescription: game.gameDescription,
        isActive: game.isActive,
      };

      if (game.gameNameShort) body.gameNameShort = game.gameNameShort;
      if (game.transferDescriptionParams)
        body.transferDescriptionParams = game.transferDescriptionParams;

      const { data } = await axios.post("games", body);

      if (data.id && (gameImageFile || gameLogoFile)) {
        await updateGameImages(data.id);
      }

      hideLoader();
      displayNotificationPopup("saved successfully");
      history.push(`/games/${data.id}`);
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    <div>
      <InfoNavigation link={"/games"} parts={["Games", "Create game"]} />
      <div className={styles.head}>
        <div>
          <div className={cn("h3", styles.title)}>Create game</div>
        </div>
      </div>

      <Card>
        <div className={cn(styles.game)}>
          <div className={styles.blc_left}>
            <GameImageLoader
              label="Logo *"
              imgSrc={game.gameLogoUrl}
              isEditMode={true}
              isError={errorVisible && validationError.includes("gameLogoUrl")}
              onUpload={(file) => onUploadPreview("gameLogoUrl", file)}
              onDelete={() => onDeletePreview("gameLogoUrl")}
            />
            <GameImageLoader
              label="Image *"
              imgSrc={game.gameImageUrl}
              isEditMode={true}
              isError={errorVisible && validationError.includes("gameImageUrl")}
              onUpload={(file) => onUploadPreview("gameImageUrl", file)}
              onDelete={() => onDeletePreview("gameImageUrl")}
            />
          </div>
          <div className={styles.blc_right}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("gameNameFull"),
              })}
              value={game.gameNameFull}
              placeholder="Full name ..."
              label={"Full game name *"}
              onChange={({ target }) =>
                handleChange("gameNameFull", target.value)
              }
              error={errorVisible && validationError.includes("gameNameFull")}
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("gameNameShort"),
              })}
              value={game.gameNameShort}
              placeholder="Short name ..."
              label={"Short game name *"}
              onChange={({ target }) =>
                handleChange("gameNameShort", target.value)
              }
              error={errorVisible && validationError.includes("gameNameShort")}
            />
            <Dropdown
              value={game.gameType}
              setValue={(selectedValue) => {
                handleChange("gameType", selectedValue);
              }}
              options={gameTypes}
              label={"Game type *"}
            />
            <AutoHeightTextarea
              classInput={styles.textarea}
              value={game.gameDescription}
              placeholder="Description ..."
              label={"Description *"}
              onChange={({ target }) =>
                handleChange("gameDescription", target.value)
              }
              error={
                errorVisible && validationError.includes("gameDescription")
              }
            />
            <GameTransferDescriptionParams
              data={game.transferDescriptionParams || []}
              onChange={(tdParams) =>
                setGame((prev) => ({
                  ...prev,
                  transferDescriptionParams: tdParams.length ? tdParams : null,
                }))
              }
              validationError={
                errorVisible &&
                validationError.includes("transferDescriptionParams") &&
                validationError
              }
              disabled={false}
            />
            <div className={styles.blc_switch}>
              <span>Active</span>
              <Switch
                value={game.isActive}
                onChange={() =>
                  setGame((prev) => ({
                    ...prev,
                    isActive: !prev.isActive,
                  }))
                }
              />
            </div>
            <button
              className={cn("button", styles.save_button)}
              disabled={!isValid && errorVisible}
              onClick={createGame}
              onFocus={() => setErrorVisible(true)}
            >
              Save
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewGame;
