import React, { useState, useEffect } from "react";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./ImageUploader.module.sass";
import ListUploader from "./ListUploader";
import TextInput from "../../components-dev/TextInput";
import axios from "../../utils/axios";

const ImageUploader = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const [path, setPath] = useState("");
  const [data, setData] = useState([{ file: null, fileName: "", link: "" }]);
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    validate();
  }, [path]);

  const validate = () => {
    const { error } = Joi.object({
      path: Joi.string()
        .pattern(/^[\w-]+([/]?[\w-]+){0,3}$/i)
        .allow(""),
    }).validate({ path });

    setValid(error ? false : true);
  };

  const uploadImagesToS3 = async () => {
    try {
      displayLoader();

      const bodyFormData = new FormData();
      data.forEach((el) => bodyFormData.append("image", el.file));

      const { data: response } = await axios.post(
        `files/images`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            path: path || "common",
          },
        }
      );

      const list = data.map((el, idx) => ({ ...el, link: response.data[idx] }));
      setData(list);

      // localStorage.setItem("imgLinks", JSON.stringify(list));
      displayNotificationPopup("uploaded");
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className={styles.title}>Upload to folder</div>
      <div className={styles.card}>
        <TextInput
          classInput={cn(styles.text_input, {
            [styles.text_input_error]: !isValid,
          })}
          value={path}
          placeholder="Folder path ..."
          label={"Folder path"}
          onChange={({ target }) => setPath(target.value)}
          error={!isValid}
        />
        <ListUploader data={data} onChange={(list) => setData(list)} />
        <button
          className={cn("button", styles.save_button)}
          disabled={!isValid || data.filter((el) => !el.file).length}
          onClick={uploadImagesToS3}
        >
          Create link
        </button>
      </div>
    </>
  );
};

export default ImageUploader;
