import { format, getYear } from "date-fns";

export const formatDateInterval = (dateInterval, dateFormat = "d MMM") => {
  let startDate = new Date(dateInterval.startDate);
  let endDate = new Date(dateInterval.endDate);

  if (dateFormat.includes("y") && getYear(startDate) === getYear(endDate)) {
    dateFormat = "d MMM";
  }

  startDate = format(startDate, dateFormat);
  endDate = format(endDate, dateFormat);

  return `${startDate} - ${endDate}`;
};
