import React from "react";
import cn from "classnames";

import styles from "./Cell.module.sass";

const Cell = ({
  data,
  greyIndicator,
  greenIndicator,
  purpleIndicator,
  blueIndicator,
  redIndicator,
  yellowIndicator,
}) => {
  return (
    <div className={styles.cell}>
      <div className={styles.box}>
        <div className={styles.number}>{data.count}</div>
        <div className={styles.line}>
          <div
            className={cn(
              styles.progress,
              { [styles.grey]: greyIndicator },
              { [styles.green]: greenIndicator },
              { [styles.purple]: purpleIndicator },
              { [styles.blue]: blueIndicator },
              { [styles.red]: redIndicator },
              { [styles.yellow]: yellowIndicator }
            )}
            style={{
              width: data.progress,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Cell;
