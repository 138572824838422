import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import cn from "classnames";

import styles from "./Story.module.sass";
import Thumbnail from "./Thumbnail";
import Slide from "./Slide";
import Cta from "./Cta";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { reorderStories } from "../../utils/reorderStories";
import { uploadImagesToS3 } from "../../utils/uploadImagesToS3";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { basicRole } from "../../assets/roles";

const Story = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [story, setStory] = useState({});
  const [editable, setEditable] = useState(null);
  const [isValid, setValid] = useState({
    thumbnail: false,
    slide0: false,
  });
  const [errorVisible, setErrorVisible] = useState(false);

  const [slGeneral, setSlGeneral] = useState(null);
  const [generalColorSettings, setGeneralColorSettings] = useState(null);

  const { id: storyId } = useParams();
  const history = useHistory();

  // useEffect(() => {
  //   if (basicRole) history.goBack();
  // }, []);

  useEffect(() => {
    fetchStory();
  }, []);

  useEffect(() => {
    if (generalColorSettings) {
      setStory((prev) => ({
        ...prev,
        slides: {
          ...prev.slides,
          a: prev.slides.a.map((s, idx) => {
            return {
              ...s,
              slideBackgroundColor: generalColorSettings.slideBackgroundColor,
              slideTitleColor: generalColorSettings.slideTitleColor,
              slideTextColor: generalColorSettings.slideTextColor,
            };
          }),
        },
      }));
    }
  }, [generalColorSettings]);

  const fetchStory = async () => {
    try {
      displayLoader();

      const {
        data: {
          records: [story],
        },
      } = await axios.get("news/preview", {
        params: { newsPreviewId: storyId },
      });

      if (story) {
        const slides = story.pages.map((slide) => ({
          id: slide.id,
          title: slide.title,
          text: slide.textContent,
          slideImage: slide.backgroundImageUrl,
          slideBackgroundColor: slide.styles.backgroundColor,
          slideTitleColor: slide.styles.titleColor,
          slideTextColor: slide.styles.textColor,
        }));
        const ctaButtons =
          [...story.pages].pop()?.buttons?.map((btn) => ({
            button: `${btn.index === 0 ? "Primary" : "Secondary"} button`,
            displayInStory: true,
            text: btn.text,
            buttonColor: btn.styles.buttonColor,
            textColor: btn.styles.buttonTextColor,
            linkAction: btn.actionIos.onButtonTapAction,
            link: btn.actionIos.onButtonTapAction.includes("http")
              ? btn.actionIos.onButtonTapAction
              : "",
          })) || [];

        setStory({
          isActive: story.isActive,
          lastSaved: story.updatedAt,
          defaultFileUrl: story.defaultFileUrl,
          thumbnail: {
            title: story.title,
            previewImage: story.backgroundPreviewImageUrl,
            previewBackgroundColor: story.styles.backgroundColor,
            previewTextColor: story.styles.titleColor,
          },
          slides: {
            a: slides,
          },
          cta: [
            ctaButtons.find((btn) => btn.button === "Primary button") || {
              button: "Primary button",
              displayInStory: false,
              text: "ok",
              buttonColor: "#FFFFFF",
              textColor: "#000000",
              linkAction: "openMarketplace",
            },
            ctaButtons.find((btn) => btn.button === "Secondary button") || {
              button: "Secondary button",
              displayInStory: false,
              text: "ok",
              buttonColor: "#000000",
              textColor: "#FFFFFF",
              linkAction: "openMarketplace",
            },
          ],
        });
      }
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  const addSlide = () => {
    setStory((prev) => ({
      ...prev,
      slides: {
        ...prev.slides,
        a: [
          ...prev.slides.a,
          {
            id: `${prev.slides.a.length + 1}-${Date.now()}`,
            title: "",
            text: "",
            slideImage: null,
            slideBackgroundColor:
              generalColorSettings?.slideBackgroundColor || "#000000",
            slideTitleColor: generalColorSettings?.slideTitleColor || "#FFFFFF",
            slideTextColor: generalColorSettings?.slideTextColor || "#FFFFFF",
          },
        ],
      },
    }));
  };

  const updateStory = async (isActive) => {
    const { thumbnail, cta } = story;

    try {
      displayLoader();

      if (thumbnail.uploadedFile) {
        const [uploadedFileUrl] = await uploadImagesToS3(
          [thumbnail.uploadedFile],
          `stories/previews`
        );
        thumbnail.previewImage = uploadedFileUrl;
      } else {
        thumbnail.previewImage = thumbnail.previewImage
          ? thumbnail.previewImage.replace(story.defaultFileUrl, "")
          : null;
      }

      const ctaButtons = cta
        .map((btn, idx) => ({
          ...btn,
          index: idx,
        }))
        .filter((btn) => btn.displayInStory)
        .map((btn) => ({
          isActive,
          index: btn.index,
          uuid: uuidv4(),
          text: btn.text,
          actionIos: {
            onButtonTapAction: btn.link || btn.linkAction,
          },
          actionAndroid: {
            onButtonTapAction: btn.link || btn.linkAction,
          },
          styles: {
            buttonTextColor: btn.textColor,
            buttonColor: btn.buttonColor,
          },
        }));

      const slides = await Promise.all(
        story.slides.a.map(async (slide, idx, arr) => {
          let uploadedFileUrl = slide.slideImage
            ? slide.slideImage.replace(story.defaultFileUrl, "")
            : null;
          if (slide.uploadedFile) {
            [uploadedFileUrl] = await uploadImagesToS3(
              [slide.uploadedFile],
              `stories/previews`
            );
          }

          return {
            isActive,
            title: slide.title,
            textContent: slide.text,
            backgroundImageUrl: uploadedFileUrl,
            styles: {
              backgroundColor: slide.slideBackgroundColor,
              titleColor: slide.slideTitleColor,
              textColor: slide.slideTextColor,
            },
            buttons: arr.length === idx + 1 && idx ? ctaButtons : null,
          };
        })
      );

      const payload = {
        isActive,
        title: thumbnail.title,
        backgroundPreviewImageUrl: thumbnail.previewImage,
        styles: {
          backgroundColor: thumbnail.previewBackgroundColor,
          titleColor: thumbnail.previewTextColor,
        },
        pages: slides,
      };

      Object.keys(payload).forEach((key) => {
        if (payload[key] === null || payload[key] === undefined)
          delete payload[key];
      });

      await axios.put(`news/preview/${storyId}`, payload);

      history.push(`/stories`);
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  return (
    !isEmptyObject(story) && (
      <div className={styles.page}>
        <div className={styles.wrap_content}>
          <div className={styles.head}>
            <div className={cn("h3", styles.title)}>
              {story.thumbnail.title}
            </div>
            <div
              className={cn(
                styles.story_status,
                story.isActive
                  ? styles.story_status_active
                  : styles.story_status_unpublished
              )}
            >
              {story.isActive ? "Active story" : "Unpublished story"}
            </div>
          </div>

          <div className={styles.content}>
            <Thumbnail
              data={story.thumbnail}
              setEditable={setEditable}
              onSave={(value) =>
                setStory((prev) => ({ ...prev, thumbnail: value }))
              }
              errorVisible={errorVisible}
              setValid={(val) =>
                setValid((prev) => ({
                  ...prev,
                  thumbnail: val,
                }))
              }
              disabled={editable && editable !== "thumbnail"}
            />

            <DragDropContext
              onDragEnd={({ destination, source }) => {
                // dropped outside the list
                if (!destination) return;

                const sortedSlides = reorderStories(
                  story.slides,
                  source,
                  destination
                );

                // FIX: validation and general settings when changing sorting
                // story.slides
                // console.log(
                //   "source: ",
                //   source.index,
                //   "destination: ",
                //   destination.index
                // );

                setStory((prev) => ({ ...prev, slides: sortedSlides }));
              }}
            >
              <div>
                {Object.entries(story.slides).map(([k, slides]) => (
                  <Droppable
                    droppableId={k}
                    type="CARD"
                    direction="horizontal"
                    isCombineEnabled={true}
                    key={k}
                  >
                    {(dropProvided) => (
                      <div {...dropProvided.droppableProps}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                          ref={dropProvided.innerRef}
                        >
                          {slides.map((slide, idx) => (
                            <Draggable
                              key={slide.id}
                              draggableId={slide.id}
                              index={idx}
                              isDragDisabled={editable !== null}
                            >
                              {(dragProvided) => (
                                <div
                                  {...dragProvided.dragHandleProps}
                                  {...dragProvided.draggableProps}
                                  ref={dragProvided.innerRef}
                                >
                                  <Slide
                                    data={slide}
                                    ctaButtons={
                                      slides.length - 1 === idx
                                        ? story.cta
                                        : null
                                    }
                                    setEditable={setEditable}
                                    onSave={(value) =>
                                      setStory((prev) => ({
                                        ...prev,
                                        slides: {
                                          ...prev.slides,
                                          a: prev.slides.a.map((s) => {
                                            if (s.id === slide.id) return value;
                                            return s;
                                          }),
                                        },
                                      }))
                                    }
                                    onDelete={(slideId) => {
                                      setStory((prev) => ({
                                        ...prev,
                                        slides: {
                                          ...prev.slides,
                                          a: prev.slides.a.filter(
                                            (s) => s.id !== slideId
                                          ),
                                        },
                                      }));
                                      setValid((prev) => ({
                                        ...prev,
                                        [`slide${idx}`]: true,
                                      }));
                                      setEditable(null);
                                    }}
                                    isGeneral={slGeneral === idx}
                                    setAsGeneral={setSlGeneral}
                                    setGeneralColors={(value) => {
                                      setGeneralColorSettings(value);
                                    }}
                                    errorVisible={errorVisible}
                                    setValid={(val) =>
                                      setValid((prev) => ({
                                        ...prev,
                                        [`slide${idx}`]: val,
                                      }))
                                    }
                                    disabled={
                                      editable && editable !== `slide${idx}`
                                    }
                                    index={idx}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {dropProvided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>

            <div className={cn(styles.slide_add, editable && styles.blurred)}>
              <button
                className={cn(styles.btn_add)}
                onClick={addSlide}
                disabled={editable}
              >
                <Icon name="add" size="24" />
              </button>
            </div>

            <Cta
              data={story.cta}
              setEditable={setEditable}
              onSave={(value) => setStory((prev) => ({ ...prev, cta: value }))}
              errorVisible={errorVisible}
              setValid={(val) =>
                setValid((prev) => ({
                  ...prev,
                  сta: val,
                }))
              }
              disabled={editable && editable !== "cta"}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.date}>
            <img src="/images/icons/check_all/light.svg" alt="icon" />
            <div>
              <span>Last saved</span>
              <span>
                {format(new Date(story.lastSaved), "MMM d, yyyy - HH:mm")}
              </span>
            </div>
          </div>
          <div className={styles.story_controls}>
            <button
              className={cn("button-stroke", styles.btn_redirect)}
              onClick={() => history.push("/stories")}
            >
              <Icon name="arrow-left" size="24" />
              <span>Back</span>
            </button>
            {story.isActive ? (
              <button
                className={cn("button-stroke", styles.btn_unpublish)}
                onClick={() => updateStory(false)}
                disabled={editable}
              >
                Unpublish
              </button>
            ) : (
              <button
                className={cn("button-stroke", styles.btn_save_draft)}
                onClick={() => updateStory(false)}
                disabled={editable}
              >
                Save Draft
              </button>
            )}
            <button
              className={cn("button-stroke", styles.btn_publish)}
              onClick={() => updateStory(true)}
              disabled={
                errorVisible &&
                (!Object.values(isValid).every((el) => el) || editable)
              }
              onFocus={() => setErrorVisible(true)}
            >
              Publish now
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default Story;
