import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import styles from "./Items.module.sass";
import Card from "../../../components-dev/Card";
import CreationForm from "./CreationForm";
import Loader from "../../../components-dev/Loader";
import RedirectTableCell from "../../../components-dev/RedirectTableCell";
import Icon from "../../../components-dev/Icon";
import { display as displayNotificationPopup } from "../../../store/reducers/notificationPopup";
import axios from "../../../utils/axios";

const Items = ({ className, digitalModel }) => {
  const [digitalItems, setDigitalItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    fetchDigitalItems();
  }, [page]);

  const fetchDigitalItems = async () => {
    if (page > 1 && digitalItems.length === total) {
      observer.current.disconnect();
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get("digitalitem", {
        params: { digitalModelId: digitalModel.id, limit: 10, page },
      });

      setTotal(data.totalRecords);
      setDigitalItems((prev) => [...prev, ...data.records]);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(displayNotificationPopup("copied to clipboard"));
  };

  return (
    <>
      <Card
        className={styles.card}
        title={"Digital items"}
        classTitle="title-green"
        info={!!total && ["Items", total]}
        btnCreate={{
          title: "Create digital items",
          onClick: () => setFormVisible(true),
        }}
      >
        <div className={cn(styles.wrapper, className)}>
          {digitalItems.length ? (
            <div className={cn(styles.table)}>
              <div className={cn(styles.row)}>
                <div className={styles.col}>Item ID</div>
                <div className={styles.col}>Owner</div>
                <div className={styles.col}>Link</div>
                <div className={styles.col}>Status</div>
              </div>
              {digitalItems.map((item, index) => (
                <div className={styles.row_content} key={index}>
                  <div className={styles.col_content}>
                    <RedirectTableCell
                      text={item.id}
                      to={`/digital-items/${item.id}`}
                    />
                  </div>
                  <div className={styles.col_content}>
                    <div className={styles.user_nickname}>
                      {item.user?.nickname}
                    </div>
                  </div>
                  <div className={styles.col_content} title={item.deeplink}>
                    <div className={styles.deeplink}>{item.deeplink}</div>
                    <button
                      className={styles.copy}
                      onClick={() => copyTextToClipboard(item.deeplink)}
                    >
                      <Icon name="copy" size="18" />
                    </button>
                  </div>
                  <div className={styles.col_content}>
                    <div
                      className={cn(
                        item.isActive ? "status-green" : "status-red"
                      )}
                    >
                      {item.isActive ? "Active" : "Not active"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            !isLoading && <div className={styles.info}>No digital items</div>
          )}
          <div ref={loader}></div>
          {isLoading && (
            <div className={styles.foot}>
              <div className={styles.blc_loader}>
                <Loader className={styles.loader} />
                <span>Loading</span>
              </div>
            </div>
          )}
        </div>
      </Card>
      <CreationForm
        visible={formVisible}
        setVisible={setFormVisible}
        digitalModel={digitalModel}
        onCreate={(newDigitalItems) => {
          setDigitalItems((prev) => [...newDigitalItems, ...prev]);
          setTotal((prev) => prev + newDigitalItems.length);
        }}
      />
    </>
  );
};

export default Items;
