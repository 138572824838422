import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { format } from "date-fns";

import styles from "./Row.module.sass";
import RedirectTableCell from "../../../../components-dev/RedirectTableCell";
import Icon from "../../../../components-dev/Icon";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../../../store/reducers/notificationPopup";
import axios from "../../../../utils/axios";
import eventBus from "../../../../utils/eventBus";
import { wheelClickEventHandler } from "../../../../utils/wheelClickEventHandler";
import { superAdminRole } from "../../../../assets/roles";

const Row = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteTransferRequest = async (transferRequestId) => {
    try {
      dispatch(displayLoader());

      await axios.delete(`/transfer-requests/${transferRequestId}`);

      eventBus.emit("delete_transfer_request_event", transferRequestId);

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("deleted successfully"));
    } catch (err) {
      dispatch(hideLoader());
      displayNotificationPopup("error");
    }
  };

  return (
    <div
      className={cn(styles.row, [
        item.transferStatus === "deactivated" && styles.row_opacity,
      ])}
    >
      <div className={styles.col}>
        <div
          className={styles.preview}
          onClick={(e) => {
            if (e.ctrlKey || e.metaKey)
              window.open(`/transfer-requests/${item.id}`);
            else history.push(`/transfer-requests/${item.id}`);
          }}
          onAuxClick={wheelClickEventHandler(`/transfer-requests/${item.id}`)}
        >
          <img
            srcSet={item.digitalItem?.digitalModel?.previewUrl}
            src={item.digitalItem?.digitalModel?.previewUrl}
            alt="Item"
          />
        </div>
      </div>
      <div className={styles.col}>
        <RedirectTableCell
          text={item.digitalItem?.digitalModel?.name}
          to={`/digital-models/${item.digitalItem?.digitalModel?.id}`}
          maxWidth={styles.redirect}
        />
      </div>
      <div className={styles.col}>
        <div className={styles.transfer_game}>{item.game?.name}</div>
      </div>
      <div className={styles.col}>
        <div className={styles.user_nickname}>{item.user?.nickname}</div>
      </div>
      <div className={styles.col}>
        <div className={cn(styles.transfer_date)}>
          {format(new Date(item.createdAt), "yyyy-MM-dd")}
        </div>
      </div>
      <div className={styles.col}>
        <div className={cn(styles.transfer_date)}>
          {format(new Date(item.updatedAt), "yyyy-MM-dd")}
        </div>
      </div>
      <div className={styles.col}>
        <div
          className={cn(
            styles.transfer_status,
            styles[`transfer_status_${item.transferStatus}`]
          )}
        >
          {item.transferStatus}
        </div>
      </div>
      {superAdminRole && (
        <div className={cn(styles.col, styles.col_delete)}>
          <button
            disabled={item.transferStatus !== "deactivated"}
            onClick={() => deleteTransferRequest(item.id)}
          >
            <Icon
              name="trash"
              size="18"
              fill={
                item.transferStatus === "deactivated" ? "#ff6a55" : "#fdc5bd"
              }
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Row;
