import React from "react";
import cn from "classnames";

import styles from "./RetryLoading.module.sass";

const RetryLoading = ({ className, retry }) => {
  return (
    <div className={cn(styles.retry, className)}>
      <div className={styles.content}>
        <img
          className={styles.icon}
          src={`/images/icons/warning/red.svg`}
          alt="warning"
        />
        <div className={styles.info}>
          There was an error loading data for this component.
        </div>
        <button className={styles.btn_loading} onClick={retry}>
          Try again
        </button>
      </div>
    </div>
  );
};

export default RetryLoading;
