import Cookies from "js-cookie";

export const redirectToLoginPage = () => {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  localStorage.clear();
  localStorage.setItem("isAuthorized", false);

  window.location.replace("/sign-in");
};
