import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import cn from "classnames";

import styles from "./DigitalModelProductImages.module.sass";
import Tooltip from "../Tooltip";
import Icon from "../Icon";
import FileUploader from "../FileUploader";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";

const DigitalModelProductImages = ({
  label,
  tooltip,
  images,
  param,
  onChange,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);

  const dispatch = useDispatch();

  const { id: digitalModelId } = useParams();

  const onUpload = async (uploadedFile, sizeLimit = 10000000) => {
    if (
      !uploadedFile?.type.includes("image") ||
      uploadedFile?.type.includes("image/gif")
    ) {
      dispatch(displayNotificationPopup("error: file type"));
      return;
    }

    if (uploadedFile?.size > sizeLimit) {
      dispatch(displayNotificationPopup("error: file size limit"));
      return;
    }

    try {
      setLoading(true);

      const imgUrl = await generateImgUrl(uploadedFile);

      //loading effect >>
      if (param === "images") {
        onChange([...images, imgUrl]);
      }

      if (param === "sizeGuide") {
        onChange({
          description: "",
          tables: [...images, imgUrl].map((img) => ({
            image: img,
          })),
        });
      }
      //<<

      setImageIndex(images.length);

      const [uploadedImageUrl] = await uploadImageToS3(
        [uploadedFile],
        `digitalmdl/${digitalModelId}/${param}`
      );
      const response = await updateDigitalModelProductImages([
        ...images,
        uploadedImageUrl,
      ]);
      onChange(response[param]);
    } catch (error) {
      dispatch(displayNotificationPopup(`error: ${error.message}`));

      //loading effect >>
      if (param === "images") {
        onChange(images.slice(0, -1));
      }

      if (param === "sizeGuide") {
        onChange({
          description: "",
          tables: images.slice(0, -1).map((img) => ({
            image: img,
          })),
        });
      }
      //<<
    } finally {
      setLoading(false);
      setImageIndex(null);
    }
  };

  const onDelete = async (idx) => {
    try {
      setLoading(true);

      setImageIndex(idx);
      const upd = images.filter((_, index) => index !== idx);
      const response = await updateDigitalModelProductImages(upd);
      onChange(response[param]);
    } catch (error) {
      dispatch(displayNotificationPopup(`error: ${error.message}`));
    } finally {
      setLoading(false);
      setImageIndex(null);
    }
  };

  const uploadImageToS3 = async (files, path) => {
    const bodyFormData = new FormData();
    files.forEach((file) => bodyFormData.append("image", file));

    try {
      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path,
        },
      });

      return resp.data;
    } catch (err) {
      throw err;
    }
  };

  const updateDigitalModelProductImages = async (images) => {
    try {
      let body;

      if (param === "images") {
        body = {
          [param]: images.length ? images : null,
        };
      }

      if (param === "sizeGuide") {
        body = {
          [param]: images.length
            ? {
                description: "",
                tables: images.map((img) => ({
                  image: img,
                })),
              }
            : null,
        };
      }

      const { data: response } = await axios.put(
        `digitalmdl/${digitalModelId}`,
        body
      );
      return response;
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className={styles.wrp}>
      <div className={styles.blc_label}>
        {label}
        {tooltip && (
          <Tooltip
            className={styles.tooltip}
            title={tooltip}
            icon="info"
            place="right"
          />
        )}
      </div>
      <div
        className={styles.blc_loader}
        style={{ backgroundColor: images.length ? "none" : "#EFEFEF" }}
      >
        {images.length ? (
          <div className={styles.product_images}>
            {images.map((img, idx) => (
              <div
                className={cn(styles.product_image)}
                key={idx}
                style={{ backgroundImage: `url(${img})` }}
              >
                {!isLoading && (
                  <button
                    className={styles.btn_remove}
                    onClick={() => onDelete(idx)}
                  >
                    <Icon name="trash" size="18" fill="#ff6a55" />
                  </button>
                )}
                {isLoading && idx === imageIndex && (
                  <div className={styles.product_image_loader}>
                    <img src="/images/icons/loader/light.svg" alt="icon" />
                  </div>
                )}
              </div>
            ))}
            <FileUploader onUpload={onUpload} disabled={isLoading}>
              <div className={styles.btn_uploader_m}>
                <Icon name="upload" size="24" />
              </div>
            </FileUploader>
          </div>
        ) : (
          <div className={styles.btn_uploader_wrp}>
            <FileUploader onUpload={onUpload} disabled={isLoading}>
              <div className={styles.btn_uploader}>
                <Icon name="upload" size="24" />
                <span>Click or drop image</span>
              </div>
            </FileUploader>
          </div>
        )}
      </div>
    </div>
  );
};

export default DigitalModelProductImages;
