import React from "react";
import cn from "classnames";

import styles from "./ProductDetails.module.sass";
import TextInput from "../TextInput";

const ProductDetails = ({ details, onChange, validationError, disabled }) => {
  return (
    <div className={styles.blc_dtls}>
      <div className={styles.dtl_label}>Details *</div>
      {details.map((detail, idx) => (
        <div
          className={cn(styles.dtl, {
            [styles.dtl_last]: details.length - 1 === idx,
          })}
          key={idx}
        >
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(`details[${idx}].name`),
            })}
            value={detail.name}
            placeholder="name"
            onChange={({ target }) => {
              details[idx] = {
                name: target.value,
                value: detail.value,
                key: detail.key,
              };
              onChange(details);
            }}
            error={
              validationError &&
              validationError.includes(`details[${idx}].name`)
            }
            disabled={disabled}
          />
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(`details[${idx}].value`),
            })}
            value={detail.value}
            placeholder="value"
            onChange={({ target }) => {
              details[idx] = {
                name: detail.name,
                value: target.value,
                key: detail.key,
              };
              onChange(details);
            }}
            error={
              validationError &&
              validationError.includes(`details[${idx}].value`)
            }
            disabled={disabled}
          />
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(`details[${idx}].key`),
            })}
            value={detail.key}
            placeholder="key"
            onChange={({ target }) => {
              details[idx] = {
                name: detail.name,
                value: detail.value,
                key: target.value,
              };
              onChange(details);
            }}
            error={
              validationError && validationError.includes(`details[${idx}].key`)
            }
            disabled={disabled}
          />
        </div>
      ))}
      {!disabled && (
        <div className={styles.blc_dtl_controls}>
          <button
            className={styles.btn_remove_dtl}
            onClick={() => onChange(details.slice(0, -1))}
            disabled={details.length < 2}
          >
            <img src="/images/icons/remove/light.svg" alt="remove" />
          </button>
          <button
            className={styles.btn_add_dtl}
            onClick={() => onChange([...details, { name: "", value: "" }])}
          >
            <img src="/images/icons/add/light.svg" alt="add" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
