import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./Admin.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import Dropdown from "../../components-dev/Dropdown";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import roles from "../../assets/roles";

const Admin = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const [admin, setAdmin] = useState({});
  const [clipboard, setClipboard] = useState({});
  const { id: adminId } = useParams();

  useEffect(() => {
    scrollToTop();
    fetchAdmin();
  }, []);

  useEffect(() => {
    validate();
  }, [admin]);

  const fetchAdmin = async () => {
    try {
      displayLoader();

      const { data } = await axios.get(`admins/${adminId}`);
      const { roles: adminRoles, ...adminData } = data;

      const privilegedRole = adminRoles?.includes("superadmin")
        ? "superadmin"
        : adminRoles?.includes("admin")
        ? "admin"
        : "basic";
      const props = {
        ...adminData,
        role: privilegedRole,
      };

      setAdmin(props);
      setClipboard(props);

      hideLoader();
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setAdmin((prev) => ({
        ...clipboard,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setAdmin((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .max(255)
        .required(),
      role: Joi.string()
        .valid(...roles)
        .required(),
    }).validate(
      {
        email: admin.email,
        role: admin.role,
      },
      { abortEarly: false }
    );
    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const updateAdmin = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        email: admin.email,
        role: admin.role,
      };

      await axios.put(`admins/${adminId}`, body);

      setClipboard((prev) => ({
        ...prev,
        ...body,
      }));
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    !isEmptyObject(admin) && (
      <>
        <InfoNavigation link={"/admins"} parts={["Admins", "Admin name"]} />
        <div className={styles.head}>
          <div className={styles.blc_title}>
            <div className={cn("h3", styles.title)}>{admin.email}</div>
          </div>
          <button
            className={cn("button-stroke", styles.switch_button, [
              !isEditMode ? styles.info : styles.edit,
            ])}
            onClick={changeMode}
          >
            <Icon name={!isEditMode ? "edit" : "close"} size="24" />
            <span>{!isEditMode ? "Edit" : "Cancel"}</span>
          </button>
        </div>

        <Card>
          <div className={styles.admin_form}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  isEditMode && validationError.includes("email"),
              })}
              value={admin.email}
              placeholder="Email ..."
              label={"Email"}
              onChange={({ target }) => handleChange("email", target.value)}
              error={isEditMode && validationError.includes("email")}
              disabled={!isEditMode}
            />
            <Dropdown
              className={styles.dropdown}
              value={admin.role}
              setValue={(selectedValue) => {
                handleChange("role", selectedValue);
              }}
              options={roles}
              label={"Role"}
              disabled={!isEditMode}
            />
            {isEditMode && (
              <button
                className={cn("button", styles.save_button)}
                disabled={!isValid}
                onClick={updateAdmin}
              >
                Save
              </button>
            )}
          </div>
        </Card>
      </>
    )
  );
};

export default Admin;
