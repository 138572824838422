import React from "react";
import cn from "classnames";

import styles from "./Card.module.sass";
import Icon from "../Icon";
import { basicRole } from "../../assets/roles";

const Card = ({
  className,
  title,
  classTitle,
  head,
  classCardHead,
  info,
  classCardInfo,
  btnCreate,
  children,
}) => {
  return (
    <div className={cn(styles.card, className)}>
      {title && (
        <div className={cn(styles.head, classCardHead)}>
          <div className={cn(classTitle, styles.title)}>{title}</div>
          {info && (
            <div className={cn(styles.info, classCardInfo)}>
              {info[0] && <span>{info[0]}</span>}
              {info[1] && <span>{info[1]}</span>}
            </div>
          )}
          {btnCreate && !basicRole && (
            <button
              className={cn("button-white", styles.btn_create)}
              onClick={btnCreate.onClick}
              onAuxClick={btnCreate.onAuxClick}
            >
              <Icon name="add" size="24" />
              <span>{btnCreate.title || "Create"}</span>
            </button>
          )}
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
