import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./DigitalProductOffers.module.sass";
import Card from "../Card";
import PreviewLoader from "../BrandImageLoader";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import Icon from "../Icon";
import Loader from "../Loader";
import axios from "../../utils/axios";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { uploadImagesToS3 } from "../../utils/uploadImagesToS3";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";
import { basicRole } from "../../assets/roles";
import { productOfferTypes } from "../../assets/productOfferTypes";
import { productOfferStatuses } from "../../assets/productOfferStatuses";

const DigitalProductOffers = ({ digitalProduct }) => {
  const [formData, setFormData] = useState({});
  const [clipboard, setClipboard] = useState([]);
  const [digitalPreviewFile, setDigitalPreviewFile] = useState(null);
  const [phygitalPreviewFile, setPhygitalPreviewFile] = useState(null);
  const [isEditMode, setEditMode] = useState(false);
  const [isCreateMode, setCreateMode] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    validate();
  }, [formData]);

  const fetch = async () => {
    try {
      setLoading(true);

      const {
        data: { offers },
      } = await axios.get("marketplace/product-offers", {
        params: {
          productId: digitalProduct.id,
        },
      });

      if (offers.length === 2) {
        const digitalProductOffer = offers.find(
          (o) => o.type === productOfferTypes.digitalOnly
        );
        const phygitalProductOffer = offers.find(
          (o) => o.type === productOfferTypes.phygital
        );
        const data = {
          digitalId: digitalProductOffer.id,
          digitalPreview: digitalProductOffer.previewURL,
          digitalStatus: digitalProductOffer.status,
          digitalAmount: digitalProductOffer.amount,
          phygitalId: phygitalProductOffer.id,
          phygitalPreview: phygitalProductOffer.previewURL,
          phygitalStatus: phygitalProductOffer.status,
          phygitalAmount: phygitalProductOffer.amount,
          storeURL: phygitalProductOffer.storeURL,
          storeURLAlias: phygitalProductOffer.storeURLAlias,
        };
        setFormData(data);
        setClipboard(data);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setFormData(clipboard);
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setFormData((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      digitalPreview: Joi.string().required(),
      digitalStatus: Joi.string()
        .valid(...productOfferStatuses)
        .required(),
      digitalPrice: Joi.string()
        .regex(/^\d+([.]\d{1,2})?$/)
        .required(),
      phygitalPreview: Joi.string().required(),
      phygitalStatus: Joi.string()
        .valid(...productOfferStatuses)
        .required(),
      phygitalPrice: Joi.string()
        .regex(/^\d+([.]\d{1,2})?$/)
        .required(),
    }).validate(
      {
        digitalPreview: formData.digitalPreview,
        digitalStatus: formData.digitalStatus,
        digitalPrice: formData.digitalAmount?.price.toString(),
        phygitalPreview: formData.phygitalPreview,
        phygitalStatus: formData.phygitalStatus,
        phygitalPrice: formData.phygitalAmount?.price.toString(),
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (type, uploadedFile, sizeLimit = 10000000) => {
    if (uploadedFile?.size > sizeLimit) {
      dispatch(displayNotificationPopup("error: file size limit"));
      return;
    }

    if (
      uploadedFile?.type.includes("image") &&
      !uploadedFile?.type.includes("image/gif")
    ) {
      const imgUrl = await generateImgUrl(uploadedFile);
      handleChange(type, imgUrl);
      type === "digitalPreview"
        ? setDigitalPreviewFile(uploadedFile)
        : setPhygitalPreviewFile(uploadedFile);
    } else {
      dispatch(displayNotificationPopup("error: unexpected file"));
    }
  };

  const onDeletePreview = (type) => {
    handleChange(type, "");
    type === "digitalPreview"
      ? setDigitalPreviewFile(null)
      : setPhygitalPreviewFile(null);
  };

  const uploadImages = async () => {
    try {
      let digitalPreview = null;
      let phygitalPreview = null;

      if (digitalPreviewFile) {
        [digitalPreview] = await uploadImagesToS3(
          [digitalPreviewFile],
          `products/${digitalProduct.id}/offers`
        );
      }
      if (phygitalPreviewFile) {
        [phygitalPreview] = await uploadImagesToS3(
          [phygitalPreviewFile],
          `products/${digitalProduct.id}/offers`
        );
      }

      return { digitalPreview, phygitalPreview };
    } catch (err) {
      throw err;
    }
  };

  const save = async () => {
    if (!isValid) return;

    try {
      dispatch(displayLoader());

      const reqBody = {
        productId: digitalProduct.id,
        storeURL: "",
        storeURLAlias: "",
      };
      const { digitalPreview, phygitalPreview } = await uploadImages();

      const {
        data: { offer: digitalProductOffer },
      } = await axios[isCreateMode ? "post" : "put"](
        `marketplace/product-offers${
          isEditMode ? `/${formData.digitalId}` : ""
        }`,
        {
          ...reqBody,
          type: productOfferTypes.digitalOnly,
          status: formData.digitalStatus,
          amount: formData.digitalAmount,
          previewURL:
            digitalPreview ||
            (formData.digitalPreview.includes("common")
              ? `common${formData.digitalPreview.split("common")[1]}`
              : `products${formData.digitalPreview.split("products")[1]}`),
        }
      );
      const {
        data: { offer: phygitalProductOffer },
      } = await axios[isCreateMode ? "post" : "put"](
        `marketplace/product-offers${
          isEditMode ? `/${formData.phygitalId}` : ""
        }`,
        {
          ...reqBody,
          type: productOfferTypes.phygital,
          status: formData.phygitalStatus,
          amount: formData.phygitalAmount,
          previewURL:
            phygitalPreview ||
            `products${formData.phygitalPreview.split("products")[1]}`,
        }
      );

      const fData = {
        digitalId: digitalProductOffer.id,
        digitalPreview: digitalProductOffer.previewURL,
        digitalStatus: digitalProductOffer.status,
        digitalAmount: digitalProductOffer.amount,
        phygitalId: phygitalProductOffer.id,
        phygitalPreview: phygitalProductOffer.previewURL,
        phygitalStatus: phygitalProductOffer.status,
        phygitalAmount: phygitalProductOffer.amount,
        storeURL: phygitalProductOffer.storeURL,
        storeURLAlias: phygitalProductOffer.storeURLAlias,
      };

      setFormData(fData);
      setClipboard(fData);

      if (isCreateMode) {
        setCreateMode(false);
      } else {
        setEditMode(false);
      }

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("saved successfully"));
    } catch (err) {
      dispatch(hideLoader());
      dispatch(displayNotificationPopup("error"));
    }
  };

  return (
    <Card className={styles.card} title={"Offers"} classTitle="title-green">
      {!basicRole && !isLoading && isEmptyObject(formData) && (
        <button
          className={cn("button-stroke", styles.create_button, styles.info)}
          onClick={() => {
            setCreateMode(true);
            setFormData({
              digitalStatus: productOfferStatuses[1],
              phygitalStatus: productOfferStatuses[1],
              amount: digitalProduct.amount,
            });
          }}
        >
          <Icon name="add" size="24" />
          <span>Add offers</span>
        </button>
      )}

      {!basicRole &&
        !isLoading &&
        !isEmptyObject(formData) &&
        !isCreateMode && (
          <button
            className={cn("button-stroke", styles.switch_button, [
              !isEditMode ? styles.info : styles.edit,
            ])}
            onClick={changeMode}
          >
            <Icon name={!isEditMode ? "edit" : "close"} size="24" />
            <span>{!isEditMode ? "Edit" : "Cancel"}</span>
          </button>
        )}

      {(!isEmptyObject(formData) || isCreateMode) && (
        <div className={cn(styles.form)}>
          <div className={styles.blc_left}>
            <p className={styles.blc_left_subtitle}>Digital-only</p>
            <PreviewLoader
              loaderClassName={styles.preview_loader}
              label="Preview *"
              imgSrc={formData.digitalPreview}
              isEditMode={isEditMode || isCreateMode}
              isError={
                errorVisible && validationError.includes("digitalPreview")
              }
              onUpload={(file) => onUploadPreview("digitalPreview", file)}
              onDelete={() => onDeletePreview("digitalPreview")}
            />
            <Dropdown
              options={productOfferStatuses}
              value={formData.digitalStatus}
              placeholder="Status ..."
              label={"Status *"}
              setValue={(selectedValue) =>
                handleChange("digitalStatus", selectedValue)
              }
              disabled={!isEditMode && !isCreateMode}
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("digitalPrice"),
              })}
              value={formData.digitalAmount?.price}
              placeholder="Price ..."
              label={["Price *", "100 = $1"]}
              onChange={({ target }) =>
                handleChange("digitalAmount", {
                  currency: "USD",
                  price: target.value,
                })
              }
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              error={errorVisible && validationError.includes("digitalPrice")}
              disabled={!isEditMode && !isCreateMode}
            />
          </div>
          <div className={styles.blc_right}>
            <p className={styles.blc_right_subtitle}>Phygital</p>
            <PreviewLoader
              loaderClassName={styles.preview_loader}
              label="Preview *"
              imgSrc={formData.phygitalPreview}
              isEditMode={isEditMode || isCreateMode}
              isError={
                errorVisible && validationError.includes("phygitalPreview")
              }
              onUpload={(file) => onUploadPreview("phygitalPreview", file)}
              onDelete={() => onDeletePreview("phygitalPreview")}
            />
            <Dropdown
              options={productOfferStatuses}
              value={formData.phygitalStatus}
              placeholder="Status ..."
              label={"Status *"}
              setValue={(selectedValue) =>
                handleChange("phygitalStatus", selectedValue)
              }
              disabled={!isEditMode && !isCreateMode}
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("phygitalPrice"),
              })}
              value={formData.phygitalAmount?.price}
              placeholder="Price ..."
              label={["Price *", "100 = $1"]}
              onChange={({ target }) =>
                handleChange("phygitalAmount", {
                  currency: "USD",
                  price: target.value,
                })
              }
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              error={errorVisible && validationError.includes("phygitalPrice")}
              disabled={!isEditMode && !isCreateMode}
            />
          </div>
        </div>
      )}

      {(isEditMode || isCreateMode) && (
        <button
          className={cn("button", styles.save_button)}
          disabled={!isValid && errorVisible}
          onClick={save}
          onFocus={() => setErrorVisible(true)}
        >
          Save
        </button>
      )}

      {!isLoading && isEmptyObject(formData) && !isCreateMode && (
        <div className={styles.info}>No offers</div>
      )}

      {isLoading && (
        <div className={styles.blc_loader}>
          <Loader className={styles.loader} />
          <span>Loading</span>
        </div>
      )}
    </Card>
  );
};

export default DigitalProductOffers;
