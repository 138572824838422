import { Switch, Route, Redirect } from "react-router-dom";

import SignIn from "./SignIn";
import NotFoundPage from "./NotFound";

import Analytics from "./Analytics";
import Brands from "./Brands";
import Brand from "./Brand";
import NewBrand from "./NewBrand";
import DigitalModels from "./DigitalModels";
import DigitalModel from "./DigitalModel";
import NewDigitalModel from "./NewDigitalModel";
import DigitalItems from "./DigitalItems";
import DigitalItem from "./DigitalItem";
import DigitalProducts from "./DigitalProducts";
import NewDigitalProduct from "./NewDigitalProduct";
import DigitalProduct from "./DigitalProduct";
import Games from "./Games";
import Game from "./Game";
import NewGame from "./NewGame";
import GameSkins from "./GameSkins";
import TransferRequests from "./TransferRequests";
import TransferRequest from "./TransferRequest";
import Stories from "./Stories";
import Story from "./Story";
import NewStory from "./NewStory";
import Admins from "./Admins";
import Admin from "./Admin";
import NewAdmin from "./NewAdmin";
import Users from "./Users";
import User from "./User";
import Settings from "./Settings";
import ImageUploader from "./ImageUploader";
import Page from "../components-dev/Page";

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/analytics"
        render={() => (
          <Page title="Dashboard">
            <Analytics />
          </Page>
        )}
      />
      <Route
        exact
        path="/brands"
        render={() => (
          <Page title="Brands" createButton createButtonTitle="Create brand">
            <Brands />
          </Page>
        )}
      />
      <Route
        exact
        path="/brands/new"
        render={() => (
          <Page>
            <NewBrand />
          </Page>
        )}
      />
      <Route
        exact
        path="/brands/:id"
        render={() => (
          <Page>
            <Brand />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-models"
        render={() => (
          <Page
            title="Digital models"
            createButton
            createButtonTitle="Create model"
          >
            <DigitalModels />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-models/new"
        render={() => (
          <Page>
            <NewDigitalModel />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-models/:id"
        render={() => (
          <Page>
            <DigitalModel />
          </Page>
        )}
      />
      <Route
        exact
        path="/game-skins"
        render={() => (
          <Page>
            <GameSkins />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-items"
        render={() => (
          <Page title="Digital items">
            <DigitalItems />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-items/:id"
        render={() => (
          <Page>
            <DigitalItem />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-products"
        render={() => (
          <Page
            title="Products"
            createButton
            createButtonTitle="Create product"
          >
            <DigitalProducts />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-products/new"
        render={() => (
          <Page>
            <NewDigitalProduct />
          </Page>
        )}
      />
      <Route
        exact
        path="/digital-products/:id"
        render={() => (
          <Page>
            <DigitalProduct />
          </Page>
        )}
      />
      <Route
        exact
        path="/games"
        render={() => (
          <Page
            title="Games and Metaverses"
            createButton
            createButtonTitle="Create game"
          >
            <Games />
          </Page>
        )}
      />
      <Route
        exact
        path="/games/new"
        render={() => (
          <Page>
            <NewGame />
          </Page>
        )}
      />
      <Route
        exact
        path="/games/:id"
        render={() => (
          <Page>
            <Game />
          </Page>
        )}
      />
      <Route
        exact
        path="/transfer-requests"
        render={() => (
          <Page title="Transfer requests">
            <TransferRequests />
          </Page>
        )}
      />
      <Route
        exact
        path="/transfer-requests/:id"
        render={() => (
          <Page>
            <TransferRequest />
          </Page>
        )}
      />
      <Route
        exact
        path="/stories"
        render={() => (
          <Page title="Stories" createButton createButtonTitle="Create story">
            <Stories />
          </Page>
        )}
      />
      <Route
        exact
        path="/stories/new"
        render={() => (
          <Page removePadding wide>
            <NewStory />
          </Page>
        )}
      />
      <Route
        exact
        path="/stories/:id"
        render={() => (
          <Page removePadding wide>
            <Story />
          </Page>
        )}
      />
      <Route
        exact
        path="/admins"
        render={() => (
          <Page title="Admins" createButton createButtonTitle="Create user">
            <Admins />
          </Page>
        )}
      />
      <Route
        exact
        path="/admins/new"
        render={() => (
          <Page>
            <NewAdmin />
          </Page>
        )}
      />
      <Route
        exact
        path="/admins/:id"
        render={() => (
          <Page>
            <Admin />
          </Page>
        )}
      />
      <Route
        exact
        path="/users"
        render={() => (
          <Page title="Users">
            <Users />
          </Page>
        )}
      />
      <Route
        exact
        path="/users/:id"
        render={() => (
          <Page>
            <User />
          </Page>
        )}
      />
      <Route
        exact
        path="/settings"
        render={() => (
          <Page title="Features">
            <Settings />
          </Page>
        )}
      />
      <Route
        exact
        path="/image-uploader"
        render={() => (
          <Page title="Image upload">
            <ImageUploader />
          </Page>
        )}
      />
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/not-found" component={NotFoundPage} />
      <Redirect exact path="/" to="/analytics" />
      <Redirect path="*" to="/not-found" />
    </Switch>
  );
};

export default Routes;
