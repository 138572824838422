import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./NewDigitalProduct.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import Dropdown from "../../components-dev/Dropdown";
import TextInput from "../../components-dev/TextInput";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import ProductDetails from "../../components-dev/ProductDetails";
import ProductStoreIdentifierDetails from "../../components-dev/ProductStoreIdentifierDetails";
import ImageStub from "../../components-dev/ImageStub";
import axios from "../../utils/axios";
import { scrollToTop } from "../../utils/scrollToTop";
import { toPrettyJson } from "../../utils/gJson";
import { basicRole } from "../../assets/roles";

const NewDigitalProduct = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const history = useHistory();

  const [digitalProduct, setDigitalProduct] = useState({
    digitalModelId: history.location.state?.id,
    digitalModel: history.location.state,
    appStoreProductId: "",
    appStoreIdentifierDetails: [
      {
        discount: "",
        value: "",
      },
    ],
    amount: {
      currency: "USD",
      price: 0,
    },
    available: true,
    details: [
      {
        name: "",
        value: "",
        key: "",
      },
    ],
    previewAttributes: {
      size: "RECTANGLE343X220",
      bgColor: "#1C1C1C",
      priceTagColor: "#ffffff",
      priceTagBorderColor: "#000000",
      borderColor: "#1C1C1C",
    },
  });
  const [digitalModels, setDigitalModels] = useState([]);

  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  useEffect(() => {
    if (basicRole) history.goBack();

    scrollToTop();
  }, []);

  useEffect(() => {
    if (!history.location.state) {
      fetchDigitalModels();
    }
  }, [history.location.state]);

  useEffect(() => {
    validate();
  }, [digitalProduct]);

  const fetchDigitalModels = async () => {
    try {
      displayLoader();

      const { data } = await axios.get("digitalmdl");
      const dModels = data.records.map((m) => ({
        ...m,
        brandName: m.brand?.name,
      }));
      setDigitalModels((prev) => [...prev, ...dModels]);
      if (!digitalProduct.digitalModelId) {
        handleChange("digitalModelId", data.records[0].id);
      }

      hideLoader();
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  const handleChange = (prop, value) => {
    setDigitalProduct((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      digitalModelId: Joi.string().uuid().required(),
      appStoreProductId: Joi.string().required(),
      price: Joi.string()
        .regex(/^\d+([.]\d{1,2})?$/)
        .required(),
      details: Joi.array()
        .items(
          Joi.object({
            name: Joi.string().required(),
            value: Joi.string().required(),
            key: Joi.string().required(),
          })
        )
        .min(1),
      previewAttributes: Joi.string().optional(),
      appStoreIdentifierDetails: Joi.array().items(
        Joi.object({
          discount: Joi.string()
            .regex(/^[1-9]{1}([0-9]{1})?$/)
            .required(),
          value: Joi.string().required(),
        })
      ),
    }).validate(
      {
        digitalModelId: digitalProduct.digitalModelId,
        appStoreProductId: digitalProduct.appStoreProductId,
        price: digitalProduct.amount.price,
        details: digitalProduct.details,
        appStoreIdentifierDetails: digitalProduct.appStoreIdentifierDetails,
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const createDigitalProduct = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        digitalModelId: digitalProduct.digitalModelId,
        appStoreProductId: digitalProduct.appStoreProductId,
        amount: digitalProduct.amount,
        available: digitalProduct.available,
        details: digitalProduct.details,
        previewAttributes: digitalProduct.previewAttributes,
        inStock: 0,
        sold: 0,
        appStoreIdentifierDetails: digitalProduct.appStoreIdentifierDetails,
      };

      const { data } = await axios.post("marketplace/products", body);

      hideLoader();
      displayNotificationPopup("saved successfully");
      history.push(`/digital-products/${data.product.id}`);
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const getPreview = () => {
    const dModel = digitalModels.find(
      (model) => model.id === digitalProduct.digitalModelId
    );
    return dModel?.previewUrl;
  };

  return (
    <>
      <InfoNavigation
        link={"/digital-products"}
        parts={["Products", "Create digital product"]}
      />
      <div className={styles.head}>
        <div>
          <div className={cn("h3", styles.title)}>Create product</div>
        </div>
      </div>

      <Card>
        <div className={cn(styles.digital_model)}>
          <div className={styles.preview}>
            {digitalProduct.digitalModel &&
              (digitalProduct.digitalModel.previewUrl ? (
                <div className={styles.preview_content}>
                  <img
                    srcSet={digitalProduct.digitalModel.previewUrl}
                    src={digitalProduct.digitalModel.previewUrl}
                    alt="Digital product"
                  />
                </div>
              ) : (
                <ImageStub className={styles.stub} alt="Digital product" />
              ))}
            {!digitalProduct.digitalModel &&
              (getPreview() ? (
                <div className={styles.preview_content}>
                  <img
                    srcSet={getPreview()}
                    src={getPreview()}
                    alt="Digital product"
                  />
                </div>
              ) : (
                <ImageStub className={styles.stub} alt="Digital product" />
              ))}
          </div>
          <div className={styles.blc_right}>
            <Dropdown
              value={
                digitalProduct.digitalModel
                  ? digitalProduct.digitalModel.name
                  : digitalModels.find(
                      (model) => model.id === digitalProduct.digitalModelId
                    )?.name
              }
              valueLabel={
                digitalProduct.digitalModel
                  ? digitalProduct.digitalModel.brand?.name
                  : digitalModels.find(
                      (model) => model.id === digitalProduct.digitalModelId
                    )?.brand?.name
              }
              placeholder="Digital model ..."
              setValue={(selectedDigitalModel) => {
                handleChange("digitalModelId", selectedDigitalModel.id);
              }}
              options={digitalModels}
              optionLabel={"brandName"}
              label="Digital model *"
              loading
              disabled={digitalProduct.digitalModel}
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("appStoreProductId"),
              })}
              value={digitalProduct.appStoreProductId}
              placeholder="100_usd_product"
              label="App Store ID *"
              onChange={({ target }) =>
                handleChange("appStoreProductId", target.value)
              }
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              error={
                errorVisible && validationError.includes("appStoreProductId")
              }
            />
            <ProductStoreIdentifierDetails
              details={digitalProduct.appStoreIdentifierDetails}
              onChange={(changedDetails) =>
                setDigitalProduct((prev) => ({
                  ...prev,
                  appStoreIdentifierDetails: changedDetails,
                }))
              }
              validationError={
                errorVisible &&
                validationError.includes("appStoreIdentifierDetails") &&
                validationError
              }
            />
            <div className={cn(styles.group, styles.same)}>
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    errorVisible && validationError.includes("price"),
                })}
                value={digitalProduct.amount.price}
                placeholder="Price ..."
                label={"Price *"}
                onChange={({ target }) =>
                  handleChange("amount", {
                    currency: digitalProduct.amount.currency,
                    price: target.value,
                  })
                }
                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                error={errorVisible && validationError.includes("price")}
              />
              <TextInput
                classInput={cn(styles.text_input)}
                value={digitalProduct.amount.currency}
                label={"Currency *"}
                disabled={true}
              />
            </div>
            <div className={styles.blc_switch}>
              <span>Available</span>
              <Switch
                className={styles.switch}
                value={digitalProduct.available}
                onChange={() =>
                  setDigitalProduct((prev) => ({
                    ...prev,
                    available: !prev.available,
                  }))
                }
              />
            </div>
            <ProductDetails
              details={digitalProduct.details}
              onChange={(changedDetails) =>
                setDigitalProduct((prev) => ({
                  ...prev,
                  details: changedDetails,
                }))
              }
              validationError={
                errorVisible &&
                validationError.includes("details") &&
                validationError
              }
            />
            <AutoHeightTextarea
              className={cn({ ["unchangeable"]: true })}
              classInput={cn(styles.textarea, styles.min_height_125)}
              value={toPrettyJson(digitalProduct.previewAttributes)}
              placeholder="Preview attributes ..."
              label={"Preview attributes (JSON) *"}
              disabled={true}
            />
            <button
              className={cn("button", styles.btn_save)}
              disabled={!isValid && errorVisible}
              onClick={createDigitalProduct}
              onFocus={() => setErrorVisible(true)}
            >
              Save
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default NewDigitalProduct;
