import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";
import { format } from "date-fns";

import styles from "./TransferRequest.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import Dropdown from "../../components-dev/Dropdown";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { basicRole } from "../../assets/roles";
import { transferStatuses } from "../../assets/transferStatuses";

const TransferRequest = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const [transferRequest, setTransferRequest] = useState({});
  const [clipboard, setClipboard] = useState({});

  const [isEditMode, setEditMode] = useState(false);
  const [isValid, setValid] = useState(false);

  const { id: transferRequestId } = useParams();

  useEffect(() => {
    scrollToTop();
    fetchTransferRequest();
  }, []);

  useEffect(() => {
    validate();
  }, [transferRequest]);

  const fetchTransferRequest = async () => {
    try {
      displayLoader();

      const {
        data: { data },
      } = await axios.get(`transfer-requests/${transferRequestId}`);

      setTransferRequest(data);
      setClipboard(data);

      hideLoader();
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setTransferRequest((prev) => ({
        ...clipboard,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const validate = () => {
    const { error } = Joi.object({
      transferStatus: Joi.string()
        .valid(...transferStatuses)
        .required(),
    }).validate(
      {
        transferStatus: transferRequest.transferStatus,
      },
      {
        abortEarly: false,
      }
    );

    setValid(error ? false : true);
  };

  const updateTransferRequest = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      await axios.patch(`transfer-requests/${transferRequestId}`, {
        transferStatus: transferRequest.transferStatus,
      });

      setClipboard(transferRequest);
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    !isEmptyObject(transferRequest) && (
      <>
        <InfoNavigation
          link={"/transfer-requests"}
          parts={[
            "Transfer requests",
            `${transferRequest.digitalItem?.digitalModel?.name} to ${transferRequest.game?.name}`,
          ]}
        />
        <div className={styles.head}>
          <div>
            <div className={cn("h3", styles.title)}>
              {`${transferRequest.digitalItem?.digitalModel?.name} to ${transferRequest.game?.name}`}
            </div>
          </div>
          {!basicRole && (
            <button
              className={cn("button-stroke", styles.switch_button, [
                !isEditMode ? styles.info : styles.edit,
              ])}
              onClick={changeMode}
            >
              <Icon name={!isEditMode ? "edit" : "close"} size="24" />
              <span>{!isEditMode ? "Edit" : "Cancel"}</span>
            </button>
          )}
        </div>

        <Card>
          <div className={cn(styles.transfer_request)}>
            <div className={styles.blc_left}>
              <div className={styles.preview}>
                <div className={styles.preview_content}>
                  <img
                    srcSet={
                      transferRequest.digitalItem?.digitalModel?.previewUrl
                    }
                    src={transferRequest.digitalItem?.digitalModel?.previewUrl}
                    alt="Item"
                  />
                </div>
              </div>
            </div>
            <div className={styles.blc_right}>
              <Dropdown
                value={transferRequest.transferStatus}
                setValue={(selectedValue) => {
                  setTransferRequest((prev) => ({
                    ...prev,
                    transferStatus: selectedValue,
                  }));
                }}
                options={transferStatuses}
                label={"Transfer status"}
                disabled={!isEditMode}
              />
              <Dropdown
                classUnchangeable={cn({ ["unchangeable"]: isEditMode })}
                value={transferRequest.digitalItem?.digitalModel?.name}
                valueLabel={transferRequest.digitalItem?.brand?.name}
                placeholder="Digital model ..."
                label={"Digital model"}
                link={
                  transferRequest.digitalItem?.digitalModel &&
                  `/digital-models/${transferRequest.digitalItem.digitalModel.id}`
                }
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={transferRequest.digitalItemId}
                label={"Item ID"}
                copy
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <div
                className={cn(styles.user_nickname, {
                  ["unchangeable"]: isEditMode,
                })}
              >
                <span>Username</span>
                <span>{transferRequest.user.nickname}</span>
              </div>
              <Dropdown
                classUnchangeable={cn({ ["unchangeable"]: isEditMode })}
                value={transferRequest.game.name}
                placeholder="Game name ..."
                label={"Game name"}
                link={
                  transferRequest.game && `/games/${transferRequest.game.id}`
                }
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={transferRequest.transferParams?.value}
                label={"Wallet address, Username"}
                copy
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input)}
                value={transferRequest.transferParams?.isSaved ? "Yes" : "No"}
                label={"Saved for future transfers"}
                disabled={true}
              />
              <div className={cn(styles.group)}>
                <Dropdown
                  classUnchangeable={cn({ ["unchangeable"]: isEditMode })}
                  value={format(
                    new Date(transferRequest.createdAt),
                    "hh:mm aaa"
                  )}
                  valueLabel={format(
                    new Date(transferRequest.createdAt),

                    "dd MMMM yyyy"
                  )}
                  label={"Created"}
                  disabled={true}
                />
                <Dropdown
                  classUnchangeable={cn({ ["unchangeable"]: isEditMode })}
                  value={format(
                    new Date(transferRequest.updatedAt),
                    "hh:mm aaa"
                  )}
                  valueLabel={format(
                    new Date(transferRequest.updatedAt),

                    "dd MMMM yyyy"
                  )}
                  label={"Updated"}
                  disabled={true}
                />
              </div>
              {isEditMode && (
                <button
                  className={cn("button", styles.save_button)}
                  disabled={!isValid}
                  onClick={updateTransferRequest}
                >
                  <span>Save</span>
                </button>
              )}
            </div>
          </div>
        </Card>
      </>
    )
  );
};

export default TransferRequest;
