import { createSlice } from "@reduxjs/toolkit";

const initialState = { notification: "", visible: false };

const notificationPopupSlice = createSlice({
  name: "notificationPopup",
  initialState,
  reducers: {
    display(state, action) {
      state.notification = action.payload;
      state.visible = true;
    },
    hide(state) {
      state.visible = false;
    },
  },
});

export const { display, hide } = notificationPopupSlice.actions;
export default notificationPopupSlice.reducer;
