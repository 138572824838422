import React from "react";
import { add } from "date-fns";

import styles from "./Row.module.sass";
import Cell from "./Cell";
import { formatDateInterval } from "../../../../utils/formatDateInterval";

const Row = ({ data }) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.date}>
          {formatDateInterval(
            {
              startDate: data.eventDate,
              endDate: add(new Date(data.eventDate), { days: 6 }),
            },
            "y MMM d"
          )}
        </div>
      </div>
      <div className={styles.col}>
        <Cell
          data={{
            count: data.cohortCount,
            progress: data.cohortCountProgress,
          }}
          greyIndicator
        />
      </div>
      <div className={styles.col}>
        <Cell
          data={{
            count: data.becameUsersCount,
            progress: data.becameUsersCountProgress,
          }}
          yellowIndicator
        />
      </div>
      <div className={styles.col}>
        <Cell
          data={{
            count: data.usersItemViewedCount,
            progress: data.usersItemViewedCountProgress,
          }}
          greenIndicator
        />
      </div>
      <div className={styles.col}>
        <Cell
          data={{
            count: data.usersCustomizationSaved,
            progress: data.usersCustomizationSavedProgress,
          }}
          blueIndicator
        />
      </div>
      <div className={styles.col}>
        <Cell
          data={{
            count: data.usersArLaunched,
            progress: data.usersArLaunchedProgress,
          }}
          purpleIndicator
        />
      </div>
      <div className={styles.col}>
        <Cell
          data={{
            count: data.usersTransferSuccess,
            progress: data.usersTransferSuccessProgress,
          }}
          redIndicator
        />
      </div>
    </div>
  );
};

export default Row;
