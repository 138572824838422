import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import styles from "./Row.module.sass";
import RedirectTableCell from "../../../../components-dev/RedirectTableCell";
import Icon from "../../../../components-dev/Icon";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../../../store/reducers/notificationPopup";
import axios from "../../../../utils/axios";
import eventBus from "../../../../utils/eventBus";

const Row = ({ item }) => {
  const dispatch = useDispatch();

  const getRole = (adminRoles) => {
    const privilegedRole = adminRoles.includes("superadmin")
      ? "superadmin"
      : adminRoles.includes("admin")
      ? "admin"
      : "basic";

    return (
      <div className={cn(styles[`role_${privilegedRole}`])}>
        {privilegedRole}
      </div>
    );
  };

  const deleteAdmin = async (adminId) => {
    try {
      dispatch(displayLoader());

      await axios.delete(`admins/${adminId}`);

      eventBus.emit("delete_admin_event", adminId);

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("deleted successfully"));
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <RedirectTableCell text={item.id} to={`/admins/${item.id}`} />
      </div>
      <div className={styles.col}>{item.email}</div>
      <div className={styles.col}>{getRole(item.roles)}</div>
      <div className={styles.col}>
        <button onClick={() => deleteAdmin(item.id)}>
          <Icon name="trash" size="18" fill="#ff6a55" />
        </button>
      </div>
    </div>
  );
};

export default Row;
