import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import styles from "./GameTransferDescriptionParams.module.sass";
import AutoHeightTextarea from "../AutoHeightTextarea";
import Icon from "../Icon";
import axios from "../../utils/axios";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";

const GameTransferDescriptionParams = ({
  data,
  onChange,
  validationError,
  disabled,
}) => {
  const [index, setIndex] = useState(null);
  const hiddenFileInput = useRef(null);

  const dispatch = useDispatch();

  const handleFileInput = () => {
    hiddenFileInput.current.click();
  };

  const onUploadFile = async ({ target }) => {
    const uploadedFile = target.files[0];

    if (
      uploadedFile?.type.includes("image") &&
      !uploadedFile?.type.includes("image/gif")
    ) {
      const [uploadedImage] = await uploadToS3(
        uploadedFile,
        "games/transfer_description_params"
      );
      const params = data.map((el, i) => {
        return i !== index
          ? el
          : {
              step: el.step,
              text: el.text,
              image: uploadedImage,
            };
      });
      onChange(params);
    }
  };

  const uploadToS3 = async (file, path = "common") => {
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);

    try {
      dispatch(displayLoader());

      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path,
        },
      });

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("image uploaded"));

      return resp.data;
    } catch (err) {
      dispatch(hideLoader());
      dispatch(displayNotificationPopup("error: image uploaded"));
    }
  };

  const shortenFilename = (filename) => {
    if (filename.length < 18) return filename;

    let shortName = "";
    for (let i = 0; i < filename.length; i++) {
      if (i < 6 || i > filename.length - 7) shortName += filename[i];
      if (i === 6) shortName += "...";
    }
    return shortName;
  };

  return (
    <div>
      <div className={styles.label}>Transfer description parametres</div>
      {!data.length && disabled && (
        <div className={styles.info_stub}>no params ...</div>
      )}
      {data.map((param, idx) => (
        <div
          className={cn(styles.step, {
            [styles.step_last]: data.length - 1 === idx,
          })}
          key={idx}
        >
          <p className={styles.step_name}>Step {param.step}</p>
          <div className={styles.step_image}>
            {!disabled && (
              <div>
                <button
                  className={cn("button-stroke", styles.btn_file_upload)}
                  onClick={() => {
                    setIndex(idx);
                    handleFileInput();
                  }}
                >
                  <img src="/images/icons/add/light.svg" alt="icon" />
                  <span>Image</span>
                </button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={onUploadFile}
                  style={{ display: "none" }}
                />
              </div>
            )}
            {param.image && (
              <div className={styles.file_name}>
                <span>{shortenFilename(param.image)}</span>
                <Icon name="check" size="20" />
              </div>
            )}
          </div>
          <AutoHeightTextarea
            classInput={cn(styles.textarea)}
            value={param.text}
            placeholder="text"
            onChange={({ target }) => {
              data[idx] = {
                step: param.step,
                text: target.value,
                image: param.image,
              };
              onChange(data);
            }}
            error={
              validationError &&
              validationError.includes(`transferDescriptionParams[${idx}].text`)
            }
            disabled={disabled}
          />
        </div>
      ))}
      {!disabled && (
        <div className={styles.blc_controls}>
          <button
            className={styles.btn_add}
            onClick={() =>
              onChange([
                ...data,
                {
                  step: data.length + 1,
                  text: "",
                },
              ])
            }
          >
            <img src="/images/icons/add/light.svg" alt="add" />
          </button>
          <button
            className={styles.btn_remove}
            onClick={() => onChange(data.slice(0, -1))}
            disabled={!data.length}
          >
            <img src="/images/icons/remove/light.svg" alt="remove" />
          </button>
        </div>
      )}
    </div>
  );
};

export default GameTransferDescriptionParams;
