import React from "react";
import cn from "classnames";

import styles from "./BrandImageLoader.module.sass";
import Icon from "../Icon";
import FileUploader from "../FileUploader";
import ImageStub from "../ImageStub";

const BrandImageLoader = ({
  loaderClassName,
  label,
  imgSrc,
  isEditMode,
  isError,
  onUpload,
  onDelete,
}) => {
  return (
    <div>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={cn(styles.brand_image, loaderClassName, {
          [styles.error]: isError,
        })}
      >
        {imgSrc ? (
          <div className={styles.brand_image_content}>
            <img srcSet={imgSrc} src={imgSrc} alt="Brand" />
            {isEditMode && (
              <>
                <button
                  className={styles.btn_add}
                  style={{
                    backgroundImage: `url("/images/icons/add/light.svg")`,
                  }}
                >
                  <FileUploader onUpload={onUpload} fill />
                </button>
                <button
                  className={styles.btn_delete}
                  style={{
                    backgroundImage: `url("/images/icons/trash/light-red.svg")`,
                  }}
                  onClick={onDelete}
                ></button>
              </>
            )}
          </div>
        ) : isEditMode ? (
          <div className={styles.brand_image_content}>
            <FileUploader onUpload={onUpload}>
              <Icon
                name="add"
                size="52"
                fill={isError ? "#fec7bb" : "#b1b5b9"}
              />
            </FileUploader>
          </div>
        ) : (
          <ImageStub className={styles.stub} alt="Brand" />
        )}
      </div>
    </div>
  );
};

export default BrandImageLoader;
