import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./NewBrand.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import BrandImageLoader from "../../components-dev/BrandImageLoader";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { basicRole } from "../../assets/roles";

const NewBrand = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [brand, setBrand] = useState({
    name: "",
    description: "",
    isActive: true,
    appVisibility: false,
    previewUrl: "",
    logoUrl: "",
  });
  const [previewFile, setPreviewFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);

  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (basicRole) history.goBack();
  }, []);

  useEffect(() => {
    validate();
  }, [brand]);

  const handleChange = (prop, value) => {
    setBrand((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      name: Joi.string().required(),
      description: Joi.string().required(),
      isActive: Joi.boolean().required(),
      appVisibility: Joi.boolean().required(),
      previewUrl: Joi.string().required(),
      logoUrl: Joi.string().required(),
    }).validate(brand, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (type, uploadedFile, sizeLimit = 10000000) => {
    if (uploadedFile?.size > sizeLimit) {
      displayNotificationPopup("error: file size limit");
      return;
    }

    if (
      uploadedFile?.type.includes("image") ||
      !uploadedFile?.type.includes("image/gif")
    ) {
      const imgUrl = await generateImgUrl(uploadedFile);
      handleChange(type, imgUrl);
      type === "previewUrl"
        ? setPreviewFile(uploadedFile)
        : setLogoFile(uploadedFile);
    } else {
      displayNotificationPopup("error: unexpected file");
    }
  };

  const onDeletePreview = (type) => {
    handleChange(type, "");
    type === "previewUrl" ? setPreviewFile(null) : setLogoFile(null);
  };

  const uploadImageToS3 = async (files, path) => {
    const bodyFormData = new FormData();
    files.forEach((file) => bodyFormData.append("image", file));

    try {
      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path,
        },
      });

      return resp.data;
    } catch (err) {
      throw err;
    }
  };

  const updateBrandImages = async (brandId) => {
    try {
      let previewUrl = null;
      let logoUrl = null;

      if (previewFile) {
        [previewUrl] = await uploadImageToS3(
          [previewFile],
          `brands/${brandId}/preview`
        );
      }
      if (logoFile) {
        [logoUrl] = await uploadImageToS3([logoFile], `brands/${brandId}/logo`);
      }

      await axios.put(`brand/${brandId}`, {
        previewUrl,
        logoUrl,
      });
    } catch (err) {
      displayNotificationPopup("error: upload image(s)");
    }
  };

  const createBrand = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const { data } = await axios.post("brand", {
        name: brand.name,
        description: brand.description,
        isActive: brand.isActive,
        appVisibility: brand.appVisibility,
        previewUrl: "tmp",
        logoUrl: "tmp",
      });

      if (data.id && (previewFile || logoFile)) {
        await updateBrandImages(data.id);
      }

      hideLoader();
      displayNotificationPopup("saved successfully");
      history.push(`/brands/${data.id}`);
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    <div>
      <InfoNavigation link={"/brands"} parts={["Brands", "Create brand"]} />
      <div className={styles.head}>
        <div>
          <div className={cn("h3", styles.title)}>Create brand</div>
        </div>
      </div>

      <Card>
        <div className={cn(styles.brand)}>
          <div className={styles.blc_left}>
            <BrandImageLoader
              label="Brand preview *"
              imgSrc={brand.previewUrl}
              isEditMode={true}
              isError={errorVisible && validationError.includes("previewUrl")}
              onUpload={(file) => onUploadPreview("previewUrl", file)}
              onDelete={() => onDeletePreview("previewUrl")}
            />
            <BrandImageLoader
              label="Brand logo *"
              imgSrc={brand.logoUrl}
              isEditMode={true}
              isError={errorVisible && validationError.includes("logoUrl")}
              onUpload={(file) => onUploadPreview("logoUrl", file)}
              onDelete={() => onDeletePreview("logoUrl")}
            />
          </div>

          <div className={styles.blc_right}>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("name"),
              })}
              value={brand.name}
              placeholder="Brand name ..."
              label={"Brand name *"}
              onChange={({ target }) => handleChange("name", target.value)}
              error={errorVisible && validationError.includes("name")}
            />
            <AutoHeightTextarea
              classInput={styles.textarea}
              value={brand.description}
              placeholder="Description ..."
              label={"Description *"}
              onChange={({ target }) =>
                handleChange("description", target.value)
              }
              error={errorVisible && validationError.includes("description")}
            />
            <div className={styles.blc_switch}>
              <span>Active</span>
              <Switch
                className={styles.switch}
                value={brand.isActive}
                onChange={() =>
                  setBrand((prev) => ({
                    ...prev,
                    isActive: !prev.isActive,
                  }))
                }
              />
            </div>
            <div className={styles.blc_switch}>
              <span>App visibility</span>
              <Switch
                className={styles.switch}
                value={brand.appVisibility}
                onChange={() =>
                  setBrand((prev) => ({
                    ...prev,
                    appVisibility: !prev.appVisibility,
                  }))
                }
              />
            </div>
            <button
              className={cn("button", styles.save_button)}
              disabled={!isValid && errorVisible}
              onClick={createBrand}
              onFocus={() => setErrorVisible(true)}
            >
              Save
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NewBrand;
