import { Subject } from "rxjs";
import { filter, map } from "rxjs/operators";

class EventBus {
  subject = new Subject();
  data = null;

  emit(eventName, eventData = null) {
    this.data = eventData;
    this.subject.next({ eventName, eventData });
  }

  on(eventName, action) {
    return this.subject
      .pipe(
        filter((e) => e.eventName === eventName),
        map((e) => e.eventData)
      )
      .subscribe(action);
  }
}

export default new EventBus();
