import React from "react";
import { format } from "date-fns";

import styles from "./UserStatsTooltip.module.sass";

const UserStatsTooltip = ({ active, payload, scorecards }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div className={styles.date}>
          {format(new Date(payload[0].payload.eventDate), "d MMM")}
        </div>
        {payload.map((x, idx) => (
          <div key={idx}>
            <div className={styles.title}>{scorecards[idx].title}</div>
            <div className={styles.flex_group}>
              <div
                className={styles.color}
                style={{ backgroundColor: x.color }}
              ></div>
              <div>{x.value}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default UserStatsTooltip;
