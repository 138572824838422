import React from "react";

import styles from "./PlatformStatsTooltip.module.sass";

const PlatformStatsTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div className={styles.title}>{payload[0].payload.os}</div>
        <div className={styles.flex_group}>
          <div
            className={styles.color}
            style={{ backgroundColor: payload[0].payload.fill }}
          ></div>
          <div>{payload[0].value}%</div>
        </div>
      </div>
    );
  }

  return null;
};

export default PlatformStatsTooltip;
