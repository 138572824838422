import React from "react";

import styles from "./GeographyStatsTooltip.module.sass";

const GeographyStatsTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div className={styles.title}>{payload[0].payload.country}</div>
        <div className={styles.flex_group}>
          <div
            className={styles.color}
            style={{ backgroundColor: "#B1E5FC" }}
          ></div>
          <div>{payload[0].value}</div>
        </div>
      </div>
    );
  }

  return null;
};

export default GeographyStatsTooltip;
