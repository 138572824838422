import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Icon from "../Icon";
import PopupLoader from "../PopupLoader";
import NotificationPopup from "../NotificationPopup";
import { basicRole } from "../../assets/roles";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";

const Page = ({
  wide,
  children,
  title,
  createButton,
  createButtonTitle,
  removePadding,
}) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const isAuthorized = JSON.parse(localStorage.getItem("isAuthorized"));
    if (!isAuthorized) history.push("/sign-in");
  }, []);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        />
        <Header onOpen={() => setVisible(true)} />
        <div className={cn(styles.inner, { [styles.padding0]: removePadding })}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {title && (
              <div className={cn("h3", styles.title)}>
                <span>{title}</span>
                {createButton && !basicRole && (
                  <button
                    className={cn("button-white", styles.create_button)}
                    onClick={(e) => {
                      if (e.ctrlKey || e.metaKey)
                        window.open(`${window.location.pathname}/new`);
                      else history.push(`${window.location.pathname}/new`);
                    }}
                    onAuxClick={wheelClickEventHandler(
                      `${window.location.pathname}/new`
                    )}
                  >
                    <Icon name="add" size="24" />
                    <span>{createButtonTitle || "Create"}</span>
                  </button>
                )}
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
      <PopupLoader />
      <NotificationPopup />
    </>
  );
};

export default withRouter(Page);
