import React, { useEffect } from "react";
import cn from "classnames";

import styles from "./NotificationPopup.module.sass";

const NotificationPopup = ({ notification, visible, hide }) => {
  useEffect(() => {
    if (visible) setTimeout(() => hide(), 5000);
  }, [visible]);

  return (
    <div
      className={cn(styles.notification_popup, { [styles.visible]: visible })}
    >
      <img
        src={`/images/icons/${
          notification.includes("error")
            ? "alert_circled/filled_red"
            : "check_circled/filled_green"
        }.svg`}
        alt="icon"
      />
      <div className={styles.notification}>{notification}</div>
    </div>
  );
};

export default NotificationPopup;
