import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import styles from "./Games.module.sass";
import Game from "./Game";
import Card from "../../components-dev/Card";
import Loader from "../../components-dev/Loader";
import axios from "../../utils/axios";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";

const Games = ({ displayNotificationPopup }) => {
  const [games, setGames] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);
  const history = useHistory();

  const limit = 24;

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    page && fetchGames();
  }, [page]);

  const fetchGames = async () => {
    try {
      setLoading(true);

      const {
        data: { data, pagination },
      } = await axios.get("games", {
        params: { limit, offset: (page - 1) * limit },
      });
      if (data.length) {
        setGames((prev) => [...prev, ...data]);
      } else {
        observer.current.disconnect();
      }

      setLoading(false);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.list}>
          {games.map((game, idx) => (
            <Game
              className={styles.game}
              data={game}
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey) window.open(`/games/${game.id}`);
                else history.push(`/games/${game.id}`);
              }}
              onAuxClick={wheelClickEventHandler(`/games/${game.id}`)}
              key={idx}
            />
          ))}
        </div>
        {isLoading && (
          <div className={styles.foot}>
            <div className={styles.blc_loader}>
              <Loader className={styles.loader} />
              <span>Loading</span>
            </div>
          </div>
        )}
      </div>
      <div ref={loader}></div>
      {!games.length && !isLoading && (
        <div className={styles.info}>No games</div>
      )}
    </Card>
  );
};

export default Games;
