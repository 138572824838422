import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./GameSection.module.sass";
import Icon from "../../../components-dev/Icon";
import FileUploader from "../../../components-dev/FileUploader";
import { wheelClickEventHandler } from "../../../utils/wheelClickEventHandler";

const GameSection = ({ game, onUploadFile, onDeleteFile }) => {
  const history = useHistory();

  return (
    <section>
      <div className={styles.title}>
        <img
          src={game.logo || "/images/game_image_stub.svg"}
          alt="game"
          onClick={(e) => {
            if (e.ctrlKey || e.metaKey) window.open(`/games/${game.id}`);
            else history.push(`/games/${game.id}`);
          }}
          onAuxClick={wheelClickEventHandler(`/games/${game.id}`)}
          onError={(e) => (e.target.src = "/images/game_image_stub.svg")}
        />
        <span>{game.name}</span>
      </div>
      <div className={styles.blc_files}>
        <div className={styles.file}>
          <div className={styles.label}>Skin</div>
          <div className={styles.skin}>
            {game.skinUrl ? (
              <div className={styles.preview}>
                <img srcSet={game.skinUrl} src={game.skinUrl} alt="skin" />
                <button
                  className={styles.btn_preview_add}
                  style={{
                    backgroundImage: `url("/images/icons/add/light.svg")`,
                  }}
                >
                  <FileUploader
                    onUpload={onUploadFile("update_skin", game.id)}
                    fill
                  />
                </button>
                <button
                  className={styles.btn_preview_delete}
                  style={{
                    backgroundImage: `url("/images/icons/trash/light-red.svg")`,
                  }}
                  onClick={onDeleteFile("update_skin", game.id)}
                ></button>
              </div>
            ) : (
              <FileUploader onUpload={onUploadFile("update_skin", game.id)}>
                <Icon name="add" size="56" fill="#b1b5b9" />
              </FileUploader>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GameSection;
