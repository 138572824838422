import React from "react";
import cn from "classnames";

import styles from "./DigitalModelVariants.module.sass";
import TextInput from "../TextInput";
import Icon from "../Icon";

const DigitalModelVariants = ({
  variants,
  onChange,
  validationError,
  disabled,
}) => {
  return (
    <div>
      {variants.map((variant, idx) => (
        <div
          className={cn(styles.variant, {
            [styles.variant_last]: variants.length - 1 === idx,
          })}
          key={idx}
        >
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(`variants[${idx}].name`),
            })}
            value={variant.name}
            placeholder="Variant title ..."
            label={"Variant title"}
            onChange={({ target }) => {
              variants[idx] = {
                id: variant.id,
                name: target.value,
              };
              onChange(variants);
            }}
            error={
              validationError &&
              validationError.includes(`variants[${idx}].name`)
            }
            disabled={disabled}
          />
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(`variants[${idx}].id`),
            })}
            value={variant.id}
            placeholder="Variant ID ..."
            label={"Variant ID"}
            onChange={({ target }) => {
              variants[idx] = {
                id: target.value,
                name: variant.name,
              };
              onChange(variants);
            }}
            error={
              validationError && validationError.includes(`variants[${idx}].id`)
            }
            disabled={disabled}
          />
          {!disabled && (
            <div>
              <button
                className={styles.btn_remove}
                onClick={() =>
                  onChange(variants.filter((_, index) => index !== idx))
                }
              >
                <Icon name="trash" size="18" fill="#ff6a55" />
              </button>
            </div>
          )}
        </div>
      ))}
      {!disabled && (
        <button
          className={cn("button-stroke", styles.btn_add)}
          onClick={() => onChange([...variants, { id: "", name: "" }])}
        >
          <img src="/images/icons/add/light.svg" alt="add" />
          <span>add variant</span>
        </button>
      )}
    </div>
  );
};

export default DigitalModelVariants;
