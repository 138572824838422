import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import cn from "classnames";

import styles from "./Stories.module.sass";
import Card from "../../components-dev/Card";
import Loader from "../../components-dev/Loader";
import axios from "../../utils/axios";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";
import { reorderStories } from "../../utils/reorderStories";

const Stories = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [activeStories, setActiveStories] = useState({
    row1: [],
  });
  const [unpublishedStories, setUnpublishedStories] = useState({
    row1: [],
  });
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchStories();
  }, []);

  const fetchStories = async () => {
    try {
      setLoading(true);

      const {
        data: { records: stories },
      } = await axios.get("news/preview", {
        params: { limit: 100, page: 1 },
      });

      if (stories.length) {
        setActiveStories((prev) => ({
          ...prev,
          row1: stories.filter((story) => story.isActive),
        }));
        setUnpublishedStories((prev) => ({
          ...prev,
          row1: stories.filter((story) => !story.isActive),
        }));
      }

      setLoading(false);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  const handleDragEnd = async ({ destination, source }) => {
    // // dropped outside the list
    if (!destination) return;

    const stories = isActive ? activeStories : unpublishedStories;

    try {
      displayLoader();

      const reordered = reorderStories(stories, source, destination).row1.map(
        (el, idx) => ({ ...el, priority: idx + 1 })
      );

      if (isActive) {
        setActiveStories((prev) => ({
          ...prev,
          row1: reordered,
        }));
      } else {
        setUnpublishedStories((prev) => ({
          ...prev,
          row1: reordered,
        }));
      }

      await axios.post("news/preview/reordere", {
        previews: reordered.map((el) => ({
          id: el.id,
          priority: el.priority,
        })),
      });
    } catch (err) {
      displayNotificationPopup("error");

      isActive ? setActiveStories(stories) : setUnpublishedStories(stories);
    } finally {
      hideLoader();
    }
  };

  return (
    <Card>
      <div className={styles.blc_switch}>
        <button
          className={cn({ [styles.btn_active]: isActive })}
          onClick={() => setIsActive(true)}
        >
          Active
        </button>
        <button
          className={cn({ [styles.btn_active]: !isActive })}
          onClick={() => setIsActive(false)}
        >
          Unpublished
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div>
          {Object.entries(isActive ? activeStories : unpublishedStories).map(
            ([k, _stories]) => (
              <Droppable
                droppableId={k}
                type="CARD"
                direction="horizontal"
                isCombineEnabled={true}
                key={k}
              >
                {(dropProvided) => (
                  <div {...dropProvided.droppableProps}>
                    <div
                      id="list"
                      className={styles.list}
                      ref={dropProvided.innerRef}
                      // onWheel={(e) => {
                      //   const content = document.getElementById("list");
                      //   if (e.deltaY > 0) content.scrollLeft += 100;
                      //   else content.scrollLeft -= 100;
                      // }}
                    >
                      {_stories.map((story, index) => (
                        <Draggable
                          key={story.id}
                          draggableId={story.id}
                          index={index}
                        >
                          {(dragProvided) => (
                            <div
                              {...dragProvided.dragHandleProps}
                              {...dragProvided.draggableProps}
                              ref={dragProvided.innerRef}
                            >
                              <div className={styles.story} key={index}>
                                <div
                                  className={styles.preview}
                                  onClick={(e) => {
                                    if (e.ctrlKey || e.metaKey)
                                      window.open(`/stories/${story.id}`);
                                    else history.push(`/stories/${story.id}`);
                                  }}
                                  onAuxClick={wheelClickEventHandler(
                                    `/stories/${story.id}`
                                  )}
                                  style={{
                                    backgroundColor:
                                      story.styles?.backgroundColor ||
                                      "transparent",
                                    backgroundImage: `url(${story.backgroundPreviewImageUrl})`,
                                  }}
                                ></div>

                                <div className={styles.line}>
                                  <div className={styles.name}>
                                    {story.title}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {dropProvided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            )
          )}
        </div>
      </DragDropContext>

      {isLoading && (
        <div className={styles.foot}>
          <div className={styles.blc_loader}>
            <Loader className={styles.loader} />
            <span>Loading</span>
          </div>
        </div>
      )}

      {(isActive
        ? !activeStories.row1.length
        : !unpublishedStories.row1.length) &&
        !isLoading && <div className={styles.info}>No stories</div>}
    </Card>
  );
};

export default Stories;
