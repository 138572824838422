import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import "./styles/app.sass";
import store from "./store";
import DevScreens from "./screens";
import Routes from "./pages";

const App = () => {
  useEffect(() => {
    let brandName = window.origin.split(".")[1];
    brandName = brandName
      ? brandName.charAt(0).toUpperCase() + brandName.slice(1)
      : "Seamm";
    document.title = `${brandName} - Admin panel`;

    const link = document.querySelector("link[rel~='icon']");
    link.href = `/images/brand_logos/${window.origin.split(".")[1]}.svg`;

    const linkSiteName = document.querySelector("#link_site_name");
    linkSiteName.content = window.origin;

    const linkTitle = document.querySelector("#link_title");
    linkTitle.content = `${brandName} - Admin panel`;
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {/* <Switch>
        <DevScreens />
      </Switch> */}

        <Switch>
          <Routes />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
