import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./BrandCreationForm.module.sass";
import TextInput from "../TextInput";
import Icon from "../Icon";
import axios from "../../utils/axios";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";

const BrandCreationForm = ({ className, visible, setVisible, onCreate }) => {
  const [payload, setPayload] = useState({
    name: "",
    description: "-",
  });
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    validate();
  }, [payload]);

  const handleChange = (prop, value) => {
    setPayload((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      name: Joi.string().required(),
      description: Joi.string().required(),
    }).validate(payload, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const createBrand = async () => {
    if (!isValid) return;

    try {
      dispatch(displayLoader());

      const { data: cteatedDigitalItems } = await axios.post("brand", payload);
      setPayload((prev) => ({
        ...prev,
        name: "",
      }));
      onCreate(cteatedDigitalItems);

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("saved successfully"));
    } catch (err) {
      dispatch(hideLoader());
      dispatch(displayNotificationPopup("error"));
    } finally {
      setVisible(false);
    }
  };

  return (
    <div
      className={cn(styles.popup, {
        [styles.visible]: visible,
      })}
    >
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div className={cn(styles.creation_form, className)}>
          <div>
            <span>Create brand</span>
            <button onClick={() => setVisible(false)}>
              <Icon name="close" size="24" />
            </button>
          </div>
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                errorVisible && validationError.includes("name"),
            })}
            value={payload.name}
            placeholder="Brand name ..."
            label={"Brand name *"}
            onChange={({ target }) => handleChange("name", target.value)}
            error={errorVisible && validationError.includes("name")}
          />
          <button
            className={cn("button", styles.save_button)}
            disabled={!isValid && errorVisible}
            onClick={createBrand}
            onFocus={() => setErrorVisible(true)}
          >
            Save
          </button>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default BrandCreationForm;
