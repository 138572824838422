import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";

import styles from "./NewStory.module.sass";
import Thumbnail from "./Thumbnail";
import Slide from "./Slide";
import Cta from "./Cta";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { reorderStories } from "../../utils/reorderStories";
import { uploadImagesToS3 } from "../../utils/uploadImagesToS3";
import { basicRole } from "../../assets/roles";

const NewStory = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [story, setStory] = useState({
    thumbnail: {
      title: "Title of the story",
      previewImage: null,
      previewBackgroundColor: "#000000",
      previewTextColor: "#FFFFFF",
    },
    slides: {
      a: [
        {
          id: `${1}-${Date.now()}`,
          title: "Header of the slide",
          text: "Text for slide",
          slideImage: null,
          slideBackgroundColor: "#000000",
          slideTitleColor: "#FFFFFF",
          slideTextColor: "#FFFFFF",
        },
      ],
    },
    cta: [
      {
        button: "Primary button",
        displayInStory: false,
        text: "ok",
        buttonColor: "#FFFFFF",
        textColor: "#000000",
        linkAction: "openMarketplace",
      },
      {
        button: "Secondary button",
        displayInStory: false,
        text: "ok",
        buttonColor: "#000000",
        textColor: "#FFFFFF",
        linkAction: "openMarketplace",
      },
    ],
  });
  const [editable, setEditable] = useState(null);

  const [isValid, setValid] = useState({
    thumbnail: false,
    slide0: false,
  });
  const [errorVisible, setErrorVisible] = useState(false);

  const [slGeneral, setSlGeneral] = useState(null);
  const [generalColorSettings, setGeneralColorSettings] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (basicRole) history.goBack();
  }, []);

  useEffect(() => {
    if (generalColorSettings) {
      setStory((prev) => ({
        ...prev,
        slides: {
          ...prev.slides,
          a: prev.slides.a.map((s, idx) => {
            return {
              ...s,
              slideBackgroundColor: generalColorSettings.slideBackgroundColor,
              slideTitleColor: generalColorSettings.slideTitleColor,
              slideTextColor: generalColorSettings.slideTextColor,
            };
          }),
        },
      }));
    }
  }, [generalColorSettings]);

  const addSlide = () => {
    setStory((prev) => ({
      ...prev,
      slides: {
        ...prev.slides,
        a: [
          ...prev.slides.a,
          {
            id: `${prev.slides.a.length + 1}-${Date.now()}`,
            title: "",
            text: "",
            slideImage: null,
            slideBackgroundColor:
              generalColorSettings?.slideBackgroundColor || "#000000",
            slideTitleColor: generalColorSettings?.slideTitleColor || "#FFFFFF",
            slideTextColor: generalColorSettings?.slideTextColor || "#FFFFFF",
          },
        ],
      },
    }));
  };

  const saveStory = async (isActive) => {
    const { thumbnail, cta } = story;

    try {
      displayLoader();

      if (thumbnail.uploadedFile) {
        const [uploadedFileUrl] = await uploadImagesToS3(
          [thumbnail.uploadedFile],
          `stories/previews`
        );
        thumbnail.previewImage = uploadedFileUrl;
      }

      const ctaButtons = cta
        .map((btn, idx) => ({
          ...btn,
          index: idx,
        }))
        .filter((btn) => btn.displayInStory)
        .map((btn) => ({
          isActive,
          index: btn.index,
          uuid: uuidv4(),
          text: btn.text,
          actionIos: {
            onButtonTapAction: btn.link || btn.linkAction,
          },
          actionAndroid: {
            onButtonTapAction: btn.link || btn.linkAction,
          },
          styles: {
            buttonTextColor: btn.textColor,
            buttonColor: btn.buttonColor,
          },
        }));

      const slides = await Promise.all(
        story.slides.a.map(async (slide, idx, arr) => {
          let uploadedFileUrl = null;
          if (slide.uploadedFile) {
            [uploadedFileUrl] = await uploadImagesToS3(
              [slide.uploadedFile],
              `stories/previews`
            );
          }
          return {
            isActive,
            title: slide.title,
            textContent: slide.text,
            backgroundImageUrl: uploadedFileUrl,
            styles: {
              backgroundColor: slide.slideBackgroundColor,
              titleColor: slide.slideTitleColor,
              textColor: slide.slideTextColor,
            },
            buttons: arr.length === idx + 1 && idx ? ctaButtons : null,
          };
        })
      );

      const payload = {
        isActive,
        title: thumbnail.title,
        backgroundPreviewImageUrl: thumbnail.previewImage,
        styles: {
          backgroundColor: thumbnail.previewBackgroundColor,
          titleColor: thumbnail.previewTextColor,
        },
        pages: slides,
      };

      Object.keys(payload).forEach((key) => {
        if (payload[key] === null || payload[key] === undefined)
          delete payload[key];
      });

      await axios.post("news/preview", payload);

      history.push(`/stories`);
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.wrap_content}>
        <div className={styles.head}>
          <div>
            <div className={cn("h3", styles.title)}>Create story</div>
          </div>
        </div>

        <div
          className={styles.content}
          // onWheel={(e) => {
          //   const content = document.getElementById("content");
          //   if (e.deltaY > 0) content.scrollLeft += 100;
          //   else content.scrollLeft -= 100;
          // }}
        >
          <Thumbnail
            data={story.thumbnail}
            setEditable={setEditable}
            onSave={(value) =>
              setStory((prev) => ({ ...prev, thumbnail: value }))
            }
            errorVisible={errorVisible}
            setValid={(val) =>
              setValid((prev) => ({
                ...prev,
                thumbnail: val,
              }))
            }
            disabled={editable && editable !== "thumbnail"}
          />

          <DragDropContext
            onDragEnd={({ destination, source }) => {
              // dropped outside the list
              if (!destination) return;

              const sortedSlides = reorderStories(
                story.slides,
                source,
                destination
              );

              setStory((prev) => ({ ...prev, slides: sortedSlides }));
            }}
          >
            <div>
              {Object.entries(story.slides).map(([k, slides]) => (
                <Droppable
                  droppableId={k}
                  type="CARD"
                  direction="horizontal"
                  isCombineEnabled={true}
                  key={k}
                >
                  {(dropProvided) => (
                    <div {...dropProvided.droppableProps}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                        ref={dropProvided.innerRef}
                      >
                        {slides.map((slide, idx) => (
                          <Draggable
                            key={slide.id}
                            draggableId={slide.id}
                            index={idx}
                            isDragDisabled={editable !== null}
                          >
                            {(dragProvided) => (
                              <div
                                {...dragProvided.dragHandleProps}
                                {...dragProvided.draggableProps}
                                ref={dragProvided.innerRef}
                              >
                                <Slide
                                  data={slide}
                                  ctaButtons={
                                    slides.length - 1 === idx ? story.cta : null
                                  }
                                  setEditable={setEditable}
                                  onSave={(value) =>
                                    setStory((prev) => ({
                                      ...prev,
                                      slides: {
                                        ...prev.slides,
                                        a: prev.slides.a.map((s) => {
                                          if (s.id === slide.id) return value;
                                          return s;
                                        }),
                                      },
                                    }))
                                  }
                                  onDelete={(slideId) => {
                                    setStory((prev) => ({
                                      ...prev,
                                      slides: {
                                        ...prev.slides,
                                        a: prev.slides.a.filter(
                                          (s) => s.id !== slideId
                                        ),
                                      },
                                    }));
                                    setValid((prev) => ({
                                      ...prev,
                                      [`slide${idx}`]: true,
                                    }));
                                    setEditable(null);
                                  }}
                                  isGeneral={slGeneral === idx}
                                  setAsGeneral={setSlGeneral}
                                  setGeneralColors={(value) => {
                                    setGeneralColorSettings(value);
                                  }}
                                  errorVisible={errorVisible}
                                  setValid={(val) =>
                                    setValid((prev) => ({
                                      ...prev,
                                      [`slide${idx}`]: val,
                                    }))
                                  }
                                  disabled={
                                    editable && editable !== `slide${idx}`
                                  }
                                  index={idx}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {dropProvided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>

          <div className={cn(styles.slide_add, editable && styles.blurred)}>
            <button
              className={cn(styles.btn_add)}
              onClick={addSlide}
              disabled={editable}
            >
              <Icon name="add" size="24" />
            </button>
          </div>

          <Cta
            data={story.cta}
            setEditable={setEditable}
            onSave={(value) => setStory((prev) => ({ ...prev, cta: value }))}
            errorVisible={errorVisible}
            setValid={(val) =>
              setValid((prev) => ({
                ...prev,
                сta: val,
              }))
            }
            disabled={editable && editable !== "cta"}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.date}></div>
        <div className={styles.story_controls}>
          <button
            className={cn("button-stroke", styles.btn_save_draft)}
            onClick={() => saveStory(false)}
            disabled={editable}
          >
            Save Draft
          </button>
          <button
            className={cn("button-stroke", styles.btn_publish)}
            onClick={() => saveStory(true)}
            disabled={
              errorVisible &&
              (!Object.values(isValid).every((el) => el) || editable)
            }
            onFocus={() => setErrorVisible(true)}
          >
            Publish now
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewStory;
