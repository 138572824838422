import React, { useRef } from "react";
import cn from "classnames";

import styles from "./FileUploader.module.sass";

const FileUploader = ({ onUpload, children, fill, disabled }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const uploadedFile = event.target.files[0];
    onUpload(uploadedFile);
  };

  return (
    <div
      className={cn(styles.file_uploader, {
        [styles.fill]: fill,
      })}
    >
      <button onClick={handleClick} disabled={disabled}>
        {children ? children : <span className={styles.btn_def}>Upload</span>}
      </button>

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileUploader;
