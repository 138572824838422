export const storyColors = [
  "#84BDF4",
  "#AFF8EA",
  "#B7F57D",
  "#FBF284",
  "#E59F94",
  "#E49EC6",
  "#5F9DF1",
  "#8BE0D0",
  "#95D465",
  "#F4DC6E",
  "#DD755E",
  "#DA5E9D",
  "#4372B0",
  "#65A790",
  "#6CAC46",
  "#E8B454",
  "#C94932",
  "#B33E73",
  "#2A4B79",
  "#3E6964",
  "#416E2A",
  "#D47F40",
  "#993622",
  "#7F2951",
  "#FFFFFF",
  "#C0C0C0",
  "#5E5E5E",
  "#303030",
  "#1F1F1F",
  "#000000",
];
