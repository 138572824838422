import React from "react";

import styles from "./Table.module.sass";
import Row from "./Row";

const Table = ({ data, indicators, hide }) => {
  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div className={styles.col}>Digital model</div>
        <div className={styles.col}>
          <div className={styles.indicators}>
            {indicators.map((x, index) => (
              <div className={styles.indicator} key={index}>
                <div
                  className={styles.color}
                  style={{ backgroundColor: x.color }}
                ></div>
                {x.title}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}></div>
        <div className={styles.col}></div>
      </div>
      {!hide && data.map((x, index) => <Row data={x} key={index} />)}
    </div>
  );
};

export default Table;
