import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./TransferCard.module.sass";
import Card from "../Card";
import Icon from "../Icon";
import Loader from "../Loader";
import axios from "../../utils/axios";
import eventBus from "../../utils/eventBus";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";
import { basicRole } from "../../assets/roles";

const TransferCard = ({ digitalModel }) => {
  const [games, setGames] = useState([]);
  const [clipboard, setClipboard] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    const subscription = eventBus.on(
      "update_skin_event",
      ({ gameId, skinUrl }) => {
        setGames((prev) =>
          prev.map((g) => {
            if (g.gameId === gameId)
              return {
                ...g,
                skinUrl,
              };
            return g;
          })
        );
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const fetchGames = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("game-digital-model", {
        params: {
          limit: 50,
          digitalModelId: digitalModel.id,
        },
      });

      const transferGames = data.games.map((game) => {
        const record = data.records.find((el) => el.gameId === game.id);

        return record
          ? {
              ...record,
              game,
            }
          : {
              digitalModelId: digitalModel.id,
              gameId: game.id,
              game,
              transferable: "unable",
              skinUrl: null,
            };
      });

      setGames(transferGames);
      setClipboard(transferGames);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setGames(clipboard);
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = ({ target }) => {
    const transferGames = games.map((g) => {
      if (g.gameId === target.name) {
        return {
          ...g,
          transferable: target.value,
        };
      }

      return g;
    });

    setGames(transferGames);
  };

  const save = async () => {
    try {
      dispatch(displayLoader());

      const body = {
        gameDigitalModels: games.map((g) => ({
          gameId: g.gameId,
          transferable: g.transferable,
        })),
      };

      await axios.post(`game-digital-model/${digitalModel.id}`, body);

      setClipboard(games);
      setEditMode(false);

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("saved successfully"));
    } catch (err) {
      dispatch(hideLoader());
      displayNotificationPopup("error");
    }
  };

  return (
    <Card className={styles.card} title={"Transfer"} classTitle="title-green">
      {!isEditMode && (
        <button
          className={cn("button-stroke", styles.redirect_button)}
          onClick={() =>
            history.push("/game-skins", {
              digitalModel,
              gameXDigitalModel: games,
            })
          }
        >
          <Icon name="upload" size="24" />
          <span>Upload images</span>
        </button>
      )}
      {!basicRole && (
        <button
          className={cn("button-stroke", styles.switch_button, [
            !isEditMode ? styles.info : styles.edit,
          ])}
          onClick={changeMode}
        >
          <Icon name={!isEditMode ? "edit" : "close"} size="24" />
          <span>{!isEditMode ? "Edit" : "Cancel"}</span>
        </button>
      )}
      <div className={cn(styles.table)}>
        <div className={cn(styles.row)}>
          <div className={styles.col}>Game</div>
          <div className={styles.col}>Transferable</div>
          <div className={styles.col}>Coming soon</div>
          <div className={styles.col}>Not transferable</div>
        </div>
        {games.map((item, index) => (
          <div className={styles.row_content} key={index}>
            <div className={styles.col_content}>
              <img
                src={item.game.logo || "/images/game_image_stub.svg"}
                alt="game"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey)
                    window.open(`/games/${item.game.id}`);
                  else history.push(`/games/${item.game.id}`);
                }}
                onAuxClick={wheelClickEventHandler(`/games/${item.game.id}`)}
                onError={(e) => (e.target.src = "/images/game_image_stub.svg")}
              />
              <span>{item.game.name}</span>
            </div>
            <div className={styles.col_content}>
              <input
                type="radio"
                name={item.gameId}
                value="able"
                checked={item.transferable === "able"}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
            <div className={styles.col_content}>
              <input
                type="radio"
                name={item.gameId}
                value="soon"
                checked={item.transferable === "soon"}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
            <div className={styles.col_content}>
              <input
                type="radio"
                name={item.gameId}
                value="unable"
                checked={item.transferable === "unable"}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
          </div>
        ))}
      </div>
      {isLoading && (
        <div className={styles.blc_loader}>
          <Loader className={styles.loader} />
          <span>Loading</span>
        </div>
      )}
      {isEditMode && (
        <button className={cn("button", styles.save_button)} onClick={save}>
          Save
        </button>
      )}
    </Card>
  );
};

export default TransferCard;
