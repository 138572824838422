import React, { useState, useEffect } from "react";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./Cta.module.sass";
import TextInput from "../../../components-dev/TextInput";
import Dropdown from "../../../components-dev/Dropdown";
import Icon from "../../../components-dev/Icon";
import Switch from "../../../components-dev/Switch";
import StoryColorPicker from "../../../components-dev/StoryColorPicker";
import { basicRole } from "../../../assets/roles";

const linkActions = {
  close: "closeNews",
  "open wardrobe": "openWardrobe",
  "open marketplace": "openMarketplace",
  "open webpage": "any not seamm-domain related URL",
};

const Cta = ({
  data,
  setEditable,
  onSave,
  errorVisible,
  setValid,
  disabled,
}) => {
  const [cta, setCta] = useState(data);
  const [clipboard, setClipboard] = useState(data);
  const [isEditMode, setEditMode] = useState(false);
  const [selected, setSelected] = useState(null);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    setEditable(isEditMode ? "cta" : null);
  }, [isEditMode]);

  useEffect(() => {
    validate();
  }, [cta]);

  const validate = () => {
    const { error } = Joi.array()
      .items(
        Joi.object({
          button: Joi.string().required(),
          displayInStory: Joi.bool().required(),
          text: Joi.string()
            .max(28)
            .when("displayInStory", {
              is: true,
              then: Joi.required(),
              otherwise: Joi.allow("").optional(),
            }),
          buttonColor: Joi.string().required(),
          textColor: Joi.string().required(),
          linkAction: Joi.string().required(),
          link: Joi.when("displayInStory", {
            is: true,
            then: Joi.when("linkAction", {
              is: linkActions["open webpage"],
              then: Joi.string().uri().required(),
              otherwise: Joi.optional(),
            }),
          }),
        })
      )
      .validate(cta, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const switchButton = (index) => {
    const settings = cta.map((x, idx) =>
      index === idx ? { ...x, displayInStory: !x.displayInStory } : x
    );
    setCta(settings);
    setClipboard(settings);
    onSave(settings);
  };

  const save = () => {
    onSave(cta);
    setClipboard(cta);
    setEditMode(false);
    setSelected(null);
  };

  const cancel = () => {
    setCta(clipboard);
    setEditMode(false);
    setColorPickerVisible(false);
    setSelected(null);
  };

  return (
    <div className={cn(styles.content, disabled && styles.blurred)}>
      <div className={cn(styles.card, styles.cta)}>
        <div className={styles.card_head}>
          <div className={cn("title-yellow", styles.title)}>CTA buttons</div>
          {!basicRole && (
            <button
              className={cn(styles.btn_edit)}
              onClick={() =>
                setEditMode((prev) => (prev === true ? cancel() : true))
              }
              disabled={disabled || cta?.every((el) => !el.displayInStory)}
            >
              <Icon name="edit" size="20" />
            </button>
          )}
        </div>
        <div className={styles.cta_text}>
          Call-to-action buttons are permissible solely on the concluding slides
          of the narrative.
        </div>
        {cta.map((el, index) => (
          <div className={styles.cta_switch} key={index}>
            <span>{el.button} activity</span>
            <Switch
              className={styles.switch}
              value={el.displayInStory}
              onChange={() => switchButton(index)}
              disabled={disabled || isEditMode}
            />
          </div>
        ))}
      </div>

      {isEditMode && (
        <div className={cn(styles.card, styles.cta_edit)}>
          <div className={styles.card_head}>
            <div className={cn("title-yellow", styles.title)}>CTA edit</div>
          </div>
          {cta.map((el, index) => (
            <div className={styles.cta_edit_section} key={index}>
              <div className={styles.color_picker_control}>
                <span>{el.button} settings</span>
                <button
                  className={cn(styles.btn_prototype)}
                  onClick={() =>
                    setSelected((prev) => (prev === index ? null : index))
                  }
                  style={{
                    transform: selected === index && "rotate(180deg)",
                  }}
                >
                  <Icon name="arrow-down" size="24" />
                </button>
              </div>

              {selected === index && (
                <div className={styles.cta_settings}>
                  <TextInput
                    classInput={cn(styles.text_input, {
                      [styles.text_input_error]:
                        errorVisible &&
                        validationError.includes(`[${index}].text`),
                    })}
                    classLabel={styles.text_input_label}
                    value={el.text}
                    placeholder="Button text"
                    label={`${el.button} - text`}
                    maxLength="28"
                    onChange={({ target }) =>
                      setCta((prev) =>
                        prev.map((x, idx) =>
                          idx === index ? { ...x, text: target.value } : x
                        )
                      )
                    }
                    error={
                      errorVisible &&
                      validationError.includes(`[${index}].text`)
                    }
                  />
                  <div className={styles.color_picker_control}>
                    <StoryColorPicker
                      title="Button color"
                      currentColor={el.buttonColor}
                      onSaveColor={(selectedColor) => {
                        setCta((prev) =>
                          prev.map((x, idx) =>
                            idx === index
                              ? { ...x, buttonColor: selectedColor }
                              : x
                          )
                        );
                      }}
                      setColorPickerVisible={setColorPickerVisible}
                    />
                  </div>
                  <div className={styles.color_picker_control}>
                    <StoryColorPicker
                      title="Text color"
                      currentColor={el.textColor}
                      onSaveColor={(selectedColor) => {
                        setCta((prev) =>
                          prev.map((x, idx) =>
                            idx === index
                              ? { ...x, textColor: selectedColor }
                              : x
                          )
                        );
                      }}
                      setColorPickerVisible={setColorPickerVisible}
                    />
                  </div>
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownLabel={styles.dropdown_label}
                    value={
                      el.link
                        ? "open webpage"
                        : Object.keys(linkActions).find(
                            (key) => linkActions[key] === el.linkAction
                          )
                    }
                    setValue={(selectedValue) => {
                      setCta((prev) =>
                        prev.map((x, idx) =>
                          idx === index
                            ? {
                                ...x,
                                linkAction: linkActions[selectedValue],
                                link:
                                  selectedValue === linkActions["open webpage"]
                                    ? x.link
                                    : "",
                              }
                            : x
                        )
                      );
                    }}
                    options={Object.keys(linkActions)}
                    label={`${el.button} - link`}
                  />
                  {el.linkAction !== linkActions["close"] &&
                    el.linkAction !== linkActions["open wardrobe"] &&
                    el.linkAction !== linkActions["open marketplace"] && (
                      <TextInput
                        classInput={cn(styles.text_input, {
                          [styles.text_input_error]:
                            errorVisible &&
                            validationError.includes(`[${index}].link`),
                        })}
                        classLabel={styles.text_input_label}
                        value={el.link}
                        placeholder="Source link"
                        label="Link"
                        onChange={({ target }) =>
                          setCta((prev) =>
                            prev.map((x, idx) =>
                              idx === index ? { ...x, link: target.value } : x
                            )
                          )
                        }
                        error={
                          errorVisible &&
                          validationError.includes(`[${index}].link`)
                        }
                      />
                    )}
                </div>
              )}
            </div>
          ))}

          <div className={styles.cta_edit_section}>
            <button
              className={cn("button", styles.btn_save_cta)}
              disabled={colorPickerVisible}
              onClick={save}
            >
              Done
            </button>
            <button
              className={cn("button-stroke", styles.btn_cancel_cta)}
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cta;
