import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./GameSkins.module.sass";
import Icon from "../../components-dev/Icon";
import GameSection from "./GameSection";
import axios from "../../utils/axios";
import eventBus from "../../utils/eventBus";
import { generateImgUrl } from "../../utils/generateImgUrl";

const GameSkins = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [games, setGames] = useState([]);
  const [digitalModel, setDigitalModel] = useState({});

  const history = useHistory();

  useEffect(() => {
    const { digitalModel, gameXDigitalModel } = history.location.state;

    if (!digitalModel) history.goBack();
    setDigitalModel(digitalModel);

    const games = gameXDigitalModel
      .filter((gm) => gm.game?.type === "full skin")
      .map((gm) => ({
        id: gm.gameId,
        name: gm.game.name,
        logo: gm.game.logo,
        skinUrl: gm.skinUrl,
      }));
    setGames(games);
  }, []);

  const uploadFileToS3 = async (file, path) => {
    try {
      displayLoader();

      const bodyFormData = new FormData();
      bodyFormData.append("image", file);

      const { data: response } = await axios.post(
        `files/images`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            path: path || "common",
          },
        }
      );

      return response.data;
    } catch (err) {
      displayNotificationPopup("error: upload file");
    } finally {
      hideLoader();
    }
  };

  const updateSkin = async (data, uploadedFile) => {
    try {
      displayLoader();

      await axios.patch("game-digital-model/skin", data);

      const skinUrl = uploadedFile ? await generateImgUrl(uploadedFile) : null;

      eventBus.emit("update_skin_event", { gameId: data.gameId, skinUrl });

      setGames((prev) =>
        prev.map((g) => {
          if (g.id === data.gameId)
            return {
              ...g,
              skinUrl,
            };
          return g;
        })
      );

      displayNotificationPopup("success");
    } catch (err) {
      displayNotificationPopup("error: upload skin");
    } finally {
      hideLoader();
    }
  };

  const uploadFile = (operationType, gameId) => async (uploadedFile) => {
    if (!uploadedFile?.type.includes("image/png")) {
      displayNotificationPopup("error: invalid file");
      return;
    }

    let path = "common";
    switch (operationType) {
      case "update_skin":
        path = `games/${gameId}/skins`;
        break;
      default:
        break;
    }

    const [imgUrl] = await uploadFileToS3(uploadedFile, path);

    if (!imgUrl) return;

    if (operationType === "update_skin") {
      await updateSkin(
        {
          gameId,
          digitalModelId: digitalModel.id,
          skinUrl: imgUrl,
        },
        uploadedFile
      );
    }
  };

  const deleteFile = (operationType, gameId) => async () => {
    if (operationType === "update_skin") {
      await updateSkin(
        {
          gameId,
          digitalModelId: digitalModel.id,
          skinUrl: null,
        },
        null
      );
    }
  };

  return (
    <>
      <div className={cn(styles.navigation)}>
        <Link className={styles.link} to={"/digital-models"}>
          <span>Digital models</span>
        </Link>
        <span className={styles.forwardslash}>{"/"}</span>
        <Link className={styles.link} to={`/digital-models/${digitalModel.id}`}>
          <span>{digitalModel.name}</span>
        </Link>
        <span className={styles.forwardslash}>{"/"}</span>
        <span>Additional files upload</span>
        <div className={styles.blc_redirect}>
          <button
            className={cn("button-stroke", styles.redirect_button)}
            onClick={() =>
              history.push(`/digital-models/${digitalModel.id}`, {
                anchorLink: "#transfer-card",
              })
            }
          >
            <Icon name="arrow-left" size="24" />
            <span>Back</span>
          </button>
        </div>
      </div>

      <div className={styles.blc_title}>
        <div className={styles.title}>Skins and game previews</div>
        <div
          className={styles.subtitle}
        >{`${digitalModel.brand?.name} ${digitalModel.name}`}</div>
      </div>

      {games.length ? (
        games.map((game, index) => (
          <GameSection
            game={game}
            onUploadFile={uploadFile}
            onDeleteFile={deleteFile}
            key={index}
          />
        ))
      ) : (
        <div className={styles.info}>No games with skins</div>
      )}
    </>
  );
};

export default GameSkins;
