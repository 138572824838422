import React from "react";
import cn from "classnames";

import styles from "./ProductStoreIdentifierDetails.module.sass";
import TextInput from "../TextInput";

const ProductStoreIdentifierDetails = ({
  details,
  onChange,
  validationError,
  disabled,
}) => {
  return (
    <div className={styles.blc_dtls}>
      <div className={styles.dtl_label}>App Store Identifier Details</div>
      {details.map((detail, idx) => (
        <div
          className={cn(styles.dtl, {
            [styles.dtl_last]: details.length - 1 === idx,
          })}
          key={idx}
        >
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(
                  `appStoreIdentifierDetails[${idx}].discount`
                ),
            })}
            value={detail.discount}
            placeholder="discount"
            onChange={({ target }) => {
              details[idx] = {
                discount: target.value,
                value: detail.value,
              };
              onChange(details);
            }}
            error={
              validationError &&
              validationError.includes(
                `appStoreIdentifierDetails[${idx}].discount`
              )
            }
            disabled={disabled}
          />
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError &&
                validationError.includes(
                  `appStoreIdentifierDetails[${idx}].value`
                ),
            })}
            value={detail.value}
            placeholder="value"
            onChange={({ target }) => {
              details[idx] = {
                discount: detail.discount,
                value: target.value,
              };
              onChange(details);
            }}
            error={
              validationError &&
              validationError.includes(
                `appStoreIdentifierDetails[${idx}].value`
              )
            }
            disabled={disabled}
          />
        </div>
      ))}
      {!disabled && (
        <div className={styles.blc_dtl_controls}>
          <button
            className={styles.btn_remove_dtl}
            onClick={() => onChange(details.slice(0, -1))}
            disabled={details.length < 1}
          >
            <img src="/images/icons/remove/light.svg" alt="remove" />
          </button>
          <button
            className={styles.btn_add_dtl}
            onClick={() => onChange([...details, { discount: "", value: "" }])}
          >
            <img src="/images/icons/add/light.svg" alt="add" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductStoreIdentifierDetails;
