import React, { useState, useEffect } from "react";
import cn from "classnames";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";

import styles from "./UserStats.module.sass";
import Scorecard from "./Scorecard";
import UserStatsTooltip from "./UserStatsTooltip";
import Loader from "../Loader";
import RetryLoading from "../RetryLoading";
import Plug from "../Plug";
import Card from "../../../components-dev/Card";
import Tooltip from "../../../components-dev/Tooltip";
import axios from "../../../utils/axios";

const scorecards = [
  {
    title: "All users",
    color: "#CABDFF",
    tooltip: "Users launched the app in the selected period",
    url: "stats/all-users",
  },
  {
    title: "New users",
    color: "#B5E4CA",
    tooltip:
      "Users who launched the app for the first time in the selected period",
    url: "stats/new-users",
  },
  {
    title: "Active users",
    color: "#B1E5FC",
    tooltip: "Users interacted with digital items in the selected period",
    url: "stats/active-users",
  },
];

const UserStats = ({ dateInterval }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [dateInterval]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("stats/users", {
        params: { ...dateInterval },
      });

      const userStats = data.length > 1 ? dataMapper(data) : [];

      setData(userStats);
      setLoading(false);
      setErrorLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorLoading(true);
    }
  };

  const dataMapper = (data) => {
    const dateFormat =
      new Date(data[0].eventDate).getMonth() ===
      new Date(data[data.length - 1].eventDate).getMonth()
        ? "d"
        : "d MMM";

    return data.map((stats) => {
      return {
        ...stats,
        eventDateTick: format(new Date(stats.eventDate), dateFormat),
      };
    });
  };

  return (
    <Card
      className={cn(styles.card)}
      title="Overview"
      classTitle={cn("title-green")}
      head={
        <Tooltip
          className={styles.tooltip}
          title="Overview of the number of users for the selected period"
          icon="info-stroke"
          place="left"
        />
      }
    >
      <div className={styles.scorecard}>
        {scorecards.map((scorecard, index) => (
          <Scorecard
            scorecard={scorecard}
            dateInterval={dateInterval}
            key={index}
          />
        ))}
      </div>

      {isLoading && (
        <div className={styles.chart_loader}>
          <Loader />
        </div>
      )}

      {!isLoading && (
        <div className={styles.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid
                strokeDasharray="none"
                stroke="#EFEFEF"
                vertical={false}
              />
              <XAxis
                dataKey="eventDateTick"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                padding={{ left: 30 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
              />
              <ChartTooltip
                content={<UserStatsTooltip scorecards={scorecards} />}
                wrapperStyle={{ zIndex: 1 }}
              />
              <Line
                type="monotone"
                dataKey="usersCount"
                dot={false}
                strokeWidth={4}
                stroke="#CABDFF"
              />
              <Line
                type="monotone"
                dataKey="newUsersCount"
                dot={false}
                strokeWidth={4}
                stroke="#B5E4CA"
              />
              <Line
                type="monotone"
                dataKey="activeUsersCount"
                dot={false}
                strokeWidth={4}
                stroke="#B1E5FC"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      {!isLoading && !data.length && <Plug className={styles.plug} />}

      <div className={styles.chart_info}>
        {scorecards.map((scorecard, index) => (
          <div className={styles.user_type} key={index}>
            <div
              className={styles.color}
              style={{ backgroundColor: scorecard.color }}
            ></div>
            {scorecard.title}
          </div>
        ))}
      </div>

      {!isLoading && isErrorLoading && (
        <RetryLoading className={styles.retry_loading} retry={fetchData} />
      )}
    </Card>
  );
};

export default UserStats;
