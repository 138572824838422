import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TransferRequest from "./TransferRequest";

import {
  display as displayLoader,
  hide as hideLoader,
} from "../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { displayLoader, hideLoader, displayNotificationPopup },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferRequest);
