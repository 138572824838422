import React, { useState, useEffect } from "react";
import cn from "classnames";

import styles from "./Scorecard.module.sass";
import Tooltip from "../../../../components-dev/Tooltip";
import Loader from "../../../../components-dev/Loader";
import axios from "../../../../utils/axios";

const Scorecard = ({ scorecard, dateInterval }) => {
  const [usersCount, setUsersCount] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [dateInterval]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(scorecard.url, {
        params: { ...dateInterval },
      });

      setUsersCount(
        data.usersCount || data.newUsersCount || data.activeUsersCount || 0
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div
      className={cn(styles.info)}
      style={{ backgroundColor: scorecard.color + 35 }}
    >
      <div className={styles.blc_title}>
        <div
          className={styles.icon}
          style={{ backgroundColor: scorecard.color }}
        ></div>
        <div className={styles.title}>{scorecard.title}</div>
        <Tooltip
          className={styles.tooltip}
          title={scorecard.tooltip}
          icon="info"
          place="top"
        />
      </div>
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : usersCount !== "" ? (
        <div className={styles.amount}>{usersCount}</div>
      ) : (
        <div className={styles.plug}>
          Data is not yet available, please check back later.
        </div>
      )}
    </div>
  );
};

export default Scorecard;
