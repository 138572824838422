import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./InfoNavigation.module.sass";

const InfoNavigation = ({ className, link, parts }) => {
  return (
    <div className={cn(styles.navigation, className)}>
      <Link className={styles.link} to={link}>
        <span>{parts[0]}</span>
      </Link>
      <span className={styles.forwardslash}>{"/"}</span>
      <span>{parts[1]}</span>
    </div>
  );
};

export default InfoNavigation;
