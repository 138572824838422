import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";

import styles from "./ConfirmPopup.module.sass";
import Icon from "../Icon";

const ConfirmPopup = ({ visible, setVisible, infoMessage, confirm }) => {
  const hide = () => setVisible({ visible: false });

  return (
    <div className={cn(styles.popup_wrapper, { [styles.visible]: visible })}>
      <OutsideClickHandler onOutsideClick={hide}>
        <div className={cn(styles.popup)}>
          <div className={styles.info}>
            <span>{infoMessage}</span>
            <span>Are you sure you want to continue?</span>
            {/* <button onClick={hide}>
              <Icon name="close" size="20" />
            </button> */}
          </div>
          <div className={styles.controls}>
            <button
              className={cn("button", styles.btn_save)}
              onClick={() => {
                confirm();
                hide();
              }}
            >
              Yes
            </button>
            <button
              className={cn("button-stroke", styles.btn_cancel)}
              onClick={hide}
            >
              No
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default ConfirmPopup;
