import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";
import QRCode from "react-qr-code";
import { format } from "date-fns";

import styles from "./DigitalItem.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import Dropdown from "../../components-dev/Dropdown";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import Icon from "../../components-dev/Icon";
import axios from "../../utils/axios";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { toPrettyJson } from "../../utils/gJson";
import { basicRole } from "../../assets/roles";

const DigitalItem = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const [digitalItem, setDigitalItem] = useState({});
  const [clipboard, setClipboard] = useState({});
  const [digitalModels, setDigitalModels] = useState([]);
  const [physiсalItems, setPhysiсalItems] = useState([]);

  const [isEditMode, setEditMode] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const { id: digitalItemId } = useParams();

  useEffect(() => {
    scrollToTop();
    fetchData();
  }, []);

  useEffect(() => {
    validate();
  }, [digitalItem]);

  const fetchData = async () => {
    displayLoader();
    await fetchDigitalItem();
    await fetchDigitalModels();
    await fetchPhysiсalItems();
    hideLoader();
  };

  const fetchDigitalItem = async () => {
    try {
      const {
        data: { records },
      } = await axios.get("digitalitem", {
        params: {
          digitalItemId,
        },
      });

      const fetchedDigitalItem = {
        ...records[0],
        price: records[0].price.replace("$", ""),
        customizationSettings: toPrettyJson(records[0].customizationSettings),
      };
      setDigitalItem(fetchedDigitalItem);
      setClipboard(fetchedDigitalItem);
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const fetchDigitalModels = async () => {
    try {
      const { data } = await axios.get("digitalmdl");
      setDigitalModels(data.records);
    } catch {}
  };

  const fetchPhysiсalItems = async () => {
    try {
      const { data } = await axios.get("physicalitem");
      setPhysiсalItems(
        data.records.map((item) => ({ ...item, name: item.id }))
      );
    } catch {}
  };

  const changeMode = () => {
    if (isEditMode) {
      setDigitalItem((prev) => ({
        ...clipboard,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setDigitalItem((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const dItem = JSON.parse(JSON.stringify(digitalItem));
    const joiSchema = {
      digitalModelId: Joi.string().uuid().required(),
      isActive: Joi.bool().required(),
      price: Joi.string().regex(/^\d+([.]\d{1,2})?$/),
      userId: Joi.string().uuid(),
      physicalItemId: Joi.string().uuid(),
    };
    const props = Object.keys(joiSchema);

    Object.keys(dItem).forEach(
      (key) =>
        (!props.includes(key) || dItem[key] === null || dItem[key] === "") &&
        delete dItem[key]
    );

    const { error } = Joi.object(joiSchema).validate(dItem, {
      abortEarly: false,
    });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const updateDigitalItem = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        userId: digitalItem.userId || null,
        estimatedPriceCents: digitalItem.price * 100,
        digitalModelId: digitalItem.digitalModelId,
        physicalItemId: digitalItem.physicalItemId || null,
        isActive: digitalItem.isActive,
        comment: digitalItem.comment || null,
      };

      await axios.put(`digitalitem/${digitalItemId}`, body);

      setClipboard((prev) => ({
        id: prev.id,
        ...body,
      }));
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    !isEmptyObject(digitalItem) && (
      <>
        <InfoNavigation
          link={"/digital-items"}
          parts={["Digital items", digitalItem.id]}
        />
        <div className={styles.head}>
          <div>
            <div className={cn("h3", styles.title)}>
              {digitalItem.brand?.name}
            </div>
            <div className={cn("h3", styles.subtitle)}>
              {digitalItem.digitalModel?.titleFull}
            </div>
          </div>
          {!basicRole && (
            <button
              className={cn("button-stroke", styles.switch_button, [
                !isEditMode ? styles.info : styles.edit,
              ])}
              onClick={changeMode}
            >
              <Icon name={!isEditMode ? "edit" : "close"} size="24" />
              <span>{!isEditMode ? "Edit" : "Cancel"}</span>
            </button>
          )}
        </div>

        <Card>
          <div className={cn(styles.digital_model)}>
            <div className={styles.preview}>
              <div className={styles.qr_code}>
                <QRCode
                  value={digitalItem.deeplink}
                  fgColor="#1b1d1f"
                  bgColor="#F4F4F4"
                  size="390"
                />
              </div>
            </div>
            <div className={styles.blc_right}>
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={digitalItem.id}
                placeholder="ID ..."
                label={"ID *"}
                copy
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={digitalItem.deeplink}
                placeholder="Link ..."
                label={"Link *"}
                copy
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <TextInput
                className={cn({
                  ["unchangeable"]: !digitalItem.userId && isEditMode,
                })}
                classInput={styles.text_input}
                value={(digitalItem.userId && digitalItem.user?.nickname) || ""}
                placeholder="Owner ..."
                label={"Owner"}
                clear={digitalItem.userId && isEditMode}
                onClear={() => handleChange("userId", "")}
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={digitalItem.activationMedium}
                placeholder="Activation source ..."
                label={"Activation source"}
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={digitalItem.cid}
                placeholder="CID ..."
                label={"CID *"}
                copy
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input, styles.text_overflow)}
                value={digitalItem.uniqueHash}
                placeholder="Hash ..."
                label={"Hash *"}
                copy
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_success]: isEditMode,
                  [styles.text_input_error]:
                    isEditMode && validationError.includes("price"),
                })}
                value={digitalItem.price || ""}
                placeholder="Price ..."
                label={"Price"}
                onChange={({ target }) => handleChange("price", target.value)}
                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                error={isEditMode && validationError.includes("price")}
                disabled={!isEditMode}
              />
              <Dropdown
                className={styles.dropdown}
                value={
                  digitalModels.find((d) => d.id === digitalItem.digitalModelId)
                    ?.name
                }
                placeholder="Digital model ..."
                setValue={(selectedValue) => {
                  handleChange("digitalModelId", selectedValue.id);
                }}
                options={digitalModels}
                label={"Digital model *"}
                link={
                  !isEditMode && `/digital-models/${digitalItem.digitalModelId}`
                }
                disabled={!isEditMode}
              />
              <Dropdown
                className={styles.dropdown}
                value={digitalItem.physicalItemId}
                placeholder="Physical item ..."
                setValue={(selectedValue) =>
                  handleChange("physicalItemId", selectedValue.id)
                }
                options={physiсalItems}
                label={"Physical item"}
                link={
                  !isEditMode &&
                  digitalItem.physicalItemId &&
                  `/physical-items/${digitalItem.physicalItemId}`
                }
                clear={isEditMode && digitalItem.physicalItemId}
                disabled={!isEditMode}
              />
              <div className={styles.blc_switch}>
                <span>Active</span>
                <Switch
                  className={styles.switch}
                  value={digitalItem.isActive}
                  onChange={() =>
                    setDigitalItem((prev) => ({
                      ...prev,
                      isActive: !prev.isActive,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              <AutoHeightTextarea
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.textarea)}
                label={"Customization settings (JSON)"}
                value={digitalItem.customizationSettings || ""}
                placeholder="Customization settings ..."
                disabled={true}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={styles.text_input}
                value={
                  (digitalItem.creatorId && digitalItem.creator?.email) ||
                  "automatic"
                }
                placeholder="Creator ..."
                label={"Creator"}
                disabled={true}
              />
              <Dropdown
                classUnchangeable={cn({ ["unchangeable"]: isEditMode })}
                value={format(new Date(digitalItem.createdAt), "hh:mm aaa")}
                valueLabel={format(
                  new Date(digitalItem.createdAt),

                  "dd MMMM yyyy"
                )}
                label={"Created"}
                disabled={true}
              />
              <AutoHeightTextarea
                classInput={cn(styles.textarea)}
                label={"Comment"}
                value={digitalItem.comment}
                placeholder="Comment ..."
                onChange={({ target }) => handleChange("comment", target.value)}
                disabled={!isEditMode}
              />
              {isEditMode && (
                <button
                  className={cn("button", styles.save_button)}
                  disabled={!isValid}
                  onClick={updateDigitalItem}
                >
                  <span>Save</span>
                </button>
              )}
            </div>
          </div>
        </Card>
      </>
    )
  );
};

export default DigitalItem;
