import React from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import cn from "classnames";

import styles from "./Row.module.sass";
import RedirectTableCell from "../../../../components-dev/RedirectTableCell";
import Icon from "../../../../components-dev/Icon";
import { display as displayNotificationPopup } from "../../../../store/reducers/notificationPopup";

const Row = ({ data }) => {
  const dispatch = useDispatch();

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(displayNotificationPopup("copied to clipboard"));
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <RedirectTableCell
            text={data.nickname}
            to={`/users/${data.id}`}
            maxWidth={styles.redirect}
          />
        </div>
        <div className={styles.col} title={data.id}>
          <div className={styles.align_center}>
            <div className={styles.short}>{data.id}</div>
            <button
              className={styles.copy}
              onClick={() => copyTextToClipboard(data.id)}
            >
              <Icon name="copy" size="18" />
            </button>
          </div>
        </div>
        <div className={styles.col} title={data.email}>
          <div className={styles.align_center}>
            <div className={cn(styles.short, styles.email)}>{data.email}</div>
            <button
              className={styles.copy}
              onClick={() => copyTextToClipboard(data.email)}
            >
              <Icon name="copy" size="18" />
            </button>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.date}>
            <span>{format(new Date(data.registrationDate), "yyyy-MM-dd")}</span>
            <span>{format(new Date(data.registrationDate), "hh:mm")}</span>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.date}>
            <span>
              {data.lastActivity
                ? format(new Date(data.lastActivity), "yyyy-MM-dd")
                : ""}
            </span>
            <span>
              {data.lastActivity
                ? format(new Date(data.lastActivity), "hh:mm")
                : ""}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
