import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import styles from "./DigitalProducts.module.sass";
import Card from "../../components-dev/Card";
import DigitalProduct from "../../components-dev/DigitalProduct";
import Loader from "../../components-dev/Loader";
import axios from "../../utils/axios";
import { wheelClickEventHandler } from "../../utils/wheelClickEventHandler";

const DigitalProducts = ({ displayNotificationPopup }) => {
  const [digitalProducts, setDigitalProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);
  const history = useHistory();

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    page && fetchDigitalProducts();
  }, [page]);

  const fetchDigitalProducts = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("marketplace/products", {
        params: { limit: 24, page },
      });
      if (data.products.length) {
        setDigitalProducts((prev) => [...prev, ...data.products]);
      } else {
        observer.current.disconnect();
      }

      setLoading(false);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.list}>
          {digitalProducts.map((x, index) => (
            <DigitalProduct
              className={styles.digital_product}
              item={x}
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey)
                  window.open(`/digital-products/${x.id}`);
                else history.push(`/digital-products/${x.id}`);
              }}
              onAuxClick={wheelClickEventHandler(`/digital-products/${x.id}`)}
              key={index}
            />
          ))}
        </div>
        {isLoading && (
          <div className={styles.foot}>
            <div className={styles.blc_loader}>
              <Loader className={styles.loader} />
              <span>Loading</span>
            </div>
          </div>
        )}
      </div>
      <div ref={loader}></div>
      {!digitalProducts.length && !isLoading && (
        <div className={styles.info}>No products</div>
      )}
    </Card>
  );
};

export default DigitalProducts;
