import axios from "axios";
import Cookies from "js-cookie";

import { redirectToLoginPage } from "./redirectToLoginPage";

const createInstanceAxios = (url) =>
  axios.create({
    baseURL: `${url}/api`,
    headers: {
      "x-device-id": "admin-service-seamm",
    },
  });

const adminServiceBaseUrl = window.origin.includes("localhost")
  ? process.env.REACT_APP_ADMIN_SERVICE_BASE_URL
  : `${window.origin}/api-admin`;

export const axiosAuth = createInstanceAxios(adminServiceBaseUrl);

const axiosMain = createInstanceAxios(adminServiceBaseUrl);

axiosMain.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosMain.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      redirectToLoginPage();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get("refreshToken");

      try {
        const response = await axiosAuth.get("/token/refresh", {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });

        if (response.status === 200) {
          const { accessToken, refreshToken } = response.data;

          Cookies.set("accessToken", accessToken, { expires: 1 });
          Cookies.set("refreshToken", refreshToken, { expires: 14 });
          localStorage.setItem("isAuthorized", true);

          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        }
      } catch (error) {
        if (
          error.response.status === 401 &&
          error.config.url === "/token/refresh"
        ) {
          redirectToLoginPage();
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosMain;
