import { combineReducers, configureStore } from "@reduxjs/toolkit";

import loaderReducer from "./reducers/loader";
import notificationPopupReducer from "./reducers/notificationPopup";

const rootReducer = combineReducers({
  loaderReducer,
  notificationPopupReducer,
});
const store = configureStore({ reducer: rootReducer });

export default store;
