import React, { useState } from "react";

import styles from "./Analytics.module.sass";
import Datepicker from "./Datepicker";
import UserStats from "./UserStats";
import GeographyStats from "./GeographyStats";
import PlatformStats from "./PlatformStats";
import UserActivityStats from "./UserActivityStats";
import PopularModelStats from "./PopularModelStats";

const Analytics = () => {
  const [dateInterval, setDateInterval] = useState(null);

  return (
    <div className={styles.analytics}>
      <Datepicker
        currentDateInterval={dateInterval}
        setNewDateInterval={(interval) => setDateInterval(interval)}
      />
      {dateInterval && (
        <>
          <UserStats dateInterval={dateInterval} />
          <div className={styles.group}>
            <GeographyStats dateInterval={dateInterval} />
            <PlatformStats dateInterval={dateInterval} />
          </div>
          <UserActivityStats dateInterval={dateInterval} />
          <PopularModelStats dateInterval={dateInterval} />
        </>
      )}
    </div>
  );
};

export default Analytics;
