import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./NewAdmin.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import Dropdown from "../../components-dev/Dropdown";
import axios from "../../utils/axios";
import roles, { basicRole } from "../../assets/roles";

const NewAdmin = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [admin, setAdmin] = useState({
    email: "",
    role: roles[1],
  });

  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (basicRole) history.goBack();
  }, []);

  useEffect(() => {
    validate();
  }, [admin]);

  const handleChange = (prop, value) => {
    setAdmin((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .max(255)
        .required(),
      role: Joi.string()
        .valid(...roles)
        .required(),
    }).validate(admin, { abortEarly: false });
    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const createAdmin = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      await axios.post("admins", admin);

      hideLoader();
      displayNotificationPopup("saved successfully");

      history.push(`/admins`);
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    <>
      <InfoNavigation link={"/admins"} parts={["Admins", "Admin creation"]} />
      <div className={styles.head}>
        <div>
          <div className={cn("h3", styles.title)}>Admin creation</div>
        </div>
      </div>

      <Card>
        <div className={styles.creation_form}>
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                errorVisible && validationError.includes("email"),
            })}
            value={admin.email}
            placeholder="Email ..."
            label={"Email *"}
            onChange={({ target }) => handleChange("email", target.value)}
            error={errorVisible && validationError.includes("email")}
          />
          <Dropdown
            className={styles.dropdown}
            value={admin.role}
            setValue={(selectedValue) => {
              handleChange("role", selectedValue);
            }}
            options={roles}
            label={"Role *"}
          />
          <button
            className={cn("button", styles.save_button)}
            disabled={!isValid && errorVisible}
            onClick={createAdmin}
            onFocus={() => setErrorVisible(true)}
          >
            Save
          </button>
        </div>
      </Card>
    </>
  );
};

export default NewAdmin;
