import React from "react";
import cn from "classnames";

import styles from "./CodeInput.module.sass";
import Tooltip from "../Tooltip";

const CodeInput = ({
  className,
  classLabel,
  classInput,
  label,
  tooltip,
  place,
  error,
  ...props
}) => {
  return (
    <div className={cn(styles.field, { [styles.error]: error }, className)}>
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <input
          className={cn(classInput, styles.input, styles.code_input)}
          {...props}
        />
      </div>
    </div>
  );
};

export default CodeInput;
