import React from "react";
import cn from "classnames";

import styles from "./DigitalModel.module.sass";
import ImageStub from "../ImageStub";

const DigitalModel = ({ className, item, onClick, onAuxClick }) => {
  return (
    <div className={cn(styles.digital_model, className)}>
      <div className={styles.preview} onClick={onClick} onAuxClick={onAuxClick}>
        {item.previewUrl ? (
          <img
            srcSet={item.previewUrl}
            src={item.previewUrl}
            alt="Digital model"
          />
        ) : (
          <ImageStub alt="Digital model" />
        )}
      </div>
      <div className={styles.line}>
        <div className={styles.title}>{item.brand?.name}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.description}>
          <span>{item.titleFull}</span>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.dt}>{item.apparelType}</div>
        <div className={styles.dt}>{item.digitalModelType}</div>
        <div
          className={cn(
            item.isActive ? "status-green" : "status-red",
            styles.dm
          )}
        >
          {item.isActive ? "Active" : "Not active"}
        </div>
      </div>
    </div>
  );
};

export default DigitalModel;
