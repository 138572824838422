import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import styles from "./AutoHeightTextarea.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const AutoHeightTextarea = ({
  className,
  classLabel,
  classInput,
  label,
  tooltip,
  place,
  error,
  onPrettyJson,
  onClear,
  ...props
}) => {
  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue] = useState("");

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [currentValue]);

  return (
    <div className={cn(styles.field, { [styles.error]: error }, className)}>
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <textarea
          ref={textareaRef}
          className={cn(classInput, styles.textarea, {
            [styles.disabled]: props.disabled,
          })}
          value={currentValue}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          {...props}
        />
        {onPrettyJson && props.value && (
          <button
            className={styles.btn_beautify}
            onClick={() => onPrettyJson(textareaRef.current.value)}
          >
            <Icon name="edit" size="18" />
          </button>
        )}
        {onClear && props.value && (
          <button
            className={styles.btn_clear}
            onClick={() => {
              setCurrentValue("");
              onClear();
            }}
          >
            <Icon name="close" size="18" />
          </button>
        )}
      </div>
    </div>
  );
};

export default AutoHeightTextarea;
