import axios from "./axios";

export const uploadImagesToS3 = async (files, path) => {
  const bodyFormData = new FormData();
  files.forEach((file) => bodyFormData.append("image", file));

  try {
    const { data: resp } = await axios.post(`files/images`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        path,
      },
    });

    return resp.data;
  } catch (err) {
    throw err;
  }
};
