import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import styles from "./Users.module.sass";
import Table from "./Table";
import Card from "../../components-dev/Card";
import Form from "../../components-dev/Form";
import Dropdown from "../../components-dev/Dropdown";
import axios from "../../utils/axios";

const sortOptions = ["Registration date", "Last activity"];

const Users = ({ displayNotificationPopup }) => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(sortOptions[0]);
  const observer = useRef(null);
  const loader = useRef(null);
  const didMount = useRef(false);

  const limit = 25;

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    page && fetchUsers({ search, sort });
  }, [page]);

  useEffect(() => {
    if (!didMount.current) return;

    const timeoutId = setTimeout(() => {
      resetData();
      fetchUsers({ search, sort });
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    if (!didMount.current) return;

    resetData();
    fetchUsers({ search, sort });
  }, [sort]);

  const resetData = () => {
    setUsers([]);
    setPage(1);
  };

  const fetchUsers = async ({ search, sort }) => {
    try {
      setLoading(true);

      const params = { limit, offset: (page - 1) * limit };
      if (sort)
        params.sortBy =
          sort === "Registration date" ? "registrationDate" : "lastActivity";
      if (search?.length) params.search = search;

      const { data } = await axios.get("users", {
        params,
      });

      if (data.records.length) {
        setUsers((prev) => [...prev, ...data.records]);
      } else {
        observer.current.disconnect();
      }

      setLoading(false);

      if (!didMount.current) didMount.current = true;
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <Card
      className={styles.card}
      title="Customer"
      classCardHead={styles.head}
      classTitle={cn("title-purple", styles.title)}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            placeholder="Search by name, email or ID"
            type="text"
            name="search"
            icon="search"
          />
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdown_head}
            value={sort}
            setValue={setSort}
            options={sortOptions}
          />
        </>
      }
    >
      <Table
        className={styles.table}
        data={users}
        loader={loader}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default Users;
