import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./Row.module.sass";
import { wheelClickEventHandler } from "../../../../../utils/wheelClickEventHandler";

const Row = ({ data }) => {
  const history = useHistory();

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.model}>
            <div className={styles.preview}>
              <img
                src={
                  data.digitalModelPreviewUrl || "/images/game_image_stub.svg"
                }
                alt="Digital model"
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey)
                    window.open(`/digital-models/${data.digitalModelId}`);
                  else history.push(`/digital-models/${data.digitalModelId}`);
                }}
                onAuxClick={wheelClickEventHandler(
                  `/digital-models/${data.digitalModelId}`
                )}
                onError={(e) => (e.target.src = "/images/game_image_stub.svg")}
              />
            </div>
            <div className={styles.details}>
              <div className={styles.title}>
                <span
                  onClick={(e) => {
                    if (e.ctrlKey || e.metaKey)
                      window.open(`/digital-models/${data.digitalModelId}`);
                    else history.push(`/digital-models/${data.digitalModelId}`);
                  }}
                  onAuxClick={wheelClickEventHandler(
                    `/digital-models/${data.digitalModelId}`
                  )}
                >
                  {data.digitalModelName}
                </span>
              </div>
              <div className={styles.brand}>
                <span
                  onClick={(e) => {
                    if (e.ctrlKey || e.metaKey)
                      window.open(`/brands/${data.brandId}`);
                    else history.push(`/brands/${data.brandId}`);
                  }}
                  onAuxClick={wheelClickEventHandler(`/brands/${data.brandId}`)}
                >
                  {data.brandName}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.line} style={{ width: data.progress }}>
            {data.items.map((x, index) => (
              <div
                className={styles.box}
                style={{ backgroundColor: x.color, width: x.width }}
                key={index}
              >
                <div className={styles.tooltip}>
                  <div className={styles.subtitle}>{x.indicatorTitle}</div>
                  <div className={styles.legend}>
                    <div
                      className={styles.color}
                      style={{ backgroundColor: x.color }}
                    ></div>
                    <div className={styles.counter}>{x.counter}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
