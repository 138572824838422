import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./NewDigitalModel.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import Dropdown from "../../components-dev/Dropdown";
import TextInput from "../../components-dev/TextInput";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import Icon from "../../components-dev/Icon";
import FileUploader from "../../components-dev/FileUploader";
import BrandCreationForm from "../../components-dev/BrandCreationForm";
import ModelLoader from "../../components-dev/ModelLoader";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { scrollToTop } from "../../utils/scrollToTop";
import { isJsonString, toPrettyJson } from "../../utils/gJson";
import { addTabCharacter, resizeHeightTextarea } from "../../utils/textField";
import apparelTypes from "../../assets/apparelTypes";
import digitalModelTypes from "../../assets/digitalModelTypes";
import { basicRole } from "../../assets/roles";

const NewDigitalModel = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const history = useHistory();

  const [digitalModel, setDigitalModel] = useState({
    brandId: history.location.state || "",
    titleFull: "",
    name: "",
    description: "",
    apparelType: apparelTypes[0],
    digitalModelType: digitalModelTypes[0],
    physicalModelId: "",
    isActive: true,
    transferable: "not_transferable",
    freeCollection: false,
    giftingAvailable: false,
    previewUrl: "",
    previewAttributes: {
      size: "rectangle_343x220",
      bgColor: "#1C1C1C",
      priceTagColor: "#ffffff",
      priceTagBorderColor: "#000000",
      borderColor: "#1C1C1C",
    },
    customizationConfigurator: null,
    arGroupLensId: "",
    arLensId: "",
  });
  const [previewFile, setPreviewFile] = useState(null);
  const [glbFile, setGlbFile] = useState(null);
  const [usdzFile, setUsdzFile] = useState(null);
  const [brands, setBrands] = useState([]);
  const [physiсalModels, setPhysiсalModels] = useState([]);

  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [brandFormVisible, setBrandFormVisible] = useState(false);

  useEffect(() => {
    if (basicRole) history.goBack();

    scrollToTop();
    fetchData();
  }, []);

  useEffect(() => {
    validate();
  }, [digitalModel]);

  const fetchData = async () => {
    displayLoader();
    await fetchBrands();
    await fetchPhysiсalModels();
    hideLoader();
  };

  const fetchBrands = async (alreadyLoadedBrands = [], page = 1) => {
    try {
      const { data } = await axios.get("brand", {
        params: {
          page,
          limit: 20,
        },
      });

      if (data.records.length) {
        if (data.count > alreadyLoadedBrands.length + data.records.length) {
          await fetchBrands([...alreadyLoadedBrands, ...data.records], ++page);
        } else {
          setBrands([...alreadyLoadedBrands, ...data.records]);
          setDigitalModel((prev) => ({
            ...prev,
            brandId:
              prev.brandId || [...alreadyLoadedBrands, ...data.records][0]?.id,
          }));
        }
      }
    } catch {}
  };

  const fetchPhysiсalModels = async () => {
    try {
      const { data } = await axios.get("physicalmdl");

      if (data.records.length) {
        setPhysiсalModels(data.records);
      }
    } catch {}
  };

  const handleChange = (prop, value) => {
    setDigitalModel((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const {
      previewUrl,
      previewAttributes,
      customizationConfigurator,
      ...dModel
    } = digitalModel;

    const { error } = Joi.object({
      brandId: Joi.string().uuid().required(),
      titleFull: Joi.string().required(),
      name: Joi.string().required(),
      description: Joi.string().required(),
      apparelType: Joi.string().required(),
      digitalModelType: Joi.string().required(),
      isActive: Joi.boolean().required(),
      transferable: Joi.string().required(),
      freeCollection: Joi.boolean().required(),
      giftingAvailable: Joi.boolean().required(),
      physicalModelId: Joi.string().uuid().allow("").optional(),
      previewAttributes: Joi.string().optional(),
      customizationConfigurator: Joi.object({
        parts: Joi.array()
          .items(
            Joi.object({
              name: Joi.string().required(),
              title: Joi.string().required(),
              colors: Joi.array().items(Joi.string()).min(1),
              patterns: Joi.array().items(Joi.string()).min(1),
            }).required()
          )
          .required(),
        backgroundColors: Joi.array().items(Joi.string()).min(1),
      }).allow(null),
      arGroupLensId: Joi.string().uuid().allow(null, ""),
      arLensId: Joi.string().uuid().allow(null, ""),
    }).validate(
      {
        ...dModel,
        customizationConfigurator:
          isJsonString(customizationConfigurator) &&
          JSON.parse(customizationConfigurator),
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (uploadedFile) => {
    if (
      uploadedFile?.type.includes("image") ||
      !uploadedFile?.type.includes("image/gif")
    ) {
      const previewUrl = await generateImgUrl(uploadedFile);
      handleChange("previewUrl", previewUrl);
      setPreviewFile(uploadedFile);
    }
  };

  const onDeletePreview = () => {
    handleChange("previewUrl", "");
    setPreviewFile(null);
  };

  const uploadPreviewToS3 = async (file, digitalmdlId) => {
    const bodyFormData = new FormData();
    bodyFormData.append("previewPic", file);

    try {
      await axios.post(`digitalmdl/${digitalmdlId}/preview`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      displayNotificationPopup("error: upload preview");
    }
  };

  const upload3dModelToS3 = async (file, type, digitalModelId) => {
    const bodyFormData = new FormData();
    bodyFormData.append(type, file);

    try {
      await axios.post(`digitalmdl/${digitalModelId}/model3d`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  const createDigitalModel = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        brandId: digitalModel.brandId,
        titleFull: digitalModel.titleFull,
        name: digitalModel.name,
        description: digitalModel.description,
        apparelType: digitalModel.apparelType,
        digitalModelType: digitalModel.digitalModelType,
        physicalModelId: digitalModel.physicalModelId,
        isActive: digitalModel.isActive,
        transferable: digitalModel.transferable,
        freeCollection: digitalModel.freeCollection,
        giftingAvailable: digitalModel.giftingAvailable,
        previewAttributes: digitalModel.previewAttributes,
        customizationConfigurator: JSON.parse(
          digitalModel.customizationConfigurator
        ),
        arGroupLensId: digitalModel.arGroupLensId,
        arLensId: digitalModel.arLensId,
      };

      Object.keys(body).forEach((key) => {
        if (body[key] === null || body[key] === undefined) delete body[key];
        if (body[key] === "") body[key] = null;
      });

      const { data } = await axios.post("digitalmdl", body);

      if (data.id) {
        if (previewFile) {
          await uploadPreviewToS3(previewFile, data.id);
        }

        if (glbFile) {
          await upload3dModelToS3(glbFile, "glbFile", data.id);
        }

        if (usdzFile) {
          await upload3dModelToS3(usdzFile, "usdzFile", data.id);
        }
      }

      hideLoader();
      displayNotificationPopup("saved successfully");
      history.push(`/digital-models/${data.id}`);
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    <>
      <InfoNavigation
        link={"/digital-models"}
        parts={["Digital models", "Create digital model"]}
      />
      <div className={styles.head}>
        <div>
          <div className={cn("h3", styles.title)}>Create digital model</div>
        </div>
      </div>

      <Card>
        <div className={cn(styles.digital_model)}>
          <div className={styles.blc_left}>
            <div className={styles.preview}>
              {digitalModel.previewUrl ? (
                <div className={styles.preview_content}>
                  <img
                    srcSet={digitalModel.previewUrl}
                    src={digitalModel.previewUrl}
                    alt="Digital model"
                  />
                  <button
                    className={styles.btn_preview_add}
                    style={{
                      backgroundImage: `url("/images/icons/add/light.svg")`,
                    }}
                  >
                    <FileUploader onUpload={onUploadPreview} fill />
                  </button>
                  <button
                    className={styles.btn_preview_delete}
                    style={{
                      backgroundImage: `url("/images/icons/trash/light-red.svg")`,
                    }}
                    onClick={onDeletePreview}
                  ></button>
                </div>
              ) : (
                <div className={styles.preview_content}>
                  <FileUploader onUpload={onUploadPreview}>
                    <Icon name="add" size="52" fill="#b1b5b9" />
                  </FileUploader>
                </div>
              )}
            </div>
            <>
              <ModelLoader
                btnTitle={"Add Android 3D model"}
                modelFile="glbFileUrl"
                delay={(uploaded3dFile) => setGlbFile(uploaded3dFile)}
              />
              <ModelLoader
                btnTitle={"Add iOS 3D model"}
                modelFile="usdzFileUrl"
                delay={(uploaded3dFile) => setUsdzFile(uploaded3dFile)}
              />
            </>
          </div>

          <div className={styles.blc_right}>
            <div className={cn(styles.group, styles.nsame)}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={brands.find((b) => b.id === digitalModel.brandId)?.name}
                setValue={(selectedBrand) => {
                  handleChange("brandId", selectedBrand.id);
                }}
                options={brands}
                loading
                label={"Brand name *"}
              />
              {/* <button
                className={cn("button-stroke", styles.create_brand_button)}
                onClick={() => setBrandFormVisible(true)}
              >
                <Icon name="add" size="24" />
                <span>Create brand</span>
              </button> */}
            </div>
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("titleFull"),
              })}
              value={digitalModel.titleFull}
              placeholder="Title full ..."
              label={"Title full *"}
              onChange={({ target }) => handleChange("titleFull", target.value)}
              error={errorVisible && validationError.includes("titleFull")}
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("name"),
              })}
              value={digitalModel.name}
              placeholder="Name ..."
              label={"Name *"}
              onChange={({ target }) => handleChange("name", target.value)}
              error={errorVisible && validationError.includes("name")}
            />
            <AutoHeightTextarea
              classInput={cn(styles.textarea, styles.min_height_205)}
              value={digitalModel.description}
              placeholder="Description ..."
              label={"Description *"}
              onChange={({ target }) =>
                handleChange("description", target.value)
              }
              error={errorVisible && validationError.includes("description")}
            />
            <div className={cn(styles.group, styles.same)}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={digitalModel.apparelType}
                setValue={(selectedValue) => {
                  handleChange("apparelType", selectedValue);
                }}
                options={apparelTypes}
                label={"Apparel type *"}
              />
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={digitalModel.digitalModelType}
                setValue={(selectedValue) => {
                  handleChange("digitalModelType", selectedValue);
                }}
                options={digitalModelTypes}
                label={"Model type *"}
              />
            </div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={
                physiсalModels.find(
                  (model) => model.id === digitalModel.physicalModelId
                )?.name
              }
              placeholder="Physiсal model ..."
              setValue={(selectedPhysiсalModel) => {
                handleChange("physicalModelId", selectedPhysiсalModel.id);
              }}
              options={physiсalModels}
              label={"Physiсal model"}
              loading
              clear={digitalModel.physicalModelId}
            />
            <div className={styles.blc_switch}>
              <span>Active</span>
              <Switch
                className={styles.switch}
                value={digitalModel.isActive}
                onChange={() =>
                  setDigitalModel((prev) => ({
                    ...prev,
                    isActive: !prev.isActive,
                  }))
                }
              />
            </div>
            <div className={styles.blc_switch}>
              <span>Transferable</span>
              <Switch
                className={styles.switch}
                value={digitalModel.transferable !== "not_transferable"}
                onChange={() =>
                  handleChange(
                    "transferable",
                    digitalModel.transferable === "not_transferable"
                      ? "decentraland"
                      : "not_transferable"
                  )
                }
              />
            </div>
            <div className={styles.blc_switch}>
              <span>Free collection for new users</span>
              <Switch
                className={styles.switch}
                value={digitalModel.freeCollection}
                onChange={() =>
                  setDigitalModel((prev) => ({
                    ...prev,
                    freeCollection: !prev.freeCollection,
                  }))
                }
              />
            </div>
            <div className={styles.blc_switch}>
              <span>Gifting</span>
              <Switch
                className={styles.switch}
                value={digitalModel.giftingAvailable}
                onChange={() =>
                  setDigitalModel((prev) => ({
                    ...prev,
                    giftingAvailable: !prev.giftingAvailable,
                  }))
                }
              />
            </div>
            <AutoHeightTextarea
              className={cn({ ["unchangeable"]: true })}
              classInput={styles.textarea}
              label={"Preview attributes (JSON) *"}
              value={toPrettyJson(digitalModel.previewAttributes)}
              placeholder="Preview attributes ..."
              disabled={true}
            />
            <AutoHeightTextarea
              classInput={cn(styles.textarea)}
              label={"Customization configurator (JSON)"}
              value={digitalModel.customizationConfigurator || ""}
              placeholder="Customization configurator ..."
              onChange={({ target }) => {
                handleChange("customizationConfigurator", target.value);
                resizeHeightTextarea(target);
              }}
              onKeyDown={addTabCharacter}
              onPrettyJson={(value) =>
                handleChange("customizationConfigurator", toPrettyJson(value))
              }
              onClear={() => handleChange("customizationConfigurator", null)}
              error={
                errorVisible &&
                validationError.includes("customizationConfigurator")
              }
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("arGroupLensId"),
              })}
              value={digitalModel.arGroupLensId}
              placeholder="AR group lens ID ..."
              label={"AR group lens ID"}
              onChange={({ target }) =>
                handleChange("arGroupLensId", target.value)
              }
              error={errorVisible && validationError.includes("arGroupLensId")}
            />
            <TextInput
              classInput={cn(styles.text_input, {
                [styles.text_input_error]:
                  errorVisible && validationError.includes("arLensId"),
              })}
              value={digitalModel.arLensId}
              placeholder="AR lens ID ..."
              label={"AR lens ID"}
              onChange={({ target }) => handleChange("arLensId", target.value)}
              error={errorVisible && validationError.includes("arLensId")}
            />
            <button
              className={cn("button", styles.save_button)}
              disabled={!isValid && errorVisible}
              onClick={createDigitalModel}
              onFocus={() => setErrorVisible(true)}
            >
              Save
            </button>
          </div>
        </div>
      </Card>
      <BrandCreationForm
        visible={brandFormVisible}
        setVisible={setBrandFormVisible}
        onCreate={(newBrand) => {
          setBrands((prev) => [newBrand, ...prev]);
          handleChange("brandId", newBrand.id);
        }}
      />
    </>
  );
};

export default NewDigitalModel;
