import React from "react";
import cn from "classnames";

import styles from "./Plug.module.sass";

const Plug = ({ className, info }) => {
  return (
    <div className={cn(styles.plug, className)}>
      <div className={styles.info}>
        {info || "Data is not yet available, please check back later."}
      </div>
    </div>
  );
};

export default Plug;
