import React, { useState, useEffect } from "react";
import cn from "classnames";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from "recharts";

import styles from "./GeographyStats.module.sass";
import GeographyStatsTooltip from "./GeographyStatsTooltip";
import Loader from "../Loader";
import RetryLoading from "../RetryLoading";
import Plug from "../Plug";
import Card from "../../../components-dev/Card";
import Icon from "../../../components-dev/Icon";
import Tooltip from "../../../components-dev/Tooltip";
import axios from "../../../utils/axios";

const GeographyStats = ({ dateInterval }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(0);
  const limit = 5;

  useEffect(() => {
    fetchData();
  }, [dateInterval]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("stats/geography", {
        params: { ...dateInterval },
      });

      setData(data);
      setMaxPageNumberLimit(Math.ceil(data.length / limit));

      setLoading(false);
      setErrorLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorLoading(true);
    }
  };

  const paginate = (action) => {
    if (action === "next" && currentPage <= maxPageNumberLimit) {
      setCurrentPage((prev) => ++prev);
    }
    if (action === "prev" && currentPage > 1) {
      setCurrentPage((prev) => --prev);
    }
  };

  return (
    <Card
      className={cn(styles.card)}
      title="Geography"
      classTitle="title-blue"
      head={
        <Tooltip
          className={styles.tooltip}
          title="Distribution of all users by country for the selected period"
          icon="info-stroke"
          place="left"
        />
      }
    >
      {isLoading && <Loader className={styles.chart_loader} />}

      {!isLoading && !isErrorLoading && data.length && (
        <div className={styles.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data.slice(
                (currentPage - 1) * limit,
                (currentPage - 1) * limit + limit
              )}
              layout="vertical"
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barCategoryGap={4}
            >
              <CartesianGrid
                strokeDasharray="none"
                stroke="#EFEFEF"
                horizontal={false}
                vertical={false}
              />
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
                padding={{ left: 10, right: 15 }}
              />
              <YAxis
                width={82}
                orientation="left"
                type="category"
                dataKey="country"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#6F767E" }}
                tickFormatter={(el) =>
                  el.length < 12 ? el : `${el.slice(0, 9)}...`
                }
              />
              <ChartTooltip
                content={<GeographyStatsTooltip />}
                wrapperStyle={{ zIndex: 1 }}
                cursor={{ fill: "#f3f2f3" }}
              />
              <Bar dataKey="usersCount" fill="#B1E5FC" barSize={20} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {!isLoading && data.length > limit && (
        <div className={styles.blc_pagination}>
          <button
            className={styles.btn_arrow}
            onClick={() => paginate("prev")}
            disabled={currentPage === 1}
          >
            <Icon name="arrow-left" size="17" />
          </button>
          <button
            className={styles.btn_arrow}
            onClick={() => paginate("next")}
            disabled={currentPage === maxPageNumberLimit}
          >
            <Icon name="arrow-right" size="17" />
          </button>
        </div>
      )}

      {!isLoading && !data.length && <Plug className={styles.plug} />}

      {!isLoading && isErrorLoading && (
        <RetryLoading className={styles.retry_loading} retry={fetchData} />
      )}
    </Card>
  );
};

export default GeographyStats;
