import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./MarketplaceProducts.module.sass";
import Card from "../../../components-dev/Card";
import Loader from "../../../components-dev/Loader";
import RedirectTableCell from "../../../components-dev/RedirectTableCell";
import axios from "../../../utils/axios";
import { wheelClickEventHandler } from "../../../utils/wheelClickEventHandler";

const MarketplaceProducts = ({ className, digitalModel }) => {
  const [digitalProducts, setDigitalProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchDigitalProducts();
  }, []);

  const fetchDigitalProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("marketplace/products", {
        params: { digitalModelId: digitalModel.id, limit: 50, page: 1 },
      });

      setDigitalProducts(data.products);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        className={styles.card}
        title={"Marketplace products"}
        classTitle="title-green"
        btnCreate={{
          title: "Create product",
          onClick: (e) => {
            if (e.ctrlKey || e.metaKey) window.open("/digital-products/new");
            else history.push("/digital-products/new", digitalModel);
          },
          onAuxClick: wheelClickEventHandler("/digital-products/new"),
        }}
      >
        <div className={cn(styles.wrapper, className)}>
          {digitalProducts.length ? (
            <div className={cn(styles.table)}>
              <div className={cn(styles.row)}>
                <div className={styles.col}>Product ID</div>
                <div className={styles.col}>Price</div>
                <div className={styles.col}>Stocks</div>
                <div className={styles.col}>Available</div>
              </div>
              {digitalProducts.map((pr, index) => (
                <div className={styles.row_content} key={index}>
                  <div className={styles.col_content}>
                    <RedirectTableCell
                      text={pr.id}
                      to={`/digital-products/${pr.id}`}
                    />
                  </div>
                  <div className={styles.col_content}>$ {pr.amount.price}</div>
                  <div className={styles.col_content}>{`${pr.inStock}/${
                    pr.inStock + pr.sold
                  }`}</div>
                  <div className={styles.col_content}>
                    <div
                      className={cn(
                        pr.available ? "status-green" : "status-silver"
                      )}
                    >
                      {pr.available ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            !isLoading && <div className={styles.info}>No products</div>
          )}
          {isLoading && (
            <div className={styles.foot}>
              <div className={styles.blc_loader}>
                <Loader className={styles.loader} />
                <span>Loading</span>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default MarketplaceProducts;
