export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
};

export const toPrettyJson = (str) => {
  if (!str) return str;

  return isJsonString(str)
    ? JSON.stringify(JSON.parse(str), undefined, 6)
    : JSON.stringify(str, undefined, 6);
};
