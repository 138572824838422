import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./Game.module.sass";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import Dropdown from "../../components-dev/Dropdown";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import Icon from "../../components-dev/Icon";
import GameTransferDescriptionParams from "../../components-dev/GameTransferDescriptionParams";
import GameImageLoader from "../../components-dev/BrandImageLoader";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { basicRole } from "../../assets/roles";
import gameTypes from "../../assets/gameTypes";

const Game = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const [game, setGame] = useState({});
  const [gameImageFile, setGameImageFile] = useState(null);
  const [gameLogoFile, setGameLogoFile] = useState(null);
  const [clipboard, setClipboard] = useState({});

  const { id: gameId } = useParams();

  useEffect(() => {
    scrollToTop();
    fetchGame();
  }, []);

  useEffect(() => {
    validate();
  }, [game]);

  const fetchGame = async () => {
    try {
      displayLoader();

      const { data } = await axios.get(`games/${gameId}`);

      const path = "games/transfer_description_params/";

      const transferDescriptionParams = data.transferDescriptionParams.map(
        (param) => ({
          ...param,
          ...(param.image
            ? {
                image: param.image.includes(path)
                  ? `${path}${param.image.split(path)[1]}`
                  : param.image,
              }
            : {}),
        })
      );

      const fetchedGame = {
        id: data.id,
        gameNameFull: data.name,
        gameNameShort: data.shortName || "",
        gameType: data.type,
        gameDescription: data.description,
        isActive: data.isActive,
        transferDescriptionParams,
        gameLogoUrl: data.logo,
        gameImageUrl: data.image,
      };

      setGame(fetchedGame);
      setClipboard(fetchedGame);

      hideLoader();
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setGame((prev) => ({
        ...clipboard,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setGame((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      gameNameFull: Joi.string().max(255).required(),
      gameNameShort: Joi.string().max(255).required(),
      gameType: Joi.string()
        .valid(...gameTypes)
        .required(),
      gameDescription: Joi.string().required(),
      isActive: Joi.boolean().required(),
      transferDescriptionParams: Joi.array()
        .items(
          Joi.object({
            step: Joi.number().integer().min(1).required(),
            text: Joi.string().required(),
            image: Joi.string().max(2000),
          })
        )
        .min(1)
        .allow(null),
      gameLogoUrl: Joi.string().required(),
      gameImageUrl: Joi.string().required(),
    }).validate(
      {
        gameNameFull: game.gameNameFull,
        gameNameShort: game.gameNameShort,
        gameType: game.gameType,
        gameDescription: game.gameDescription,
        isActive: game.isActive,
        transferDescriptionParams: game.transferDescriptionParams,
        gameLogoUrl: game.gameLogoUrl,
        gameImageUrl: game.gameImageUrl,
      },
      { abortEarly: false }
    );
    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (type, uploadedFile, sizeLimit = 10000000) => {
    if (uploadedFile?.size > sizeLimit) {
      displayNotificationPopup("error: file size limit");
      return;
    }

    if (
      uploadedFile?.type.includes("image") &&
      !uploadedFile?.type.includes("image/gif")
    ) {
      const imgUrl = await generateImgUrl(uploadedFile);
      handleChange(type, imgUrl);
      type === "gameImageUrl"
        ? setGameImageFile(uploadedFile)
        : setGameLogoFile(uploadedFile);
    } else {
      displayNotificationPopup("error: unexpected file");
    }
  };

  const onDeletePreview = (type) => {
    handleChange(type, "");
    type === "gameImageUrl" ? setGameImageFile(null) : setGameLogoFile(null);
  };

  const uploadImageToS3 = async (files, path) => {
    const bodyFormData = new FormData();
    files.forEach((file) => bodyFormData.append("image", file));

    try {
      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path,
        },
      });

      return resp.data;
    } catch (err) {
      throw err;
    }
  };

  const uploadGameImages = async () => {
    try {
      let gameImage = null;
      let gameLogo = null;

      if (gameImageFile) {
        [gameImage] = await uploadImageToS3(
          [gameImageFile],
          `games/${gameId}/preview`
        );
      }
      if (gameLogoFile) {
        [gameLogo] = await uploadImageToS3(
          [gameLogoFile],
          `games/${gameId}/logo`
        );
      }

      return { gameImage, gameLogo };
    } catch (err) {
      displayNotificationPopup("error: upload image(s)");
    }
  };

  const update = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        gameNameFull: game.gameNameFull,
        gameType: game.gameType,
        gameDescription: game.gameDescription,
        isActive: game.isActive,
      };

      if (game.gameNameShort) body.gameNameShort = game.gameNameShort;
      if (game.transferDescriptionParams)
        body.transferDescriptionParams = game.transferDescriptionParams;

      if (gameImageFile || gameLogoFile) {
        const { gameImage, gameLogo } = await uploadGameImages();
        body.gameImage =
          gameImage || `games${game.gameImageUrl.split("games")[1]}`;
        body.gameLogo =
          gameLogo || `games${game.gameLogoUrl.split("games")[1]}`;
      }

      await axios.put(`games/${gameId}`, body);

      setClipboard((prev) => ({
        id: prev.id,
        ...body,
      }));
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    !isEmptyObject(game) && (
      <>
        <InfoNavigation link={"/games"} parts={["Games", game.id || "Game"]} />
        <div className={styles.head}>
          <div className={styles.blc_title}>
            <div className={cn("h3", styles.title)}>
              {game.gameNameFull || "Game"}
            </div>
          </div>
          {!basicRole && (
            <button
              className={cn("button-stroke", styles.switch_button, [
                !isEditMode ? styles.info : styles.edit,
              ])}
              onClick={changeMode}
            >
              <Icon name={!isEditMode ? "edit" : "close"} size="24" />
              <span>{!isEditMode ? "Edit" : "Cancel"}</span>
            </button>
          )}
        </div>

        <Card>
          <div className={cn(styles.game)}>
            <div className={styles.blc_left}>
              <GameImageLoader
                label="Logo *"
                imgSrc={game.gameLogoUrl}
                isEditMode={isEditMode}
                isError={isEditMode && validationError.includes("gameLogoUrl")}
                onUpload={(file) => onUploadPreview("gameLogoUrl", file)}
                onDelete={() => onDeletePreview("gameLogoUrl")}
              />
              <GameImageLoader
                label="Image *"
                imgSrc={game.gameImageUrl}
                isEditMode={isEditMode}
                isError={isEditMode && validationError.includes("gameImageUrl")}
                onUpload={(file) => onUploadPreview("gameImageUrl", file)}
                onDelete={() => onDeletePreview("gameImageUrl")}
              />
            </div>

            <div className={styles.blc_right}>
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    validationError.includes("gameNameFull"),
                })}
                value={game.gameNameFull}
                placeholder="Full name ..."
                label={"Full game name *"}
                onChange={({ target }) =>
                  handleChange("gameNameFull", target.value)
                }
                error={isEditMode && validationError.includes("gameNameFull")}
                disabled={!isEditMode}
              />
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    validationError.includes("gameNameShort"),
                })}
                value={game.gameNameShort}
                placeholder="Short name ..."
                label={"Short game name *"}
                onChange={({ target }) =>
                  handleChange("gameNameShort", target.value)
                }
                error={isEditMode && validationError.includes("gameNameShort")}
                disabled={!isEditMode}
              />
              <Dropdown
                value={game.gameType}
                setValue={(selectedValue) => {
                  handleChange("gameType", selectedValue);
                }}
                options={gameTypes}
                label={"Game type *"}
                disabled={!isEditMode}
              />
              <AutoHeightTextarea
                classInput={styles.textarea}
                value={game.gameDescription}
                placeholder="Description ..."
                label={"Description *"}
                onChange={({ target }) =>
                  handleChange("gameDescription", target.value)
                }
                error={
                  isEditMode && validationError.includes("gameDescription")
                }
                disabled={!isEditMode}
              />
              <GameTransferDescriptionParams
                data={game.transferDescriptionParams || []}
                onChange={(tdParams) =>
                  setGame((prev) => ({
                    ...prev,
                    transferDescriptionParams: tdParams.length
                      ? tdParams
                      : null,
                  }))
                }
                validationError={
                  isEditMode &&
                  validationError.includes("transferDescriptionParams") &&
                  validationError
                }
                disabled={!isEditMode}
              />
              <div className={styles.blc_switch}>
                <span>Active</span>
                <Switch
                  className={styles.switch}
                  value={game.isActive}
                  onChange={() =>
                    setGame((prev) => ({
                      ...prev,
                      isActive: !prev.isActive,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              {isEditMode && (
                <button
                  className={cn("button", styles.save_button)}
                  disabled={!isValid}
                  onClick={update}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </Card>
      </>
    )
  );
};

export default Game;
