import React from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import cn from "classnames";

import styles from "./Row.module.sass";
import RedirectTableCell from "../../../../components-dev/RedirectTableCell";
import Icon from "../../../../components-dev/Icon";
import { display as displayNotificationPopup } from "../../../../store/reducers/notificationPopup";

const Row = ({ item }) => {
  const dispatch = useDispatch();

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(item.deeplink);
    dispatch(displayNotificationPopup("copied to clipboard"));
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <RedirectTableCell
            text={item.digitalModel?.name}
            to={`/digital-models/${item.digitalModel?.id}`}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.deeplink}>{item.digitalModel?.titleFull}</div>
        </div>
        <div className={styles.col} title={item.id}>
          <RedirectTableCell text={item.id} to={`/digital-items/${item.id}`} />
        </div>
        <div className={styles.col}>
          <div className={styles.user_nickname}>{item.user?.nickname}</div>
        </div>
        <div className={styles.col} title={item.deeplink}>
          <div className={styles.deeplink}>{item.deeplink}</div>
          <button className={styles.copy} onClick={copyTextToClipboard}>
            <Icon name="copy" size="18" />
          </button>
        </div>
        <div className={styles.col}>
          <div className={cn(item.isActive ? "status-green" : "status-red")}>
            {item.isActive ? "Active" : "Not active"}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.date}>
            <span>{format(new Date(item.createdAt), "yyyy-MM-dd")}</span>
            <span>{format(new Date(item.createdAt), "hh:mm")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
