import React from "react";
import ReactTooltip from "react-tooltip";

import styles from "./TooltipGlodal.module.sass";

const TooltipGlodal = () => {
  return (
    <ReactTooltip
      className={styles.tooltipWpapper}
      effect="solid"
      border={true}
      borderColor="#FFFFFF12"
      backgroundColor="#272B30"
      textColor="#EFEFEF"
    />
  );
};

export default TooltipGlodal;
