export const apparelTypes = [
  "hats",
  "accessories",
  "tshirts",
  "hoodiessweatshirts",
  "jacketscoats",
  "jeanspants",
  "shoes",
  "bags",
  "backpacks",
  "fullsuits",
];

export default apparelTypes;
