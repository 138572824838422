import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./Brand.module.sass";
import DigitalModels from "./DigitalModels";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import TextInput from "../../components-dev/TextInput";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import Icon from "../../components-dev/Icon";
import BrandImageLoader from "../../components-dev/BrandImageLoader";
import axios from "../../utils/axios";
import { generateImgUrl } from "../../utils/generateImgUrl";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { basicRole } from "../../assets/roles";

const Brand = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const [brand, setBrand] = useState({});
  const [previewFile, setPreviewFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [clipboard, setClipboard] = useState({});

  const { id: brandId } = useParams();

  useEffect(() => {
    scrollToTop();
    fetchBrand();
  }, []);

  useEffect(() => {
    validate();
  }, [brand]);

  const fetchBrand = async () => {
    try {
      displayLoader();

      const { data } = await axios.get(`brand/${brandId}`);
      setBrand(data);
      setClipboard(data);

      hideLoader();
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const changeMode = () => {
    if (isEditMode) {
      setBrand((prev) => ({
        ...clipboard,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setBrand((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      name: Joi.string().required(),
      description: Joi.string().required(),
      isActive: Joi.boolean().required(),
      appVisibility: Joi.boolean().required(),
      previewUrl: Joi.string().required(),
      logoUrl: Joi.string().required(),
    }).validate(
      {
        name: brand.name,
        description: brand.description,
        isActive: brand.isActive,
        appVisibility: brand.appVisibility,
        previewUrl: brand.previewUrl,
        logoUrl: brand.logoUrl,
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const onUploadPreview = async (type, uploadedFile, sizeLimit = 10000000) => {
    if (uploadedFile?.size > sizeLimit) {
      displayNotificationPopup("error: file size limit");
      return;
    }

    if (
      uploadedFile?.type.includes("image") ||
      !uploadedFile?.type.includes("image/gif")
    ) {
      const imgUrl = await generateImgUrl(uploadedFile);
      handleChange(type, imgUrl);
      type === "previewUrl"
        ? setPreviewFile(uploadedFile)
        : setLogoFile(uploadedFile);
    } else {
      displayNotificationPopup("error: unexpected file");
    }
  };

  const onDeletePreview = (type) => {
    handleChange(type, "");
    type === "previewUrl" ? setPreviewFile(null) : setLogoFile(null);
  };

  const uploadImageToS3 = async (files, path) => {
    const bodyFormData = new FormData();
    files.forEach((file) => bodyFormData.append("image", file));

    try {
      const { data: resp } = await axios.post(`files/images`, bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          path,
        },
      });

      return resp.data;
    } catch (err) {
      throw err;
    }
  };

  const uploadBrandImages = async () => {
    try {
      let previewUrl = null;
      let logoUrl = null;

      if (previewFile) {
        [previewUrl] = await uploadImageToS3(
          [previewFile],
          `brands/${brandId}/preview`
        );
      }
      if (logoFile) {
        [logoUrl] = await uploadImageToS3([logoFile], `brands/${brandId}/logo`);
      }

      return { previewUrl, logoUrl };
    } catch (err) {
      throw err;
    }
  };

  const update = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        name: brand.name,
        description: brand.description,
        isActive: brand.isActive,
        appVisibility: brand.appVisibility,
      };

      if (previewFile || logoFile) {
        const { previewUrl, logoUrl } = await uploadBrandImages();
        body.previewUrl =
          previewUrl || `brands${brand.previewUrl.split("brands")[1]}`;
        body.logoUrl = logoUrl || `brands${brand.logoUrl.split("brands")[1]}`;
      }

      await axios.put(`brand/${brandId}`, body);

      setClipboard((prev) => ({
        id: prev.id,
        ...body,
      }));
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  return (
    !isEmptyObject(brand) && (
      <>
        <InfoNavigation
          link={"/brands"}
          parts={["Brands", brand.name || "brand"]}
        />
        <div className={styles.head}>
          <div className={styles.blc_title}>
            <div className={cn("h3", styles.title)}>{brand.name}</div>
          </div>
          {!basicRole && (
            <button
              className={cn("button-stroke", styles.switch_button, [
                !isEditMode ? styles.info : styles.edit,
              ])}
              onClick={changeMode}
            >
              <Icon name={!isEditMode ? "edit" : "close"} size="24" />
              <span>{!isEditMode ? "Edit" : "Cancel"}</span>
            </button>
          )}
        </div>

        <Card>
          <div className={cn(styles.brand)}>
            <div className={styles.blc_left}>
              <BrandImageLoader
                label="Brand preview *"
                imgSrc={brand.previewUrl}
                isEditMode={isEditMode}
                isError={isEditMode && validationError.includes("previewUrl")}
                onUpload={(file) => onUploadPreview("previewUrl", file)}
                onDelete={() => onDeletePreview("previewUrl")}
              />
              <BrandImageLoader
                label="Brand logo *"
                imgSrc={brand.logoUrl}
                isEditMode={isEditMode}
                isError={isEditMode && validationError.includes("logoUrl")}
                onUpload={(file) => onUploadPreview("logoUrl", file)}
                onDelete={() => onDeletePreview("logoUrl")}
              />
            </div>

            <div className={styles.blc_right}>
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]: validationError.includes("name"),
                })}
                value={brand.name}
                placeholder="Brand name ..."
                label={"Brand name *"}
                onChange={({ target }) => handleChange("name", target.value)}
                error={isEditMode && validationError.includes("name")}
                disabled={!isEditMode}
              />
              <AutoHeightTextarea
                classInput={styles.textarea}
                value={brand.description}
                placeholder="Description ..."
                label={"Description *"}
                onChange={({ target }) =>
                  handleChange("description", target.value)
                }
                error={isEditMode && validationError.includes("description")}
                disabled={!isEditMode}
              />
              <div className={styles.blc_switch}>
                <span>Active</span>
                <Switch
                  className={styles.switch}
                  value={brand.isActive}
                  onChange={() =>
                    setBrand((prev) => ({
                      ...prev,
                      isActive: !prev.isActive,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              <div className={styles.blc_switch}>
                <span>App visibility</span>
                <Switch
                  className={styles.switch}
                  value={brand.appVisibility}
                  onChange={() =>
                    setBrand((prev) => ({
                      ...prev,
                      appVisibility: !prev.appVisibility,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              {isEditMode && (
                <button
                  className={cn("button", styles.save_button)}
                  disabled={!isValid}
                  onClick={update}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </Card>
        {!isEditMode && <DigitalModels brandId={brand.id} />}
      </>
    )
  );
};

export default Brand;
