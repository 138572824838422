import React, { useState, useEffect } from "react";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  subWeeks,
  subMonths,
} from "date-fns";

import styles from "./Datepicker.module.sass";
import Card from "../../../components-dev/Card";
import Dropdown from "../../../components-dev/Dropdown";
import { formatDateInterval } from "../../../utils/formatDateInterval";

const intervals = [
  "current week",
  "previous week",
  "current month",
  "previous month",
  "all time",
];

const Datepicker = ({ currentDateInterval, setNewDateInterval }) => {
  const [interval, setInterval] = useState(intervals[2]);

  useEffect(() => {
    const newDateInterval = getNewDateInterval();
    setNewDateInterval(newDateInterval);
  }, [interval]);

  const getNewDateInterval = () => {
    try {
      let newDateInterval = currentDateInterval;

      switch (interval) {
        case intervals[0]:
          newDateInterval = {
            startDate: format(
              startOfWeek(new Date(), { weekStartsOn: 1 }),
              "y-MM-dd"
            ),
            endDate: format(new Date(), "y-MM-dd"),
          };
          break;
        case intervals[1]:
          newDateInterval = {
            startDate: format(
              startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
              "y-MM-dd"
            ),
            endDate: format(
              endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
              "y-MM-dd"
            ),
          };
          break;
        case intervals[2]:
          newDateInterval = {
            startDate: format(startOfMonth(new Date()), "y-MM-dd"),
            endDate: format(new Date(), "y-MM-dd"),
          };
          break;
        case intervals[3]:
          newDateInterval = {
            startDate: format(
              startOfMonth(subMonths(new Date(), 1)),
              "y-MM-dd"
            ),
            endDate: format(endOfMonth(subMonths(new Date(), 1)), "y-MM-dd"),
          };
          break;
        case intervals[4]:
          newDateInterval = {
            startDate: format(startOfYear(new Date()), "y-MM-dd"),
            endDate: format(new Date(), "y-MM-dd"),
          };
          break;
        default:
          break;
      }

      return newDateInterval;
    } catch {
      return currentDateInterval;
    }
  };

  return (
    <Card className={styles.card}>
      {currentDateInterval && (
        <div className={styles.date}>
          {formatDateInterval(currentDateInterval, "y MMM d")}
        </div>
      )}
      <Dropdown
        className={styles.dropdown}
        classDropdownHead={styles.dropdown_head}
        value={interval}
        setValue={setInterval}
        options={intervals}
      />
    </Card>
  );
};

export default Datepicker;
