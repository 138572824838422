import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";

import styles from "./Sidebar.module.sass";
import Dropdown from "./Dropdown";
import Icon from "../Icon";
import Image from "../Image";

const roles = localStorage.getItem("roles");

const navigation = [
  {
    title: "Dashboard",
    icon: "pie_chart/light.svg",
    url: "/analytics",
  },
  {
    title: "Brands",
    icon: "category/light.svg",
    url: "/brands",
  },
  {
    title: "Collections",
    slug: ["digital-models", "digital-items"],
    icon: "diamond/light.svg",
    dropdown: [
      {
        title: "Digital models",
        url: "/digital-models",
      },
      {
        title: "Digital items",
        url: "/digital-items",
      },
    ],
  },
  {
    title: "Marketplace",
    slug: ["digital-products"],
    icon: "cart/light.svg",
    dropdown: [
      {
        title: "Products",
        url: "/digital-products",
      },
    ],
  },
  {
    title: "Transfer",
    slug: ["games", "transfer-requests"],
    icon: "arrow_up_down/light.svg",
    dropdown: [
      {
        title: "Games and Metaverses",
        url: "/games",
      },
      {
        title: "Transfer requests",
        url: "/transfer-requests",
      },
    ],
  },
  {
    title: "Marketing",
    slug: ["stories"],
    icon: "rocket/light.svg",
    dropdown: [
      {
        title: "Stories",
        url: "/stories",
      },
    ],
  },
  {
    title: "Admins",
    icon: "profile_circled/light.svg",
    url: "/admins",
    isHide: (title) => title === "Admins" && !roles?.includes("superadmin"),
  },
  {
    title: "Users",
    icon: "person/light.svg",
    url: "/users",
  },
  {
    title: "Features",
    icon: "star/light.svg",
    url: "/settings",
    isHide: (title) => {
      return (
        title === "Features" &&
        !roles?.includes("superadmin") &&
        !roles?.includes("admin")
      );
    },
  },
  {
    title: "Image upload",
    icon: "file_add//light.svg",
    url: "/image-uploader",
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src={`/images/brand_logos/${window.origin.split(".")[1]}.svg`}
            alt="Logo"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.hide]: x.isHide && x.isHide(x.title),
                })}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                exact={false}
                onClick={onClose}
              >
                <img
                  src={`/images/icons/${x.icon}`}
                  alt="section"
                  title={x.title}
                />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
      </div>
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
