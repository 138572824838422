import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./CreationForm.module.sass";
import TextInput from "../../../../components-dev/TextInput";
import Dropdown from "../../../../components-dev/Dropdown";
import Icon from "../../../../components-dev/Icon";
import axios from "../../../../utils/axios";
import {
  display as displayLoader,
  hide as hideLoader,
} from "../../../../store/reducers/loader";
import { display as displayNotificationPopup } from "../../../../store/reducers/notificationPopup";

const CreationForm = ({
  className,
  visible,
  setVisible,
  product,
  onCreate,
}) => {
  const [payload, setPayload] = useState({
    productId: product.id,
    createAmount: 1,
  });
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    validate();
  }, [payload]);

  const handleChange = (prop, value) => {
    setPayload((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    const { error } = Joi.object({
      productId: Joi.string().uuid().required(),
      createAmount: Joi.number().integer().min(1).max(100).required(),
    }).validate(payload, { abortEarly: false });

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const createProductItems = async () => {
    if (!isValid) return;

    try {
      dispatch(displayLoader());

      const {
        data: { productItems },
      } = await axios.post("marketplace/product-items", payload);

      onCreate(productItems);
      setVisible(false);

      dispatch(hideLoader());
      dispatch(displayNotificationPopup("saved successfully"));
    } catch (err) {
      dispatch(hideLoader());
      dispatch(displayNotificationPopup("error"));
    }
  };

  return (
    <div
      className={cn(styles.popup, {
        [styles.visible]: visible,
      })}
    >
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div className={cn(styles.creation_form, className)}>
          <div>
            <span>Add product items</span>
            <button onClick={() => setVisible(false)}>
              <Icon name="close" size="24" />
            </button>
          </div>
          <Dropdown
            value={product.digitalModel?.name}
            valueLabel={product.digitalModel?.brand?.name}
            placeholder={"Digital model ..."}
            label={"Digital model *"}
            disabled={true}
          />
          <TextInput
            classInput={cn(styles.text_input, {
              [styles.text_input_error]:
                validationError.includes("createAmount"),
            })}
            value={payload.createAmount}
            placeholder="In stock ..."
            label={"In stock *"}
            onKeyDown={(e) =>
              (e.key === " " || e.key === ".") && e.preventDefault()
            }
            onChange={({ target }) =>
              handleChange("createAmount", target.value)
            }
            error={validationError.includes("createAmount")}
          />
          <button
            className={cn("button", styles.save_button)}
            disabled={!isValid}
            onClick={createProductItems}
          >
            Save
          </button>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default CreationForm;
