import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const Styles = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fcfcfc;
  background-color: #111315;
  p {
    margin-bottom: 10px;
    line-height: 1.3;
    &:nth-child(1) {
      font-size: 35px;
      font-weight: 600;
    }
  }
  .content {
    height: 300px;
    width: 385px;
    font-size: 15px;
    text-align: start;
  }
  .link {
    color: #2a85ff;
    text-decoration: none;
    transition: 0.5s;
    border-radius: 8px;
    &:hover {
      padding: 5px 10px;
      color: #fcfcfc;
      background-color: #2a85ff;
      transition: 0.5s;
    }
  }
`;

export default function NotFoundPage() {
  const history = useHistory();

  return (
    <Styles>
      <div className="content">
        <p>Error 404</p>
        <p>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </p>
        <p>
          Please try to&nbsp;
          <Link
            className="link"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            go back
          </Link>
          &nbsp;or&nbsp;
          <Link className="link" to="/">
            return to the homepage
          </Link>
        </p>
        <p>Good luck !</p>
      </div>
    </Styles>
  );
}
