import React from "react";
import cn from "classnames";

import styles from "./DigitalProduct.module.sass";
import ImageStub from "../ImageStub";

const DigitalProduct = ({ className, item, onClick, onAuxClick }) => {
  return (
    <div className={cn(styles.digital_product, className)}>
      <div className={styles.preview} onClick={onClick} onAuxClick={onAuxClick}>
        {item.digitalModel?.previewUrl ? (
          <img
            srcSet={item.digitalModel?.previewUrl}
            src={item.digitalModel?.previewUrl}
            alt="Digital product"
          />
        ) : (
          <ImageStub alt="Digital product" />
        )}
      </div>
      <div className={styles.line}>
        <div className={styles.title}>{item.digitalModel?.brand?.name}</div>
      </div>
      <div className={styles.line}>
        <div className={styles.description}>
          <span>{item.digitalModel?.titleFull}</span>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.dt}>$ {item.amount.price}</div>
        <div className={styles.dt}>
          {`${item.inStock}/${item.inStock + item.sold}`} left
        </div>
      </div>
    </div>
  );
};

export default DigitalProduct;
