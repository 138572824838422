import React, { useState, useEffect } from "react";
import cn from "classnames";
import {
  PieChart,
  Pie,
  Tooltip as ChartTooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

import styles from "./PlatformStats.module.sass";
import PlatformStatsTooltip from "./PlatformStatsTooltip";
import Loader from "../Loader";
import RetryLoading from "../RetryLoading";
import Plug from "../Plug";
import Card from "../../../components-dev/Card";
import Tooltip from "../../../components-dev/Tooltip";
import axios from "../../../utils/axios";

const COLORS = ["#FFBC99", "#FFD88D"];

const PlatformStats = ({ dateInterval }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [dateInterval]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("stats/platforms", {
        params: { ...dateInterval },
      });

      const platforms = data.map((row) => ({
        ...row,
        usersCountInPercentage: Number(row.usersCountInPercentage.toFixed(2)),
      }));

      setData(platforms);
      setLoading(false);
      setErrorLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorLoading(true);
    }
  };

  return (
    <Card
      className={cn(styles.card)}
      title="Platform"
      classTitle="title-red"
      head={
        <Tooltip
          className={styles.tooltip}
          title="Distribution of all users by device operating system for the selected period"
          icon="info-stroke"
          place="left"
        />
      }
    >
      {isLoading && <Loader className={styles.chart_loader} />}

      {!isLoading && data.length && (
        <div className={styles.chart}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width="100%" height="100%">
              <ChartTooltip
                content={<PlatformStatsTooltip />}
                wrapperStyle={{ zIndex: 1 }}
                // position={{ x: 0, y: 0 }}
              />
              <Pie
                data={data}
                dataKey="usersCountInPercentage"
                cx={105}
                cy={90}
                innerRadius={70}
                outerRadius={95}
                paddingAngle={1}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          <div className={styles.chart_info}>
            {data.map((x, index) => (
              <div key={`info-${index}`}>
                <span>{x.os}</span>
                <span>{x.usersCountInPercentage}%</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isLoading && !data.length && <Plug className={styles.plug} />}

      {!isLoading && isErrorLoading && (
        <RetryLoading className={styles.retry_loading} retry={fetchData} />
      )}
    </Card>
  );
};

export default PlatformStats;
