import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./PopupLoader.module.sass";

const Loader = () => {
  const { visible } = useSelector((state) => state.loaderReducer);

  return (
    <div className={cn(styles.wrap, { [styles.visible]: visible })}>
      <div className={styles.loader}></div>
    </div>
  );
};

export default Loader;
