import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Games from "./Games";
import { display as displayNotificationPopup } from "../../store/reducers/notificationPopup";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ displayNotificationPopup }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Games);
