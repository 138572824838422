import React, { useState, useEffect, useRef } from "react";

import Table from "./Table";
import Card from "../../components-dev/Card";
import axios from "../../utils/axios";
import eventBus from "../../utils/eventBus";

const TransferRequests = ({ displayNotificationPopup }) => {
  const [transferRequests, setTransferRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);

  const limit = 25;

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    const subscription = eventBus.on(
      "delete_transfer_request_event",
      (transferRequestId) => {
        setTransferRequests((prev) =>
          prev.filter((t) => t.id !== transferRequestId)
        );
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    page && fetchTransferRequests();
  }, [page]);

  const fetchTransferRequests = async () => {
    try {
      setLoading(true);

      const {
        data: { data, pagination },
      } = await axios.get("transfer-requests", {
        params: { limit, offset: (page - 1) * limit },
      });
      if (data.length) {
        setTransferRequests((prev) => [...prev, ...data]);
      } else {
        observer.current.disconnect();
      }

      setLoading(false);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  return (
    <Card>
      <Table
        transferRequests={transferRequests}
        loader={loader}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default TransferRequests;
