import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";

import styles from "./User.module.sass";
import Icon from "../../Icon";

const items = [
  // {
  //   menu: [
  //     {
  //       title: "Profile",
  //       url: "/profile",
  //     },
  //     {
  //       title: "Edit profile",
  //       url: "/profile/edit",
  //     },
  //   ],
  // },
  {
    menu: [
      {
        title: "Log out",
        onClick: () => {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          localStorage.clear();
          localStorage.setItem("isAuthorized", false);
          window.location.replace("/sign-in");
        },
      },
    ],
  },
];

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const userEmail = localStorage.getItem("userEmail") || "";

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <div className={styles.head}>
          <div className={styles.email}>{userEmail}</div>
          <button
            className={styles.avatar}
            onClick={() => setVisible(!visible)}
          >
            <img src="/images/default-avatar.svg" alt="Avatar" />
          </button>
        </div>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    activeClassName={styles.active}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={x.onClick}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
