import React, { useState, useRef } from "react";
import cn from "classnames";

import styles from "./ListUploader.module.sass";
import TextInput from "../../../components-dev/TextInput";

const ListUploader = ({ data, onChange }) => {
  const [index, setIndex] = useState(null);
  const hiddenFileInput = useRef(null);

  const handleFileInput = () => {
    hiddenFileInput.current.click();
  };

  const onUploadFile = async ({ target }) => {
    const uploadedFile = target.files[0];

    if (
      uploadedFile?.type.includes("image") &&
      !uploadedFile?.type.includes("image/gif")
    ) {
      const list = data.map((el, i) => {
        return i !== index
          ? el
          : { file: uploadedFile, fileName: uploadedFile.name, link: "" };
      });
      onChange(list);
    }
  };

  return (
    <div className={styles.list_uploader}>
      {data.map((item, idx) => (
        <div
          className={cn(styles.item, {
            [styles.item_last]: data.length - 1 === idx,
          })}
          key={idx}
        >
          <div>
            <button
              className={cn("button-stroke", styles.btn_file_upload)}
              onClick={() => {
                setIndex(idx);
                handleFileInput();
              }}
            >
              <img src="/images/icons/add/light.svg" alt="icon" />
              <span>Upload image</span>
            </button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={onUploadFile}
              style={{ display: "none" }}
            />
          </div>
          <div className={styles.file_name}>{item.fileName}</div>
          <TextInput
            className={cn({ ["unchangeable"]: !item.link })}
            classInput={cn(styles.text_input, styles.text_overflow)}
            value={item.link}
            label={"Image link"}
            copy={item.link}
            classCopyIcon={styles.copy_custom}
            disabled={true}
          />
        </div>
      ))}

      <div className={styles.blc_controls}>
        <button
          className={styles.btn_add}
          onClick={() => onChange([...data, { fileName: "", link: "" }])}
        >
          <img src="/images/icons/add/light.svg" alt="add" />
        </button>
        <button
          className={styles.btn_remove}
          onClick={() => onChange(data.slice(0, -1))}
          disabled={data.length < 2}
        >
          <img src="/images/icons/remove/light.svg" alt="remove" />
        </button>
      </div>
    </div>
  );
};

export default ListUploader;
