import React from "react";
import cn from "classnames";

import styles from "./Table.module.sass";
import Row from "./Row";
import Loader from "../../../components-dev/Loader";

const Table = ({ className, digitalItems, loader, isLoading }) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      {digitalItems.length ? (
        <div className={cn(styles.table)}>
          <div className={cn(styles.row)}>
            <div className={styles.col}>Digital model</div>
            <div className={styles.col}>Item ID</div>
            <div className={styles.col}>Owner</div>
            <div className={styles.col}>Link</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}>Date</div>
          </div>
          {digitalItems.map((x, index) => (
            <Row item={x} key={index} />
          ))}
        </div>
      ) : (
        !isLoading && <div className={styles.info}>No digital items</div>
      )}
      <div ref={loader}></div>
      {isLoading && (
        <div className={styles.foot}>
          <div className={styles.blc_loader}>
            <Loader className={styles.loader} />
            <span>Loading</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
