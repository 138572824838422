import React, { useState, useEffect } from "react";
import cn from "classnames";

import styles from "./UserActivityStats.module.sass";
import Row from "./Row";
import Loader from "../Loader";
import RetryLoading from "../RetryLoading";
import Plug from "../Plug";
import Card from "../../../components-dev/Card";
import Icon from "../../../components-dev/Icon";
import Tooltip from "../../../components-dev/Tooltip";
import axios from "../../../utils/axios";

const UserActivityStats = ({ dateInterval }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(0);
  const limit = 4;

  useEffect(() => {
    fetchData();
  }, [dateInterval]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const { data } = await axios.get("stats/users-activity", {
        params: { ...dateInterval },
      });

      setMaxPageNumberLimit(Math.ceil(data.length / limit));

      const userActivityStats = dataMapper(data);
      setData(userActivityStats);

      setLoading(false);
      setErrorLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorLoading(true);
    }
  };

  const dataMapper = (data) => {
    const result = [];

    const percentageOfMaxNumber = (count, max) =>
      max ? `${Number((count / (max / 100)).toFixed(2))}%` : "0%";

    const mapper = (arr) => {
      let cohortCountMax = 0;
      let becameUsersCountMax = 0;
      let usersItemViewedCountMax = 0;
      let usersCustomizationSavedMax = 0;
      let usersArLaunchedMax = 0;
      let usersTransferSuccessMax = 0;

      arr.forEach((x) => {
        cohortCountMax =
          cohortCountMax > x.cohortCount ? cohortCountMax : x.cohortCount;
        becameUsersCountMax =
          becameUsersCountMax > x.becameUsersCount
            ? becameUsersCountMax
            : x.becameUsersCount;
        usersItemViewedCountMax =
          usersItemViewedCountMax > x.usersItemViewedCount
            ? usersItemViewedCountMax
            : x.usersItemViewedCount;
        usersCustomizationSavedMax =
          usersCustomizationSavedMax > x.usersCustomizationSaved
            ? usersCustomizationSavedMax
            : x.usersCustomizationSaved;
        usersArLaunchedMax =
          usersArLaunchedMax > x.usersArLaunched
            ? usersArLaunchedMax
            : x.usersArLaunched;
        usersTransferSuccessMax =
          usersTransferSuccessMax > x.usersTransferSuccess
            ? usersTransferSuccessMax
            : x.usersTransferSuccess;
      });

      result.push(
        ...arr.map((x) => ({
          ...x,
          cohortCountProgress: percentageOfMaxNumber(
            x.cohortCount,
            cohortCountMax
          ),
          becameUsersCountProgress: percentageOfMaxNumber(
            x.becameUsersCount,
            becameUsersCountMax
          ),
          usersItemViewedCountProgress: percentageOfMaxNumber(
            x.usersItemViewedCount,
            usersItemViewedCountMax
          ),
          usersCustomizationSavedProgress: percentageOfMaxNumber(
            x.usersCustomizationSaved,
            usersCustomizationSavedMax
          ),
          usersArLaunchedProgress: percentageOfMaxNumber(
            x.usersArLaunched,
            usersArLaunchedMax
          ),
          usersTransferSuccessProgress: percentageOfMaxNumber(
            x.usersTransferSuccess,
            usersTransferSuccessMax
          ),
        }))
      );
    };

    let step = 0;
    while (true) {
      const arr = data.slice(step, step + limit);

      if (!arr.length) break;

      mapper(arr);
      step += limit;
    }

    return result;
  };

  const paginate = (action) => {
    if (action === "next" && currentPage <= maxPageNumberLimit) {
      setCurrentPage((prev) => ++prev);
    }
    if (action === "prev" && currentPage > 1) {
      setCurrentPage((prev) => --prev);
    }
  };

  return (
    <Card
      className={cn(styles.card)}
      title="User activity"
      classTitle={cn("title-green", styles.title)}
      head={
        <Tooltip
          className={styles.tooltip}
          title="Distribution of users by activity in the app by weeks included in the selected period"
          icon="info-stroke"
          place="left"
        />
      }
    >
      {isLoading && <Loader className={styles.chart_loader} />}

      {!isLoading && data.length && (
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>Week</div>
            <div className={styles.col}>All Users</div>
            <div className={styles.col}>New Users</div>
            <div className={styles.col}>Item views</div>
            <div className={styles.col}>Customizations</div>
            <div className={styles.col}>AR Try-ons</div>
            <div className={styles.col}>Transfers</div>
          </div>
          {data
            .slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit)
            .map((x, index) => (
              <Row data={x} key={index} />
            ))}
        </div>
      )}

      {!isLoading && data.length > limit && (
        <div className={styles.blc_pagination}>
          <button
            className={styles.btn_arrow}
            onClick={() => paginate("prev")}
            disabled={currentPage === 1}
          >
            <Icon name="arrow-left" size="17" />
          </button>
          <button
            className={styles.btn_arrow}
            onClick={() => paginate("next")}
            disabled={currentPage === maxPageNumberLimit}
          >
            <Icon name="arrow-right" size="17" />
          </button>
        </div>
      )}

      {!isLoading && !data.length && <Plug className={styles.plug} />}

      {!isLoading && isErrorLoading && (
        <RetryLoading className={styles.retry_loading} retry={fetchData} />
      )}
    </Card>
  );
};

export default UserActivityStats;
