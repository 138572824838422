import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "./Settings.module.sass";
import Switch from "../../components-dev/Switch";
import ConfirmPopup from "../../components-dev/ConfirmPopup";
import axios from "../../utils/axios";
import { isEmptyObject } from "../../utils/isEmptyObject";

const settingsPool = {
  freeCollection: {
    name: "freeCollectionAvailable",
    infoMessage: "This action activates the free collection feature",
  },
  customization: {
    name: "customizationAvailable",
    infoMessage: "This action activates the customization feature",
  },
  augmentedReality: {
    name: "augmentedRealityAvailable",
    infoMessage: "This action activates the AR feature",
  },
  stories: {
    name: "newsAvailable",
    infoMessage: "This action activates the stories feature",
  },
};

const Settings = ({ displayLoader, hideLoader, displayNotificationPopup }) => {
  const [settings, setSettings] = useState({});
  const [popup, setPopup] = useState({
    visible: false,
    infoMessage: "",
    setting: "",
  });
  const history = useHistory();

  useEffect(() => {
    const roles = localStorage.getItem("roles");

    if (!roles.includes("superadmin") && !roles.includes("admin")) {
      history.goBack();
      return;
    }

    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      displayLoader();
      const { data } = await axios.get("settings");
      setSettings(data);
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  const changeSetting = async (setting) => {
    if (setting.name === settingsPool.freeCollection.name) {
      setPopup({
        visible: true,
        ...setting,
        infoMessage: `This action ${
          settings.freeCollectionAvailable === "on" ? "de" : ""
        }activates the free collection feature`,
      });
    }
    if (setting.name === settingsPool.customization.name) {
      setPopup({
        visible: true,
        ...setting,
        infoMessage: `This action ${
          settings.customizationAvailable === "on" ? "de" : ""
        }activates the customization feature`,
      });
    }
    if (setting.name === settingsPool.augmentedReality.name) {
      setPopup({
        visible: true,
        ...setting,
        infoMessage: `This action ${
          settings.augmentedRealityAvailable === "on" ? "de" : ""
        }activates the AR feature`,
      });
    }
    if (setting.name === settingsPool.stories.name) {
      setPopup({
        visible: true,
        ...setting,
        infoMessage: `This action ${
          settings.newsAvailable === "on" ? "de" : ""
        }activates the stories feature`,
      });
    }
  };

  const onConfirm = async () => {
    try {
      displayLoader();

      if (popup.name === settingsPool.freeCollection.name) {
        const freeCollectionAvailable =
          settings.freeCollectionAvailable === "on" ? "off" : "on";

        await axios.put("settings", { freeCollectionAvailable });
        setSettings((prev) => ({
          ...prev,
          freeCollectionAvailable,
        }));
      }
      if (popup.name === settingsPool.customization.name) {
        const customizationAvailable =
          settings.customizationAvailable === "on" ? "off" : "on";

        await axios.put("settings", { customizationAvailable });
        setSettings((prev) => ({
          ...prev,
          customizationAvailable,
        }));
      }
      if (popup.name === settingsPool.augmentedReality.name) {
        const augmentedRealityAvailable =
          settings.augmentedRealityAvailable === "on" ? "off" : "on";

        await axios.put("settings", { augmentedRealityAvailable });
        setSettings((prev) => ({
          ...prev,
          augmentedRealityAvailable,
        }));
      }
      if (popup.name === settingsPool.stories.name) {
        const newsAvailable = settings.newsAvailable === "on" ? "off" : "on";

        await axios.put("settings", { newsAvailable });
        setSettings((prev) => ({
          ...prev,
          newsAvailable,
        }));
      }
    } catch (err) {
      displayNotificationPopup("error");
    } finally {
      hideLoader();
    }
  };

  return (
    !isEmptyObject(settings) && (
      <>
        <section>
          <div className={styles.section_title}>Free collection</div>
          <div className={styles.section_settings}>
            <div className={styles.blc_switch}>
              <span>Available for new users</span>
              <Switch
                value={settings.freeCollectionAvailable === "on"}
                onChange={() => changeSetting(settingsPool.freeCollection)}
              />
            </div>
          </div>
        </section>
        <section>
          <div className={styles.section_title}>Customization</div>
          <div className={styles.section_settings}>
            <div className={styles.blc_switch}>
              <span>Available customization</span>
              <Switch
                value={settings.customizationAvailable === "on"}
                onChange={() => changeSetting(settingsPool.customization)}
              />
            </div>
          </div>
        </section>
        <section>
          <div className={styles.section_title}>Augmented reality</div>
          <div className={styles.section_settings}>
            <div className={styles.blc_switch}>
              <span>Available AR</span>
              <Switch
                value={settings.augmentedRealityAvailable === "on"}
                onChange={() => changeSetting(settingsPool.augmentedReality)}
              />
            </div>
          </div>
        </section>
        <section>
          <div className={styles.section_title}>Stories</div>
          <div className={styles.section_settings}>
            <div className={styles.blc_switch}>
              <span>Available stories</span>
              <Switch
                value={settings.newsAvailable === "on"}
                onChange={() => changeSetting(settingsPool.stories)}
              />
            </div>
          </div>
        </section>
        <ConfirmPopup
          visible={popup.visible}
          setVisible={setPopup}
          infoMessage={popup.infoMessage}
          confirm={onConfirm}
        />
      </>
    )
  );
};

export default Settings;
