import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { use100vh } from "react-div-100vh";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import styles from "./SignIn.module.sass";
import TextInput from "./TextInput";
import CodeInput from "../../components-dev/CodeInput";
import Image from "../../components-dev/Image";
import Loader from "../../components-dev/Loader";
import { axiosAuth } from "../../utils/axios";
import { parseJwt } from "../../utils/parseJwt";
import emailValidator from "../../validators/email.validator";
import otpValidator from "../../validators/otp.validator";
import numericValidator from "../../validators/numeric.validator";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState(["", "", "", ""]);
  const [isValid, setIsValid] = useState(false);
  const [codeInputIsVisible, setCodeInputIsVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const heightWindow = use100vh();

  useEffect(() => {
    if (isError) setIsError(false);
  }, [email, code, codeInputIsVisible]);

  useEffect(() => {
    const { error } = otpValidator.validate({ code: code.join("") });
    setIsValid(error ? false : true);
  }, [code]);

  const onChangeTextInput = ({ target }) => {
    const value = target.value;
    const { error } = emailValidator.validate({ email: value });

    setEmail(value);
    setTimeout(() => {
      setEmail(value + " ");
    }, 0);
    setIsValid(error ? false : true);
  };

  const onChangeCodeInput = (idx, value) => {
    const { error } = numericValidator.validate({ value });

    if (!error || error?.message.includes("is not allowed")) {
      const otpCode = [...code];
      otpCode[idx] = value;
      setCode(otpCode);

      const tabIndex = value === "" ? idx : idx + 2;
      autoTab(tabIndex);
    }
  };

  const autoTab = (tabIndex) => {
    const nextElem = document.querySelector(`[tabindex = "${tabIndex}"]`);

    if (nextElem !== null) {
      nextElem.focus();
    }
  };

  const onPasteOtp = ({ clipboardData }) => {
    const otpCode = clipboardData.getData("text");
    const { error } = otpValidator.validate({ code: otpCode });
    !error && setCode(otpCode.split(""));
  };

  const onKeyUp = ({ key }) => {
    if (isValid && key === "Enter") {
      verifyOtp();
    }
  };

  const signIn = async () => {
    if (!isValid) return;

    setLoading(true);

    try {
      await axiosAuth.post(`auth/login`, { email: email.trim().toLowerCase() });
      setIsValid(false);
      setCodeInputIsVisible(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    setLoading(true);

    try {
      const userEmail = email.trim().toLowerCase();
      const { data } = await axiosAuth.post(`auth/verify-otp`, {
        email: userEmail,
        code: code.join(""),
      });

      Cookies.set("accessToken", data.accessToken, { expires: 1 });
      Cookies.set("refreshToken", data.refreshToken, { expires: 14 });

      const parsedJwt = parseJwt(data.accessToken);
      const privilegedRole = parsedJwt.roles?.includes("superadmin")
        ? "superadmin"
        : parsedJwt.roles?.includes("admin")
        ? "admin"
        : "basic";

      localStorage.setItem("userEmail", userEmail);
      localStorage.setItem("roles", parsedJwt.roles || "basic");
      localStorage.setItem("privilegedRole", privilegedRole);
      localStorage.setItem("isAuthorized", true);
      history.push("/");
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    await axiosAuth.post(`auth/login`, { email: email.trim().toLowerCase() });
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src={`/images/brand_logos/${window.origin.split(".")[1]}.svg`}
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <div className={styles.body}>
          {codeInputIsVisible ? (
            <>
              <div className={styles.info}>
                We have sent you a verify code to <b>{email}</b>. Check your
                inbox to get it.
              </div>
              <div onKeyUp={onKeyUp}>
                <CodeInput
                  className={styles.field_code}
                  autoFocus
                  required
                  value={code[0]}
                  tabIndex={1}
                  onChange={({ target }) => onChangeCodeInput(0, target.value)}
                  onPaste={onPasteOtp}
                  error={isError}
                />
                <CodeInput
                  className={styles.field_code}
                  required
                  value={code[1]}
                  tabIndex={2}
                  onChange={({ target }) => onChangeCodeInput(1, target.value)}
                  error={isError}
                />
                <CodeInput
                  className={styles.field_code}
                  required
                  value={code[2]}
                  tabIndex={3}
                  onChange={({ target }) => onChangeCodeInput(2, target.value)}
                  error={isError}
                />
                <CodeInput
                  className={styles.field_code}
                  required
                  value={code[3]}
                  tabIndex={4}
                  onChange={({ target }) => onChangeCodeInput(3, target.value)}
                  error={isError}
                />
              </div>
              <button
                className={cn("button", styles.button)}
                tabIndex={5}
                disabled={!isValid}
                onClick={verifyOtp}
              >
                {isLoading && <Loader className={styles.loader_s} white />}
                <span>Continue</span>
                {isError && (
                  <span className={styles.warning}>Incorrect code</span>
                )}
              </button>
              <button className={styles.button_resend} onClick={resendCode}>
                Resend code
              </button>
            </>
          ) : (
            <>
              <TextInput
                className={styles.field}
                name="email"
                type="email"
                autoComplete="on"
                placeholder="Your email"
                required
                icon="mail"
                autoFocus
                value={email}
                onChange={onChangeTextInput}
                onKeyPress={(e) => e.key === "Enter" && signIn()}
                error={isError}
              />
              <button
                className={cn("button", styles.button)}
                disabled={!isValid}
                onClick={signIn}
              >
                {isLoading && <Loader className={styles.loader} white />}
                Sign in
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
