import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import NotificationPopup from "./NotificationPopup";
import { hide } from "../../store/reducers/notificationPopup";

const mapStateToProps = ({ notificationPopupReducer }) => ({
  notification: notificationPopupReducer.notification,
  visible: notificationPopupReducer.visible,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ hide }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
