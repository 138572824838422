import React from "react";
import cn from "classnames";

import styles from "./Loader.module.sass";
import LoaderIcon from "../../../components-dev/Loader";

const Loader = ({ className }) => {
  return (
    <div className={cn(styles.loader, className)}>
      <LoaderIcon className={styles.loader_icon} />
    </div>
  );
};

export default Loader;
