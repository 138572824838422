export const addTabCharacter = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    // set textarea value to: text before caret + tab + text after caret
    event.target.value =
      event.target.value.substring(0, start) +
      "\t" +
      event.target.value.substring(end);
    // put caret at right position again
    event.target.selectionStart = event.target.selectionEnd = start + 1;
  }
};

export const resizeHeightTextarea = (element) => {
  element.style.height = "0px";
  element.style.height = element.scrollHeight + "px";
};
