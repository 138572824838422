import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./DigitalModels.module.sass";
import Card from "../../../components-dev/Card";
import Loader from "../../../components-dev/Loader";
import RedirectTableCell from "../../../components-dev/RedirectTableCell";
import axios from "../../../utils/axios";
import { wheelClickEventHandler } from "../../../utils/wheelClickEventHandler";

const DigitalModels = ({ className, brandId }) => {
  const [digitalModels, setDigitalModels] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const observer = useRef(null);
  const loader = useRef(null);
  const history = useHistory();

  useEffect(() => {
    observer.current = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setPage((prev) => ++prev);
      }
    });
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    fetchDigitalModels();
  }, [page]);

  const fetchDigitalModels = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("digitalmdl", {
        params: { brandId, limit: 10, page },
      });
      if (data.records.length) {
        setDigitalModels((prev) => [...prev, ...data.records]);
      } else {
        observer.current.disconnect();
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={styles.card}
      title={"Digital models"}
      classTitle="title-purple"
      btnCreate={{
        title: "Create digital model",
        onClick: (e) => {
          if (e.ctrlKey || e.metaKey) window.open("/digital-models/new");
          else history.push("/digital-models/new", brandId);
        },
        onAuxClick: wheelClickEventHandler("/digital-models/new"),
      }}
    >
      <div className={cn(styles.wrapper, className)}>
        {digitalModels.length ? (
          <div className={cn(styles.table)}>
            <div className={cn(styles.row)}>
              <div className={styles.col}>Title full</div>
              <div className={styles.col}>Apparel type</div>
              <div className={styles.col}>Status</div>
            </div>
            {digitalModels.map((dModel, index) => (
              <div className={styles.row_content} key={index}>
                <div className={styles.col_content}>
                  <RedirectTableCell
                    text={dModel.titleFull}
                    to={`/digital-models/${dModel.id}`}
                  />
                </div>
                <div className={styles.col_content}>
                  <div className={styles.apparel_type}>
                    {dModel.apparelType}
                  </div>
                </div>
                <div className={styles.col_content}>
                  <div
                    className={cn(
                      dModel.isActive ? "status-green" : "status-red"
                    )}
                  >
                    {dModel.isActive ? "Active" : "Not active"}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          !isLoading && <div className={styles.info}>No digital models</div>
        )}
        <div ref={loader}></div>
        {isLoading && (
          <div className={styles.foot}>
            <div className={styles.blc_loader}>
              <Loader className={styles.loader} />
              <span>Loading</span>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default DigitalModels;
