import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import styles from "./ModelLoader.module.sass";
import Icon from "../Icon";
import axios from "../../utils/axios";

const ModelLoader = ({ btnTitle, modelUrl, modelFile, delay }) => {
  const [option, setOption] = useState("default");
  const [fileName, setFileName] = useState("3d-model-file.format");
  const [isLoading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const { id: digitalModelId } = useParams();

  useEffect(() => {
    if (!modelUrl) return;

    const opt = modelUrl[modelFile] ? "loaded" : "default";
    if (opt === "loaded") {
      setOption(opt);
      setFileName(Object.keys(modelUrl[modelFile])[0]);
    }
  }, [modelFile]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileInput = (event) => {
    const uploadedFile = event.target.files[0];

    if (
      !uploadedFile?.name.includes(
        modelFile === "glbFileUrl" ? ".glb" : ".usdz"
      )
    ) {
      setOption("error");
      return;
    }

    if (delay) {
      delay(uploadedFile);
      setFileName(uploadedFile.name);
      setOption("loaded");
      return;
    }

    upload3dModelToS3(uploadedFile);
  };

  const upload3dModelToS3 = async (uploadedFile) => {
    const fileType = {
      glbFileUrl: "glbFile",
      usdzFileUrl: "usdzFile",
    };

    const bodyFormData = new FormData();
    bodyFormData.append(fileType[modelFile], uploadedFile);

    setLoading(true);

    try {
      const {
        data: { modelUrl },
      } = await axios.post(
        `digitalmdl/${digitalModelId}/model3d`,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFileName(Object.keys(modelUrl[modelFile])[0]);
      setOption("loaded");
    } catch (err) {
      setOption("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.model_loader}>
      <button
        className={cn("button-stroke", styles.model_upload_button)}
        onClick={handleClick}
      >
        <img
          src={`/images/icons/${isLoading ? "loader" : "add"}/light.svg`}
          alt="icon"
        />
        <span>{btnTitle}</span>
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileInput}
        style={{ display: "none" }}
      />
      {option === "default" && (
        <div className={styles.option_default}>
          <span>No 3D models uploaded</span>
        </div>
      )}
      {option === "loaded" && (
        <div className={styles.option_loaded}>
          <span>{fileName}</span>
          <Icon name="check" size="20" />
        </div>
      )}
      {option === "error" && (
        <div className={styles.option_error}>
          <span>Error. Try again</span>
          <Icon name="close" size="18" />
        </div>
      )}
    </div>
  );
};

export default ModelLoader;
