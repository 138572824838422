import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Joi from "joi";
import cn from "classnames";

import styles from "./DigitalProduct.module.sass";
import ProductItems from "./ProductItems";
import InfoNavigation from "../../components-dev/InfoNavigation";
import Card from "../../components-dev/Card";
import Dropdown from "../../components-dev/Dropdown";
import TextInput from "../../components-dev/TextInput";
import AutoHeightTextarea from "../../components-dev/AutoHeightTextarea";
import Switch from "../../components-dev/Switch";
import Icon from "../../components-dev/Icon";
import ProductDetails from "../../components-dev/ProductDetails";
import ProductStoreIdentifierDetails from "../../components-dev/ProductStoreIdentifierDetails";
import ImageStub from "../../components-dev/ImageStub";
import DigitalProductOffers from "../../components-dev/DigitalProductOffers";
import axios from "../../utils/axios";
import eventBus from "../../utils/eventBus";
import { scrollToTop } from "../../utils/scrollToTop";
import { isEmptyObject } from "../../utils/isEmptyObject";
import { toPrettyJson } from "../../utils/gJson";
import { basicRole } from "../../assets/roles";

const DigitalProduct = ({
  displayLoader,
  hideLoader,
  displayNotificationPopup,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [isValid, setValid] = useState(false);
  const [validationError, setValidationError] = useState("");

  const [digitalProduct, setDigitalProduct] = useState({});
  const [clipboard, setClipboard] = useState({});
  const [digitalModels, setDigitalModels] = useState([]);

  const { id: digitalProductId } = useParams();

  useEffect(() => {
    scrollToTop();
    fetchData();
  }, []);

  useEffect(() => {
    const subscription = eventBus.on("add_product_items_event", (count) => {
      setDigitalProduct((prev) => ({ ...prev, inStock: prev.inStock + count }));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    validate();
  }, [digitalProduct]);

  const fetchData = async () => {
    displayLoader();
    await fetchDigitalProduct();
    await fetchDigitalModels();
    hideLoader();
  };

  const fetchDigitalProduct = async () => {
    try {
      const {
        data: { product },
      } = await axios.get(`marketplace/products/${digitalProductId}`);
      const dProduct = {
        ...product,
        appStoreIdentifierDetails: product.appStoreIdentifier?.details,
      };
      setDigitalProduct(dProduct);
      setClipboard(dProduct);
    } catch (err) {
      displayNotificationPopup("error");
    }
  };

  const fetchDigitalModels = async () => {
    try {
      const { data } = await axios.get("digitalmdl");
      const dModels = data.records.map((m) => ({
        ...m,
        brandName: m.brand?.name,
      }));
      setDigitalModels((prev) => [...prev, ...dModels]);
    } catch {}
  };

  const changeMode = () => {
    if (isEditMode) {
      setDigitalProduct((prev) => ({
        ...clipboard,
        inStock: prev.inStock,
      }));
    }
    setEditMode((prev) => !prev);
  };

  const handleChange = (prop, value) => {
    setDigitalProduct((prev) => ({ ...prev, [prop]: value }));
  };

  const validate = () => {
    if (isEmptyObject(digitalProduct)) return;

    const { error } = Joi.object({
      digitalModelId: Joi.string().uuid().required(),
      appStoreProductId: Joi.string().required(),
      price: Joi.string()
        .regex(/^\d+([.]\d{1,2})?$/)
        .required(),
      available: Joi.boolean().required(),
      details: Joi.array()
        .items(
          Joi.object({
            name: Joi.string().required(),
            value: Joi.string().required(),
            key: Joi.string().required(),
          })
        )
        .min(1),
      appStoreIdentifierDetails: Joi.array().items(
        Joi.object({
          discount: [
            Joi.string()
              .regex(/^[1-9]{1}([0-9]{1})?$/)
              .required(),
            Joi.number().min(1).max(99).required(),
          ],
          value: Joi.string().required(),
        })
      ),
    }).validate(
      {
        digitalModelId: digitalProduct.digitalModelId,
        appStoreProductId: digitalProduct.appStoreProductId,
        price: digitalProduct.amount.price.toString(),
        available: digitalProduct.available,
        details: digitalProduct.details,
        appStoreIdentifierDetails: digitalProduct.appStoreIdentifierDetails,
      },
      { abortEarly: false }
    );

    setValidationError(error ? error.message : "");
    setValid(error ? false : true);
  };

  const updateDigitalProduct = async () => {
    if (!isValid) return;

    try {
      displayLoader();

      const body = {
        digitalModelId: digitalProduct.digitalModelId,
        appStoreProductId: digitalProduct.appStoreProductId,
        amount: digitalProduct.amount,
        available: digitalProduct.available,
        details: digitalProduct.details,
        appStoreIdentifierDetails: digitalProduct.appStoreIdentifierDetails,
      };

      await axios.put(`marketplace/products/${digitalProductId}`, body);

      setClipboard((prev) => ({
        ...prev,
        ...body,
      }));
      setEditMode(false);

      hideLoader();
      displayNotificationPopup("updated successfully");
      scrollToTop("smooth");
    } catch (err) {
      hideLoader();
      displayNotificationPopup("error");
    }
  };

  const getPreview = () => {
    const dModel = digitalModels.find(
      (model) => model.id === digitalProduct.digitalModelId
    );
    return dModel?.previewUrl;
  };

  return (
    !isEmptyObject(digitalProduct) && (
      <>
        <InfoNavigation
          link={"/digital-products"}
          parts={[
            "Products",
            `${
              digitalModels.find(
                (dm) => dm.id === digitalProduct.digitalModelId
              )?.brand?.name
            } ${
              digitalModels.find(
                (dm) => dm.id === digitalProduct.digitalModelId
              )?.name
            }`,
          ]}
        />
        <div className={styles.head}>
          <div className={styles.blc_title}>
            <div className={cn("h3", styles.title)}>
              {
                digitalModels.find(
                  (dm) => dm.id === digitalProduct.digitalModelId
                )?.brand?.name
              }
            </div>
            <div className={cn("h3", styles.subtitle)}>
              {
                digitalModels.find(
                  (dm) => dm.id === digitalProduct.digitalModelId
                )?.name
              }
            </div>
          </div>
          {!basicRole && (
            <button
              className={cn("button-stroke", styles.switch_button, [
                !isEditMode ? styles.info : styles.edit,
              ])}
              onClick={changeMode}
            >
              <Icon name={!isEditMode ? "edit" : "close"} size="24" />
              <span>{!isEditMode ? "Edit" : "Cancel"}</span>
            </button>
          )}
        </div>
        <Card>
          <div className={cn(styles.digital_product)}>
            <div className={styles.blc_left}>
              <div className={styles.preview}>
                {getPreview() ? (
                  <div className={styles.preview_content}>
                    <img
                      srcSet={getPreview()}
                      src={getPreview()}
                      alt="Digital product"
                    />
                  </div>
                ) : (
                  <ImageStub className={styles.stub} alt="Digital product" />
                )}
              </div>
              <div className={styles.stock}>
                <span>In stock</span>
                <span>{digitalProduct.inStock}</span>
              </div>
              <div className={styles.sold}>
                <span>Sold</span>
                <span>{digitalProduct.sold}</span>
              </div>
            </div>

            <div className={styles.blc_right}>
              <Dropdown
                value={
                  digitalModels.find(
                    (model) => model.id === digitalProduct.digitalModelId
                  )?.name
                }
                valueLabel={
                  digitalProduct.digitalModel
                    ? digitalProduct.digitalModel.brand?.name
                    : digitalModels.find(
                        (model) => model.id === digitalProduct.digitalModelId
                      )?.brand?.name
                }
                placeholder="Digital model ..."
                setValue={(selectedDigitalModel) => {
                  handleChange("digitalModelId", selectedDigitalModel.id);
                }}
                options={digitalModels}
                optionLabel={"brandName"}
                label="Digital model *"
                link={
                  !isEditMode &&
                  `/digital-models/${digitalProduct.digitalModelId}`
                }
                loading
                disabled={!isEditMode}
              />
              <TextInput
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.text_input)}
                value={digitalProduct.digitalModelId}
                label="Digital model id *"
                copy={!isEditMode}
                classCopyIcon={styles.copy_custom}
                disabled={true}
              />
              <TextInput
                classInput={cn(styles.text_input, {
                  [styles.text_input_error]:
                    isEditMode && validationError.includes("appStoreProductId"),
                })}
                value={digitalProduct.appStoreProductId}
                placeholder="100_usd_product"
                label="App Store ID *"
                onChange={({ target }) =>
                  handleChange("appStoreProductId", target.value)
                }
                onKeyDown={(e) => e.key === " " && e.preventDefault()}
                copy={!isEditMode}
                classCopyIcon={styles.copy_custom}
                error={
                  isEditMode && validationError.includes("appStoreProductId")
                }
                disabled={!isEditMode}
              />
              {(digitalProduct.appStoreIdentifierDetails.length ||
                isEditMode) && (
                <ProductStoreIdentifierDetails
                  details={digitalProduct.appStoreIdentifierDetails}
                  onChange={(changedDetails) =>
                    setDigitalProduct((prev) => ({
                      ...prev,
                      appStoreIdentifierDetails: changedDetails,
                    }))
                  }
                  validationError={
                    isEditMode &&
                    validationError.includes("appStoreIdentifierDetails") &&
                    validationError
                  }
                  disabled={!isEditMode}
                />
              )}
              <div className={cn(styles.group, styles.same)}>
                <TextInput
                  classInput={cn(styles.text_input, {
                    [styles.text_input_error]:
                      isEditMode && validationError.includes("price"),
                  })}
                  value={digitalProduct.amount.price}
                  placeholder="Price ..."
                  label={"Price *"}
                  onChange={({ target }) =>
                    handleChange("amount", {
                      currency: digitalProduct.amount.currency,
                      price: target.value,
                    })
                  }
                  onKeyDown={(e) => e.key === " " && e.preventDefault()}
                  error={isEditMode && validationError.includes("price")}
                  disabled={!isEditMode}
                />
                <TextInput
                  className={cn({ ["unchangeable"]: isEditMode })}
                  classInput={cn(styles.text_input)}
                  value={digitalProduct.amount.currency}
                  label={"Currency *"}
                  disabled={true}
                />
              </div>
              <div className={styles.blc_switch}>
                <span>Available</span>
                <Switch
                  className={styles.switch}
                  value={digitalProduct.available}
                  onChange={() =>
                    setDigitalProduct((prev) => ({
                      ...prev,
                      available: !prev.available,
                    }))
                  }
                  disabled={!isEditMode}
                />
              </div>
              <ProductDetails
                details={digitalProduct.details}
                onChange={(changedDetails) =>
                  setDigitalProduct((prev) => ({
                    ...prev,
                    details: changedDetails,
                  }))
                }
                validationError={
                  validationError.includes("details") && validationError
                }
                disabled={!isEditMode}
              />
              <AutoHeightTextarea
                className={cn({ ["unchangeable"]: isEditMode })}
                classInput={cn(styles.textarea, styles.min_height_125)}
                value={toPrettyJson(digitalProduct.previewAttributes)}
                placeholder="Preview attributes ..."
                label={"Preview attributes (JSON) *"}
                disabled={true}
              />
              {isEditMode && (
                <button
                  className={cn("button", styles.save_button)}
                  disabled={!isValid}
                  onClick={updateDigitalProduct}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </Card>
        {!isEditMode && (
          <DigitalProductOffers digitalProduct={digitalProduct} />
        )}
        {!isEditMode && <ProductItems product={digitalProduct} />}
      </>
    )
  );
};

export default DigitalProduct;
